export default {
  "cropTool": "Cultură",
  "nameIsRequired": "Numele este obligatoriu.",
  "adjustTab": "Reglați",
  "socialMedia": "Social media",
  "padding": "Căptușeală",
  "addBackgroundAsText": "Adăugați ca text",
  "toBeUploaded": "de încărcat",
  "imageTypeWillChangeOnSave": "La salvare, veți alege un nou tip de imagine/extensie pentru a salva cu",
  "IconTab": "Icoana",
  "reset": "Resetare",
  "addIconTitle": "Alegeți tipul de pictogramă",
  "classicTv": "Clasic TV",
  "importing": "Importul...",
  "brightnessTool": "Luminozitate",
  "storyPhoto": "Poveste foto",
  "resizeTab": "Redimensionați",
  "twInStreamPhotoSize": "1600x1900px",
  "portrait": "Portret",
  "toggleZoomMenuTitle": "Comutați meniul de zoom",
  "saveAs": "Salvați ca",
  "uploadWatermark": "Încărcați filigranul",
  "closeEditor": "Închideți editorul",
  "resetZoomTitle": "Procentul de zoom (Faceți clic pentru a reseta)",
  "headerPhoto": "Fotografie antet",
  "letterSpacing": "Spațierea literelor",
  "feedSquarePhoto": "Alimentați fotografia pătrată",
  "rectangleTool": "Dreptunghi",
  "textTool": "Text",
  "arrowTool": "Săgeată",
  "rotateTool": "Rotiți",
  "twHeaderPhotoSize": "1500x500px",
  "igStoryPhotoSize": "1080x1920px",
  "position": "Poziția",
  "contrastTool": "Contrast",
  "unFlipX": "Un-Flip X",
  "igProfilePhoto": "320x320px",
  "horizontal": "Orizontal",
  "quality": "Calitate",
  "ellipseTool": "Elipsă",
  "fbStoryPhotoSize": "1080x1920px",
  "annotateTab": "Desenează",
  "value": "Valoare",
  "filtersTab": "Filtre",
  "zoomOutTitle": "Zoom out",
  "hsvTool": "HSV",
  "igFeedSquarePhotoSize": "1080x1080px",
  "lineHeight": "Înălțimea liniei",
  "instagram": "Instagram",
  "addBackground": "+ Adăugați fundal",
  "previewTool": "Previzualizare",
  "liProfilePhotoSize": "400x400px",
  "finetuneTab": "Finetune",
  "uploadIcon": "Pictograma Încărcare",
  "liProfileCoverPhotoSize": "1584x396px",
  "inStreamPhoto": "Fotografie în flux",
  "twProfilePhotoSize": "400x400px",
  "resizeWidthTitle": "Lățimea în pixeli",
  "toggleRatioLockTitle": "Comutarea blocării raportului",
  "annotationVariable": "Setați variabila de adnotare",
  "uploadImageError": "Eroare la încărcarea imaginii.",
  "custom": "Personalizat",
  "cropSizeLowerThanResizedWarning": "Notă, zona de tăiere selectată este mai mică decât cea de redimensionare aplicată, ceea ce ar putea cauza o scădere a calității.",
  "showImageTitle": "Arată imaginea originală",
  "continue": "Continuă",
  "linkedIn": "LinkedIn",
  "actualSize": "Dimensiunea reală (100%)",
  "back": "Înapoi",
  "resize": "Redimensionați",
  "flipY": "Flip Y",
  "addImage": "+ Adaugă imagine",
  "fontFamily": "Familie de fonturi",
  "polygonTool": "Poligon",
  "blurRadius": "blurRadius",
  "imageTypeNotSavingSupported": "Tipul/extensiunea imaginii NU este acceptat la salvare",
  "penTool": "Stilou",
  "saturation": "Saturație",
  "addBackgroundTitle": "Alegeți tipul de fundal",
  "zoomInTitle": "Zoom in",
  "addWatermark": "+ Adăugați filigranul",
  "isNotImage": "nu este imagine",
  "profileCoverPhoto": "Fotografie de acoperire a profilului",
  "feedPortraitPhoto": "Alimentați fotografia portret",
  "textAlignment": "Alinierea textului",
  "watermarkTab": "Filigran",
  "twitter": "Twitter",
  "loading": "Încărcare...",
  "unFlipY": "Un-Flip Y",
  "landscape": "Peisaj",
  "original": "Original",
  "saveAsModalLabel": "Salvați imaginea ca",
  "size": "Dimensiune",
  "liCompanyLogoSize": "300x300px",
  "backgroundTab": "Fundal",
  "addWatermarkTitle": "Alegeți tipul de filigran",
  "igProfilePhotoSize": "320x320px",
  "cornerRadius": "Raza de colț",
  "addIcon": "+ Adăugați pictograma",
  "liBlogPostPhotoSize": "1200x627px",
  "cinemascope": "Cinemascop",
  "addWatermarkAsText": "Adăugați ca text",
  "lineTool": "Linia",
  "warmthTool": "Căldură",
  "measureTool": "Măsură",
  "save": "Salvați",
  "timelinePhoto": "Fotografie cronologică",
  "eventCoverPhoto": "Fotografie de acoperire a evenimentului",
  "facebook": "Facebook",
  "changesLoseConfirmation": "Toate modificările vor fi pierdute",
  "fbEventCoverPhotoSize": "1200x628px",
  "companyPageCover": "Coperta paginii companiei",
  "blogPostPhoto": "Blog post fotografie",
  "undoTitle": "Anularea ultimei operații",
  "resetOperations": "Resetarea/ștergerea tuturor operațiunilor",
  "stroke": "Accident vascular cerebral",
  "name": "Nume",
  "cancel": "Anulează",
  "download": "Descărcați",
  "addIconAsText": "Adăugați ca text",
  "hue": "Hue",
  "textSpacings": "Spațiile dintre texte",
  "fbProfilePhotoSize": "170x170px",
  "textTab": "Text",
  "srTab": "SR",
  "blurTool": "Ceață",
  "uploadBackground": "Încărcați fundalul",
  "downloadAsModalLabel": "Salvați imaginea ca",
  "circle": "Cerc",
  "imageDimensionsHoverTitle": "Dimensiunea imaginii salvate (lățime x înălțime)",
  "addByUrl": "+ Adaugă URL",
  "changesLoseConfirmationHint": "Ești sigur că vrei să continui?",
  "redoTitle": "Refaceți ultima operațiune",
  "feedLandscapePhoto": "Feed peisaj fotografie",
  "invalidImageError": "Imagine invalidă furnizată.",
  "shadow": "Umbra",
  "ellipse": "Elipsă",
  "square": "Pătrat",
  "blur": "Ceață",
  "sides": "Părțile laterale",
  "vertical": "Vertical",
  "igFeedLandscapePhotoSize": "1080x566px",
  "imageTool": "Imagine",
  "areNotImages": "nu sunt imagini",
  "resetSize": "Resetare la dimensiunea originală a imaginii",
  "extension": "Extensie",
  "opacity": "Opacitate",
  "cancelEditing": "Anulare editare",
  "liCompanyPageCoverSize": "1128x191px",
  "transparency": "Opacitate",
  "profilePhoto": "Poza de profil",
  "igFeedPortraitPhotoSize": "1080x1350px",
  "fitSize": "Dimensiunea de potrivire",
  "flipX": "Flip X",
  "fbProfileCoverPhotoSize": "851x315px",
  "fbTimelinePhotoSize": "1200x630px",
  "resizeHeightTitle": "Înălțime în pixeli",
  "companyLogo": "Logo-ul companiei"
}
