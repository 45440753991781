import { CheckboxProps } from '../../../custom.types';
import { CheckboxWrapper, CheckboxSquare, Check } from './Checkbox.style';

import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function Checkbox(props: CheckboxProps) {
  return (
    <CheckboxWrapper overlay={props.overlay}>
      <CheckboxSquare overlay={props.overlay} onClick={() => props?.onChange && props?.onChange(!props.checked)}>
        {props.checked && (
          <Check>
            <FontAwesomeIcon icon={faCheck} fixedWidth />
          </Check>
        )}
      </CheckboxSquare>
    </CheckboxWrapper>
  );
}
