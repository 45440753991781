import { useAppSelector } from '../app/hooks';
import { RootState } from '../app/store';
import { GalleryStateEditor } from '../custom.types';

import { ModalWrapper } from '../components/UI/Modals/Modal.style';
import Add from '../components/UI/Modals/Add';
import Preview from '../components/UI/Modals/Preview';
import Rename from '../components/UI/Modals/Rename';
import Move from '../components/UI/Modals/Move';
import Delete from '../components/UI/Modals/Delete';
import Editor from '../components/Editor/Editor';
import ManageFolders from '../components/UI/Modals/ManageFolders';
import AddFolder from '../components/UI/Modals/AddFolder';

export default function Modal() {
  const { editorState, items, currentItem: current } = useAppSelector((state: RootState) => state.gallery);
  const currentItem = items.find((item) => item.selected === true) || current;

  return (
    <ModalWrapper>
      {editorState === GalleryStateEditor.ADD && <Add />}
      {currentItem && currentItem.src && (
        <>
          {editorState === GalleryStateEditor.PREVIEW && (
            <Preview currentItem={currentItem} />
          )}
          {editorState === GalleryStateEditor.EDIT && (
            <Editor currentItem={currentItem} />
          )}
          {editorState === GalleryStateEditor.RENAME && (
            <Rename currentItem={currentItem} />
          )}
        </>
      )}
      {editorState === GalleryStateEditor.MOVE && <Move />}
      {editorState === GalleryStateEditor.DELETE && <Delete />}
      {editorState === GalleryStateEditor.MANAGE_FOLDER && <ManageFolders />}

      {/* TESTING... */}
      {editorState === GalleryStateEditor.ADD_FOLDER && <AddFolder />}
    </ModalWrapper>
  );
}

/* export default function Editor(props: LayoutProps) {
  const [isImgEditorShown, setIsImgEditorShown] = useState(false);

  const openImgEditor = () => {
    setIsImgEditorShown(true);
  };

  const closeImgEditor = () => {
    setIsImgEditorShown(false);
  };

  return (
    <div>
      <button onClick={openImgEditor}>Open Filerobot image editor</button>
      {isImgEditorShown && (
        <FilerobotImageEditor
          source="https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg"
          onSave={(editedImageObject, designState) =>
            console.log('saved', editedImageObject, designState)
          }
          onClose={closeImgEditor}
          annotationsCommon={{
            fill: '#ff0000',
          }}
          Text={{ text: 'Filerobot...' }}
          Rotate={{ angle: 90, componentType: 'slider' }}
          Crop={{
            presetsItems: [
              {
                titleKey: 'classicTv',
                descriptionKey: '4:3',
                ratio: String(4 / 3),
                // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
              },
              {
                titleKey: 'cinemascope',
                descriptionKey: '21:9',
                ratio: String(21 / 9),
                // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
              },
            ],
            presetsFolders: [
              {
                titleKey: 'socialMedia', // will be translated into Social Media as backend contains this translation key
                // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                groups: [
                  {
                    titleKey: 'facebook',
                    items: [
                      {
                        titleKey: 'profile',
                        width: 180,
                        height: 180,
                        descriptionKey: 'fbProfileSize',
                      },
                      {
                        titleKey: 'coverPhoto',
                        width: 820,
                        height: 312,
                        descriptionKey: 'fbCoverPhotoSize',
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          tabsIds={[TABS.ADJUST, TABS.ANNOTATE, TABS.WATERMARK]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ANNOTATE} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
          savingPixelRatio={4}
          previewPixelRatio={window.devicePixelRatio}
        />
      )}
    </div>
  );
} */
