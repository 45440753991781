/** External Dependencies */
import styled, { css } from 'styled-components';
import Label from '@scaleflex/ui/core/label';

const StyledTabs = styled.div`
  padding: 0 32px 0 0;
  overflow: hidden;
  max-height: 100%;
  width: 12.5%;

  [data-phone='true'] & {
    display: flex;
    padding: 0;
  }
`;

const StyledTabItem = styled.div(
  ({ isSelected, theme: { ada: theme } }) => `
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    background: ${theme.palette['light-w']};
    color: ${theme.palette['medium-grey']};
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    padding: 16px 32px;

    [data-phone='true'] & {
      margin-bottom: 0;
      height: 50px;
      border-radius: 0;
    }

    &,
    * {
      cursor: pointer;
    }

    &:hover {
      background: ${theme.palette['light-b']};
    }

    &[aria-selected='true'] {
      background: ${theme.palette['medium-green']};

      * {
        color: white;
      }
    }
  `
);

const StyledTabItemLabel = styled(Label)`
  margin: 16px 0 4px 0;
  font-size: 12px;
  line-height: 12px;
  text-align: center;
  max-width: unset;

  [data-phone='true'] & {
    font-size: 11.2px;
  }
`;

export { StyledTabs, StyledTabItem, StyledTabItemLabel };
