import styled from 'styled-components';
import { ToolbarButtonProps } from '../../custom.types';

export const ToolbarWrapper = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
`;

export const ToolbarInner = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: flex-end;
`;

export const ToolbarButtonWrapper = styled.button<
  Pick<ToolbarButtonProps, 'disable'>
>(
  ({ disable, theme: { ada: theme } }) => `
    border: none;
    font-size: 14px;
    color: ${
      disable ? theme.palette['light-grey'] : theme.palette['medium-grey']
    };
    background-color: unset;
    display: flex;
    align-items: center;
    cursor: ${disable ? 'initial' : 'pointer'};
    pointer-events: ${disable ? 'none' : 'all'};
    padding: 6px 0;
    /* opacity: ${disable ? '0.4' : '1'}; */

    /* &:last-child {
      margin-right: calc(100vw * 25 / 100);
    } */

    > svg {
      font-size: 16px;
      
      &.fa-2x {
        font-size: 32px;
      }
    }
  `
);

export const ToolbarLeftWrapper = styled.div`
  width: 83%;
  display: flex;
  justify-content: space-between;

  ${ToolbarButtonWrapper} {
    margin: 0 15px;

    /* > svg {
      height: 2rem;
    } */
  }
`;

export const Button = styled.button(
  ({ theme: { ada: theme } }) => `
    cursor: pointer;
    padding: 15px;
    color: white;
    background-color: ${theme.palette['pink']};
    font-size: 12px;
    line-height: 12px;
    border: 1px solid transparent;
    border-radius: 30px;
    white-space: nowrap;

    &:hover {
      color: ${theme.palette['pink']};
      background-color: white;
      border: 1px solid ${theme.palette['pink']};
    }
  `
);

export const AddImageButton = styled.button(
  ({ theme: { ada: theme } }) => `
    cursor: pointer;
    padding: 15px;
    color: ${theme.palette['medium-green']};
    background-color: white;
    font-size: 12px;
    line-height: 12px;
    border: 1px solid ${theme.palette['medium-green']};
    border-radius: 30px;
    white-space: nowrap;
    text-align: center;

    &:hover {
      color: white;
      background-color: ${theme.palette['medium-green']};
      border: 1px solid transparent;
    }
  `
);
