/** External Dependencies */
import React, { useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import * as FaCore from '@fortawesome/fontawesome-svg-core';
import Icon from '../common/Icon';

/** Internal Dependencies */
import { StyledTabItem, StyledTabItemLabel } from './Tabs.styled';

const TabItem = ({ id, label, icon, isSelected, onClick }) => {
  const handleClick = useCallback(() => {
    if (typeof onClick === 'function') {
      onClick(id);
    }
  }, [id]);

  return (
    <StyledTabItem
      aria-selected={isSelected}
      isSelected={isSelected}
      onClick={handleClick}
    >
      <FontAwesomeIcon size="2x" icon={icon} />
      {/* <Icon name={icon} /> */}
      {/* <FontAwesomeIcon
        size="2x"
        color="red"
        className="ada-medium-grey"
        icon={Icon}
      /> */}
      {label && (
        <StyledTabItemLabel className="FIE_tab-label">
          {label}
        </StyledTabItemLabel>
      )}
    </StyledTabItem>
  );
};

TabItem.defaultProps = {
  isSelected: false,
  onClick: undefined,
  label: undefined,
};

TabItem.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  /* Icon: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.func,
    PropTypes.instanceOf(Object),
  ]).isRequired, */
  // icon: PropTypes.instanceOf(FaCore.IconDefinition).isRequired,
  onClick: PropTypes.func,
  isSelected: PropTypes.bool,
};

export default memo(TabItem);
