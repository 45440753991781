export default {
  ada: {
    palette: {
      'light-w': '#dedede',
      'light-b': '#d5d7e0',
      'light-grey': '#c8c8c8',
      'medium-grey': '#7e7e7e',
      'dark-grey': '#333333',
      pink: '#9c495d',
      'medium-green': '#00a79f',
      'dark-green': '#004d58',
      SUCCESS: { bgColor: '#dff0d8', color: '#3c763d', border: '#d6e9c6' },
      WARNING: { bgColor: '#fff3cd', color: '#856404', border: '#ffeeba' },
      DANGER: { bgColor: '#f2dede', color: '#a94442', border: '#ebccd1' },
      INFO: { bgColor: '#cff4fc', color: '#055160', border: '#055160' },
    },
    typography: {
      fontFamily: 'Raleway, Arial',
    },
  },
};
