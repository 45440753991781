export default {
  "cropTool": "Crop",
  "nameIsRequired": "Name is required.",
  "adjustTab": "Adjust",
  "socialMedia": "Social media",
  "padding": "Padding",
  "addBackgroundAsText": "Add as text",
  "toBeUploaded": "to be uploaded",
  "imageTypeWillChangeOnSave": "On saving you will choose a new image type/extension to save with",
  "IconTab": "Icon",
  "reset": "Reset",
  "addIconTitle": "Choose the Icon type",
  "classicTv": "Classic TV",
  "importing": "Importing...",
  "brightnessTool": "Brightness",
  "storyPhoto": "Story photo",
  "resizeTab": "Resize",
  "twInStreamPhotoSize": "1600x1900px",
  "portrait": "Portrait",
  "toggleZoomMenuTitle": "Toggle zoom menu",
  "saveAs": "Save as",
  "uploadWatermark": "Upload watermark",
  "closeEditor": "Close editor",
  "resetZoomTitle": "Zoom percentage (Click to reset)",
  "headerPhoto": "Header photo",
  "letterSpacing": "Letter Spacing",
  "feedSquarePhoto": "Feed square photo",
  "rectangleTool": "Rectangle",
  "textTool": "Text",
  "arrowTool": "Arrow",
  "rotateTool": "Rotate",
  "twHeaderPhotoSize": "1500x500px",
  "igStoryPhotoSize": "1080x1920px",
  "position": "Position",
  "contrastTool": "Contrast",
  "unFlipX": "Un-Flip X",
  "igProfilePhoto": "320x320px",
  "horizontal": "Horizontal",
  "quality": "Quality",
  "ellipseTool": "Ellipse",
  "fbStoryPhotoSize": "1080x1920px",
  "annotateTab": "Draw",
  "value": "Value",
  "filtersTab": "Filters",
  "zoomOutTitle": "Zoom out",
  "hsvTool": "HSV",
  "igFeedSquarePhotoSize": "1080x1080px",
  "lineHeight": "Line height",
  "instagram": "Instagram",
  "addBackground": "+ Add background",
  "previewTool": "Preview",
  "liProfilePhotoSize": "400x400px",
  "finetuneTab": "Finetune",
  "uploadIcon": "Upload Icon",
  "liProfileCoverPhotoSize": "1584x396px",
  "inStreamPhoto": "In-stream photo",
  "twProfilePhotoSize": "400x400px",
  "removerTab": "Remover",
  "resizeWidthTitle": "Width in pixels",
  "toggleRatioLockTitle": "Toggle ratio lock",
  "annotationVariable": "Set annotation variable",
  "uploadImageError": "Error while uploading the image.",
  "custom": "Custom",
  "cropSizeLowerThanResizedWarning": "Note, the selected crop area is lower than the applied resize which might cause quality decrease",
  "showImageTitle": "Show original image",
  "continue": "Continue",
  "linkedIn": "LinkedIn",
  "actualSize": "Actual size (100%)",
  "back": "Back",
  "resize": "Resize",
  "flipY": "Flip Y",
  "addImage": "+ Add image",
  "fontFamily": "Font family",
  "polygonTool": "Polygon",
  "blurRadius": "blurRadius",
  "imageTypeNotSavingSupported": "Image type/extension is NOT supported on saving",
  "penTool": "Pen",
  "saturation": "Saturation",
  "addBackgroundTitle": "Choose the background type",
  "zoomInTitle": "Zoom in",
  "addWatermark": "+ Add watermark",
  "isNotImage": "is not image",
  "profileCoverPhoto": "Profile cover photo",
  "feedPortraitPhoto": "Feed portrait photo",
  "textAlignment": "Text alignment",
  "watermarkTab": "Watermark",
  "remover": "Remover",
  "twitter": "Twitter",
  "loading": "Loading...",
  "unFlipY": "Un-Flip Y",
  "landscape": "Landscape",
  "original": "Original",
  "saveAsModalLabel": "Save the image as",
  "size": "Size",
  "liCompanyLogoSize": "300x300px",
  "backgroundTab": "Background",
  "addWatermarkTitle": "Choose the watermark type",
  "igProfilePhotoSize": "320x320px",
  "cornerRadius": "Corner Radius",
  "addIcon": "+ Add Icon",
  "liBlogPostPhotoSize": "1200x627px",
  "cinemascope": "Cinemascope",
  "addWatermarkAsText": "Add as text",
  "lineTool": "Line",
  "warmthTool": "Warmth",
  "measureTool": "Measure",
  "save": "Save",
  "timelinePhoto": "Timeline photo",
  "eventCoverPhoto": "Event cover photo",
  "facebook": "Facebook",
  "changesLoseConfirmation": "All changes will be lost",
  "fbEventCoverPhotoSize": "1200x628px",
  "companyPageCover": "Company page cover",
  "blogPostPhoto": "Blog post photo",
  "undoTitle": "Undo last operation",
  "resetOperations": "Reset/delete all operations",
  "stroke": "Stroke",
  "name": "Name",
  "cancel": "Cancel",
  "download": "Download",
  "addIconAsText": "Add as text",
  "hue": "Hue",
  "textSpacings": "Text spacings",
  "fbProfilePhotoSize": "170x170px",
  "textTab": "Text",
  "srTab": "SR",
  "blurTool": "Blur",
  "uploadBackground": "Upload background",
  "downloadAsModalLabel": "Save the image as",
  "circle": "Circle",
  "imageDimensionsHoverTitle": "Saved image size (width x height)",
  "addByUrl": "+ Add URL",
  "changesLoseConfirmationHint": "Are you sure you want to continue?",
  "redoTitle": "Redo last operation",
  "feedLandscapePhoto": "Feed landscape photo",
  "invalidImageError": "Invalid image provided.",
  "shadow": "Shadow",
  "ellipse": "Ellipse",
  "square": "Square",
  "blur": "Blur",
  "sides": "Sides",
  "vertical": "Vertical",
  "igFeedLandscapePhotoSize": "1080x566px",
  "imageTool": "Image",
  "areNotImages": "are not images",
  "resetSize": "Reset to original image size",
  "extension": "Extension",
  "opacity": "Opacity",
  "cancelEditing": "Cancel editing",
  "liCompanyPageCoverSize": "1128x191px",
  "transparency": "Opacity",
  "profilePhoto": "Profile photo",
  "igFeedPortraitPhotoSize": "1080x1350px",
  "fitSize": "Fit size",
  "flipX": "Flip X",
  "fbProfileCoverPhotoSize": "851x315px",
  "fbTimelinePhotoSize": "1200x630px",
  "shapeTab": "Shape",
  "resizeHeightTitle": "Height in pixels",
  "companyLogo": "Company logo"
}
