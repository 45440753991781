const config = {
  reduxDevTools: process.env.NODE_ENV !== 'production',
  boGateway: {
    url: process.env.REACT_APP_TDB_BO_GATEWAY_URL,
  },
  galleryBe: {
    url: process.env.REACT_APP_TDB_BE_URL,
  },
  tdbOauth: {
    domain: process.env.REACT_APP_TDB_OAUTH_URL,
    paths: {
      authenticate: '/authenticate',
      token: '/oauth/token',
    },
    enable: process.env.REACT_APP_TDB_OAUTH_DISABLE === '1' ? false : true
  },
  dropzone: {
    maxUploadSize: 20000000,
    maxUploadNumber: 12,
  },
  defaultSizes: [
    { width: 1920, height: 1080, tag: "ARCHIVE_PREV", fit: "inside", suffix: "2x" },
    { width: 600, height: 450, tag: "ARCHIVE_GRID", fit: "inside", suffix: "2x" },
    { width: 240, height: 180, tag: "ARCHIVE_LIST", fit: "inside", suffix: "2x" },
    { width: 960, height: 540, tag: "ARCHIVE_PREV", fit: "inside", suffix: "" },
    { width: 300, height: 225, tag: "ARCHIVE_GRID", fit: "inside", suffix: "" },
    { width: 120, height: 90, tag: "ARCHIVE_LIST", fit: "inside", suffix: "" }
  ],
};

export default config;
