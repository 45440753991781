import { useTranslation } from 'react-i18next';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import {
  ImageOverlay,
  ImageContainer,
  GalleryRowWrapper,
  GalleryRow,
  ImageInfoArea,
  OverlayButton,
  SmallText,
  ImageContainerWrapper,
} from './GalleryItem.style';
import { GalleryItemType, GalleryViewState } from '../../custom.types';
import { RootState } from '../../app/store';

import Checkbox from '../UI/Checkbox/Checkbox';
import { closeGallery, selDeselItem, setCurrent } from '../../app/reducers/gallery.reducer';
import { byteConverter } from '../../app/helpers/common.helper';
import GalleryItemPicture from './GalleryItemPicture';
import OverlayType from './OverlayType';
import { useState } from 'react';

export default function GalleryItem({
  img,
  imgOnLoad,
}: {
  img: GalleryItemType;
  imgOnLoad: (() => void) | undefined;
}) {
  const { t } = useTranslation();
  const view = useAppSelector((state: RootState) => state.gallery.view);
  const dispatch = useAppDispatch();
  const isExternal = useAppSelector(
    (state: RootState) => state.gallery.isExternal
  );
  const items = useAppSelector((state: RootState) => state.gallery.items);
  const selected = items.filter((it) => it.selected === true).length;
  const [show, setShow] = useState<boolean>(false);

  const handleCheckboxChange = (isChecked: boolean) => {
    dispatch(selDeselItem({ item: img, isChecked }));
  };

  const handleOnLoad = () => {
    setShow(true);
    if (imgOnLoad) imgOnLoad();
  };

  const handleOnUse = () => {
    dispatch(setCurrent(img));
    dispatch(closeGallery());
  };

  return (
    <>
      {view === GalleryViewState.ARCHIVE_GRID ? (
        <ImageContainerWrapper>
          <ImageContainer width={'100%'}>
            <ImageOverlay visible={img.selected}>
              <Checkbox
                imgId={img.id}
                checked={img.selected}
                onChange={handleCheckboxChange}
                overlay={true}
              />
              {selected <= 1 && isExternal && (
                <OverlayButton onClick={handleOnUse}>{t('Usa')}</OverlayButton>
              )}
            </ImageOverlay>
            <GalleryItemPicture
              img={img}
              tag={GalleryViewState.ARCHIVE_GRID}
              imgOnLoad={handleOnLoad}
            />
            {show && <OverlayType img={img} />}
          </ImageContainer>
        </ImageContainerWrapper>
      ) : (
        <GalleryRowWrapper>
          <GalleryRow key={img.id}>
            <Checkbox
              imgId={img.id}
              checked={img.selected}
              onChange={handleCheckboxChange}
              overlay={false}
            />
            <ImageContainer width={'15%'}>
              <GalleryItemPicture
                img={img}
                tag={GalleryViewState.ARCHIVE_LIST}
                imgOnLoad={imgOnLoad}
              />
            </ImageContainer>
            <ImageInfoArea>
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <SmallText style={{ fontWeight: '700' }}>{img.name}</SmallText>
                <SmallText>{`${t('Data')}: ${new Date(
                  img.date
                ).toLocaleDateString()}`}</SmallText>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <SmallText>{`${t('Risoluzione')}: ${
                  img.resolution.full
                }`}</SmallText>
              </div>
              <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                <SmallText>{`${t('Dimensione')}: ${byteConverter(
                  img.size
                )}`}</SmallText>
              </div>
            </ImageInfoArea>
          </GalleryRow>
        </GalleryRowWrapper>
      )}
    </>
  );
}
