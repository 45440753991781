/** External Dependencies */
import styled, { css } from 'styled-components';
import Button from '@scaleflex/ui/core/button';
//import Button from '../Button';
import IconButton from '@scaleflex/ui/core/icon-button';

const StyledButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
  ${({ bg, theme: { ada: theme } }) =>
    bg &&
    css`
      background-color: ${theme.palette[bg]};
    `}
`;

const StyledMainButton = styled(Button)`
  color: white;
  background-color: transparent;
  ${({ isTopBar }) =>
    isTopBar &&
    css`
      padding: 12.8px 36.8px;
      border-radius: 32px;
    `}
  ${({ bg, theme: { ada: theme } }) =>
    bg &&
    css`
      background-color: ${theme.palette[bg]};
    `}
  ${({ keepBorderRadius }) =>
    keepBorderRadius
      ? ''
      : 'border-top-right-radius: 0; border-bottom-right-radius: 0'};

  .SfxButton-Label {
    font-size: 12px;

  }
  ${({ theme: { ada: theme }, disabled }) => !disabled &&
    css`
      &:hover {
        color: ${theme.palette['pink']};
        background-color: white;
        border: 1px solid ${theme.palette['pink']};
      }
  `}
`;

/* const StyledMainButton = styled(Button)`
  height: 100%;
  padding: 4px 8px;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  ${({ keepBorderRadius }) =>
    keepBorderRadius
      ? ''
      : 'border-top-right-radius: 0; border-bottom-right-radius: 0'};

  span {
    font-size: 12px !important;
    line-height: 14px !important;
  }
`; */
const StyledMenuButton = styled(IconButton)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: 1px solid white;

  border-radius: 0;

  height: 100%;
  padding: 8px 12px;
  background-color: ${({ theme: { ada: theme } }) => theme.palette.pink};
  color: white;

  svg {
    transform: rotate(-90deg);
    width: 10px;
    margin-top: 0px;
  }
`;

export { StyledButtonWrapper, StyledMainButton, StyledMenuButton };
