import { useTranslation } from 'react-i18next';

import { Title, TabSelector, Link } from './Sidebar.style';
import { AvailableSpace } from './AvailableSpace';
import { Column } from './UI/UI.style';
import { faFolderOpen, faFolderPlus } from '@fortawesome/pro-light-svg-icons';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import {
  setEditorState,
  setItemsTypeState,
} from '../app/reducers/gallery.reducer';
import { GalleryItemsTypes, GalleryStateEditor } from '../custom.types';
import ToolbarButton from './Toolbar/ToolbarButton';
import { RootState } from '../app/store';
import { useState } from 'react';

export default function Sidebar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const itemsType = useAppSelector(
    (state: RootState) => state.gallery.itemsType
  );
  const sources = useAppSelector((state: RootState) => state.gallery.sources);

  const [currentItemsType, setCurrentItemsType] =
    useState<GalleryItemsTypes | null>(itemsType);

  return (
    <Column
      width={'15%'}
      justifyContent={'space-between'}
      padding={'3rem 2.5rem'}
      borderRight={'1px solid #e7e7e790'}
      boxShadow={'5px 0 10px 0px #e7e7e780'}
    >
      <Title>{t('Galleria immagini')}</Title>
      <TabSelector>
        {sources.length > 0 &&
          sources.map((source, i) => (
            <Link
              isActive={itemsType === source.value}
              onClick={() => {
                if (source.value !== currentItemsType) {
                  dispatch(setItemsTypeState(source.value));
                  setCurrentItemsType(source.value);
                }
              }}
              key={i}
            >
              {t(source.value)}
            </Link>
          ))}
      </TabSelector>
      <ToolbarButton
        onClick={() => dispatch(setEditorState(GalleryStateEditor.ADD_FOLDER))}
        icon={faFolderPlus}
        text={t('Crea', { key: 'Cartella', count: 1 })}
      />
      <ToolbarButton
        onClick={() =>
          dispatch(setEditorState(GalleryStateEditor.MANAGE_FOLDER))
        }
        icon={faFolderOpen}
        text={t('Gestisci', { key: 'Cartella', count: 2 })}
      />
      <AvailableSpace />
    </Column>
  );
}
