import { ChangeEvent, createRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
  FolderForm,
  FolderFormInput,
  FolderFormMessages,
  Footer,
  ModalBody,
  ModalContent,
  ModalTitle,
  TopBar,
} from './Modal.style';
import { ButtonOk, ButtonCancel } from '../UI.style';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

import { Folders } from '../Select/Folders';
import { RootState } from '../../../app/store';
import {
  GalleryFolder,
  GalleryFolderAction,
  GalleryStateStatus,
} from '../../../custom.types';
import { manageFolder } from '../../../app/actions/gallery.action';
import { closeEditor } from '../../../app/reducers/gallery.reducer';
import { validateFolderName } from '../../../app/helpers/common.helper';
import { ValidationMessage } from '../ValidationMessage';

let errMessage: string;
let opts = {};

export default function ManageFolders() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [folder, setFolder] = useState<GalleryFolder | null>(null);
  // const [messages, setMessages] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');
  const [confirm, setConfirm] = useState<boolean>(false);
 
  const [valueChanged, setValueChanged] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [folderName, setFolderName] = useState<string>('');
  const [saved, setSaved] = useState<boolean>(false);

  const nameRef = createRef<HTMLInputElement>();
  const status = useAppSelector((state: RootState) => state.main.status);
  const folders = useAppSelector((state: RootState) => state.gallery.folders);

  let timerID: ReturnType<typeof setTimeout>;

  const SaveHandler = () => {
    if(!isValid) return;
    const name = nameRef.current?.value || '';
    dispatch(
      manageFolder({
        fn: GalleryFolderAction.EDIT,
        folder,
        name,
      })
    ).then((value) => {
      if (value) {
        // setMessages(['Messaggio Salvato']);
        dispatch(closeEditor());
        setFolder(null);
        setValueChanged(false);
        setSaved(true);
      }
    });
  };

  const DeleteHandler = () => {
    if (confirm) {
      setConfirm(false);
      dispatch(manageFolder({ fn: GalleryFolderAction.DELETE, folder })).then(
        (value) => {
          if (value) {
            dispatch(closeEditor());
            // setMessages(['Messaggio Delete']);
            setFolder(null);
            setSaved(true);
          }
        }
      );
    } else {
      // setMessages([`${t('Conferma eliminazione', { key: 'TheFolder_one' })}`]);
      setMessage(`${t('Conferma eliminazione', { count: 1, key: 'TheFolder_one' })}`);
      setConfirm(true);
    }
  };

  const OnChangeHandler = (folder: GalleryFolder) => {
    setMessage('');
    if (folder) {
      setFolder(folder);
    }
  };

  const editHandler = (e: ChangeEvent<HTMLInputElement>) => {
    
    const value = e.currentTarget.value
    setFolderName(value);
    
    if(timerID) {
      clearTimeout(timerID);
    }

    timerID = setTimeout(() => {
      setValueChanged(true);
      const isNameValid = validateFolderName(value);
      const isNew = folders.findIndex(f => f.name === value) === -1;
      if(!isNameValid) {
        //setError(() => ('Nome non valido.'));
        errMessage = 'invalid_name';
      }
      if(!isNew) {
        // setError(() => ('Cartella già esistente'));
        errMessage = 'Existing_media';
        opts = { type: 'Cartella_one', name: value };
      }
      setTimeout(() => {
        setIsValid(() => (isNameValid && isNew));
      }, 0)
    }, 300);
    
    const f = { ...folder, name: value } as GalleryFolder;
    setFolder(f);
  };

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef]);

  useEffect(() => {
    setTimeout(() => {
      setSaved(false)
      setIsValid(false)
    }, 300);
  }, [saved]);

  return (
    <ModalContent width="600px" height="auto">
      <TopBar onClick={() => dispatch(closeEditor())}>
        <ModalTitle color={theme.ada.palette['medium-green']}>{`${t(
          'Gestisci',
          { key: 'Cartella_other' }
        )}`}</ModalTitle>
        <FontAwesomeIcon
          color={theme.ada.palette['medium-grey']}
          icon={faX}
        />
      </TopBar>
      <ModalBody>
        {status !== GalleryStateStatus.LOADING && message !== '' && (
          <FolderFormMessages>
            <p style={{ fontSize: '18px', textAlign: 'center' }}>{message}</p>
          </FolderFormMessages>
        )}
        {!folder && !confirm && status !== GalleryStateStatus.LOADING && (
          <Folders
            handler={OnChangeHandler}
            label={t('Scegli la cartella da rinominare o eliminare')}
            placeholder={t('Select') || ''}
            disabled={false}
          />
        )}
        {folder && !confirm && status !== GalleryStateStatus.LOADING && (
          <FolderForm>
            <label>{t('Rinomina o Elimina la cartella')}</label>
            <FolderFormInput
              ref={nameRef}
              value={folder.name}
              onChange={editHandler}
            />
          </FolderForm>
        )}
        {!isValid && valueChanged && folderName !== '' && <ValidationMessage>{t(errMessage, opts)}</ValidationMessage>}
      </ModalBody>
      <Footer>
        {folder && status !== GalleryStateStatus.LOADING && (
          <>
            {!confirm ? (
              <>
                <ButtonOk onClick={SaveHandler} disabled={!isValid}>{t('Salva')}</ButtonOk>
                <ButtonCancel onClick={DeleteHandler}>{t('Elimina')}</ButtonCancel>
              </>
            ) : (
              <>
                <ButtonOk onClick={DeleteHandler}>{t('Conferma')}</ButtonOk>
                <ButtonCancel onClick={() => dispatch(closeEditor())}>{t('Annulla')}</ButtonCancel>
              </>
            )}
          </>
        )}
      </Footer>
    </ModalContent>
  );
}
