import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { selDeselItems, setView } from '../../app/reducers/gallery.reducer';
import Checkbox from '../UI/Checkbox/Checkbox';
import SearchInput from '../UI/SearchInput/SearchInput';
import { FilterDropdown } from './FilterDropdown';

import { GalleryViewState, ListItemProps } from '../../custom.types';
import { RootState } from '../../app/store';

import {
  faGrid2,
  faList,
  faArrowDownArrowUp,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  DisplaySettingsToolbar,
  CheckboxArea,
  InputArea,
  ButtonViewArea,
  SmallText,
  Button,
  SelectedItems,
} from './FilterBar.style';

import { Select } from '../UI/Select/Select';
import { useState } from 'react';

export default function FilterBar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const totalItems = useAppSelector(
    (state: RootState) => state.gallery.totalItems
  );
  const items = useAppSelector((state: RootState) => state.gallery.items);
  const isExternal = useAppSelector(
    (state: RootState) => state.gallery.isExternal
  );

  // const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const selected = items.filter((it) => it.selected === true).length;
  const isCheckboxChecked = items.length > 0 && selected === items.length;

  const handleCheckboxChange = (isChecked: boolean) => {
    // setIsCheckboxChecked(!isCheckboxChecked);
    dispatch(selDeselItems(isChecked));
  };

  const [showFilterDropdown, setShowFilterDropdown] = useState<boolean>(false);

  const handleShowHideFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
  };

  const handleBlurFilterDropdown = () => {
    setShowFilterDropdown(false);
  };

  const listItems: ListItemProps[] = [
    { id: 0, type: 'name', dir: -1, value: `${t('name')}`, selected: false },
    { id: 1, type: 'size', dir: -1, value: `${t('size')}`, selected: false },
    { id: 2, type: 'updateDate', dir: -1, value: `${t('Data di inserimento')}`, selected: true },
  ];

  return (
    <DisplaySettingsToolbar>
      <Select />
      <CheckboxArea>
        <Checkbox
          checked={isCheckboxChecked}
          onChange={handleCheckboxChange}
          overlay={false}
        />
        <label htmlFor="selectAllCheckbox">
          <SmallText>
            {t('Seleziona tutto')}
            {totalItems && selected !== 0 && (
              <SelectedItems>
                {t('selezionati')} {selected}/{totalItems}
              </SelectedItems>
            )}
          </SmallText>
        </label>
      </CheckboxArea>
      <InputArea>
        <SearchInput />
      </InputArea>
      <ButtonViewArea>
        <Button
          onClick={handleShowHideFilterDropdown}
          onBlur={handleBlurFilterDropdown}
        >
          <FontAwesomeIcon icon={faArrowDownArrowUp} />
        </Button>
        <FilterDropdown listItems={listItems} visible={showFilterDropdown}/>
        <Button
          onClick={() =>
            dispatch(
              setView({ view: GalleryViewState.ARCHIVE_GRID, isExternal })
            )
          }
        >
          <FontAwesomeIcon icon={faGrid2} />
        </Button>
        <Button
          onClick={() =>
            dispatch(
              setView({ view: GalleryViewState.ARCHIVE_LIST, isExternal })
            )
          }
        >
          <FontAwesomeIcon icon={faList} />
        </Button>
      </ButtonViewArea>
    </DisplaySettingsToolbar>
  );
}
