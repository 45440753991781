import styled, { css } from 'styled-components';

import { AlertProps, ColumnProps, SmallTextProps } from '../../custom.types';

export const Column = styled.div<ColumnProps>`
  height: 100%;
  display: flex;
  flex-direction: column;

  width: ${(props) => props.width};
  padding: ${(props) => props.padding};
  justify-content: ${(props) => props.justifyContent};
  border-right: ${(props) => props.borderRight};
  box-shadow: ${(props) => props.boxShadow};
`;

export const Container = styled.div<{ isExternal: boolean}>(
  ({ isExternal, theme: { ada: theme } }) => `
  position: relative;
  width: 100%;
  height: ${isExternal ? '100vh' : '100%'};
  box-sizing: border-box;
  background-color: #fff;
  overflow: hidden;
  display: flex;
  & * {
    font-family: ${theme.typography['fontFamily']};
  }
  & label {
    margin-bottom: 0;
  }
  `
);

export const SmallText = styled.p<SmallTextProps>`
  margin: 0;
  padding: 0;
  font-size: ${(props) => props.fontSize};
  color: ${(props) => props.color};
`;

export const Button = styled.button`
  cursor: pointer;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 15px;
  white-space: nowrap;
`;

export const ButtonOk = styled(Button)<{disabled?: boolean}>(
  ({ disabled, theme: { ada: theme } }) => css`
    color: white;
    border-radius: 30px;
    ${disabled ? css`
      background-color: ${theme.palette['light-grey']};
      cursor: not-allowed!important;
      border: 1px solid ${theme.palette['light-grey']};
    ` : css`
      background-color: ${theme.palette['pink']};
      border: 1px solid transparent;

      &:hover {
        color: ${theme.palette['pink']};
        background-color: white;
        border: 1px solid ${theme.palette['pink']};
        
      }
    `}
  `
);

export const ButtonCancel = styled(Button)(
  ({ theme: { ada: theme } }) => `
    color: ${theme.palette['medium-grey']};
    background-color: white;
    border-radius: 5px;
    border: 1px solid ${theme.palette['medium-grey']};
    margin-left: 10px;

    /* &:hover {
      color: ${theme.palette['pink']};
      background-color: white;
      border: 1px solid ${theme.palette['pink']};
    } */
  `
);

export const Input = styled.input`
  width: 100%;
  padding: 7px;
`;

export const SpinnerBackdrop = styled.div(
  ({ theme: { ada: theme } }) => `
    width: 100%;
    height: 100vh;
    background-color: ${theme.palette['light-w']};
    opacity: 0.5;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 101;
  `
);

export const Spinner = styled.div(
  ({ theme: { ada: theme } }) => `
    z-index: 102;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: ${theme.palette['medium-green']};
  `
);

const Alert = styled.div<AlertProps>(
  ({ type, theme: { ada: theme } }) => css`
    height: auto;
    min-height: 45px;
    padding: 10px;
    border-radius: 10px;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: ${theme.palette[type].bgColor};
    border: 1px solid ${theme.palette[type].border};
    color: ${theme.palette[type].color};
  `
);

export const MainAlert = styled(Alert)<AlertProps>(
  ({ show, isExternal }) => css`
    width: 15%;
    position: absolute;
    font-size: 13px;
    opacity: 0;
    top: -100px;
    transition: all 1s ease-in;
    -webkit-transition: all 1s ease-in;
    -moz-transition: all 1s ease-in;
    -o-transition: all 1s ease-in;
    ${isExternal ? css`
      right: 7%;
    ` : css`
      right: 20px;
    `}
    ${show ? css`
      opacity: 1;
      top: ${isExternal ? '3%' : '10px' };
    ` : css`
      opacity: 0;
      top: -100px;
    `}
  `
);

export const ModalAlert = styled(Alert)<AlertProps>(
  () => css`
    width: 100%;
  `
);