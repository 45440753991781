export default {
  "cropTool": "Uprawy",
  "nameIsRequired": "Wymagane jest podanie nazwy.",
  "adjustTab": "Regulacja",
  "socialMedia": "Media społecznościowe",
  "padding": "Wyściełanie",
  "addBackgroundAsText": "Dodaj jako tekst",
  "toBeUploaded": "do załadowania",
  "imageTypeWillChangeOnSave": "Podczas zapisywania zostanie wybrany nowy typ/rozszerzenie obrazu, z którym ma zostać zapisany",
  "IconTab": "Ikona",
  "reset": "Resetuj",
  "addIconTitle": "Wybierz typ ikony",
  "classicTv": "Telewizja klasyczna",
  "importing": "Importowanie...",
  "brightnessTool": "Jasność",
  "storyPhoto": "Zdjęcie Story",
  "resizeTab": "Zmień rozmiar",
  "twInStreamPhotoSize": "1600x1900px",
  "portrait": "Portret",
  "toggleZoomMenuTitle": "Przełącz menu powiększenia",
  "saveAs": "Zapisz jako",
  "uploadWatermark": "Prześlij Watermark",
  "closeEditor": "Zamknij edytor",
  "resetZoomTitle": "Procent powiększenia (Kliknij, aby wyzerować)",
  "headerPhoto": "Zdjęcie nagłówkowe",
  "letterSpacing": "Odstępy między literami",
  "feedSquarePhoto": "Nakarm kwadratowe zdjęcie",
  "rectangleTool": "Prostokąt",
  "textTool": "Tekst",
  "arrowTool": "Strzałka",
  "rotateTool": "Obróć",
  "twHeaderPhotoSize": "1500x500px",
  "igStoryPhotoSize": "1080x1920px",
  "position": "Pozycja",
  "contrastTool": "Kontrast",
  "unFlipX": "Odwróć X",
  "igProfilePhoto": "320x320px",
  "horizontal": "Pozioma",
  "quality": "Jakość",
  "ellipseTool": "Elipsa",
  "fbStoryPhotoSize": "1080x1920px",
  "annotateTab": "Rysuj",
  "value": "Wartość",
  "filtersTab": "Filtry",
  "zoomOutTitle": "Pomniejszanie",
  "hsvTool": "HSV",
  "igFeedSquarePhotoSize": "1080x1080px",
  "lineHeight": "Wysokość linii",
  "instagram": "Instagram",
  "addBackground": "+ Dodaj tło",
  "previewTool": "Podgląd",
  "liProfilePhotoSize": "400x400px",
  "finetuneTab": "Finetune",
  "uploadIcon": "Ikona wysyłania",
  "liProfileCoverPhotoSize": "1584x396px",
  "inStreamPhoto": "Zdjęcie In-stream",
  "twProfilePhotoSize": "400x400px",
  "resizeWidthTitle": "Szerokość w pikselach",
  "toggleRatioLockTitle": "Przełączanie blokady przełożenia",
  "annotationVariable": "Ustawienie zmiennej adnotacji",
  "uploadImageError": "Błąd podczas przesyłania obrazu.",
  "custom": "Własna strona",
  "cropSizeLowerThanResizedWarning": "Uwaga: wybrany obszar kadrowania jest mniejszy niż zastosowana zmiana rozmiaru, co może spowodować pogorszenie jakości",
  "showImageTitle": "Pokaż oryginalny obraz",
  "continue": "Kontynuuj",
  "linkedIn": "LinkedIn",
  "actualSize": "Rozmiar rzeczywisty (100%)",
  "back": "Powrót",
  "resize": "Zmień rozmiar",
  "flipY": "Przerzucanie Y",
  "addImage": "+ Dodaj obraz",
  "fontFamily": "Rodzina czcionek",
  "polygonTool": "Poligon",
  "blurRadius": "blurRadius",
  "imageTypeNotSavingSupported": "Typ obrazu/rozszerzenie NIE jest obsługiwane przy zapisie",
  "penTool": "Pióro",
  "saturation": "Nasycenie",
  "addBackgroundTitle": "Wybierz typ tła",
  "zoomInTitle": "Powiększenie",
  "addWatermark": "+ Dodaj znak wodny",
  "isNotImage": "nie jest obrazem",
  "profileCoverPhoto": "Zdjęcie okładki",
  "feedPortraitPhoto": "Zdjęcie portretowe paszy",
  "textAlignment": "Wyrównanie tekstu",
  "watermarkTab": "Znak wodny",
  "twitter": "Twitter",
  "loading": "Ładowanie...",
  "unFlipY": "Odepnij Y",
  "landscape": "Krajobraz",
  "original": "oryginał",
  "saveAsModalLabel": "Zapisz obraz jako",
  "size": "Rozmiar",
  "liCompanyLogoSize": "300x300px",
  "backgroundTab": "Tło",
  "addWatermarkTitle": "Wybierz typ znaku wodnego",
  "igProfilePhotoSize": "320x320px",
  "cornerRadius": "Promień narożnika",
  "addIcon": "+ Dodaj ikonę",
  "liBlogPostPhotoSize": "1200x627px",
  "cinemascope": "Cinemascope",
  "addWatermarkAsText": "Dodaj jako tekst",
  "lineTool": "Linia",
  "warmthTool": "Ciepło",
  "measureTool": "Środek",
  "save": "Zapisz",
  "timelinePhoto": "Zdjęcie na Timeline",
  "eventCoverPhoto": "Zdjęcie na okładkę imprezy",
  "facebook": "Facebook",
  "changesLoseConfirmation": "Wszystkie zmiany zostaną utracone.",
  "fbEventCoverPhotoSize": "1200x628px",
  "companyPageCover": "Okładka strony firmowej",
  "blogPostPhoto": "Zdjęcie wpisu na blogu",
  "undoTitle": "Cofnij ostatnią operację",
  "resetOperations": "Resetowanie/usuwanie wszystkich operacji",
  "stroke": "Udar mózgu",
  "name": "Nazwa",
  "cancel": "Anuluj",
  "download": "Pobierz",
  "addIconAsText": "Dodaj jako tekst",
  "hue": "Hue",
  "textSpacings": "Odstępy między tekstami",
  "fbProfilePhotoSize": "170x170px",
  "textTab": "Tekst",
  "srTab": "SR",
  "blurTool": "Rozmycie",
  "uploadBackground": "Prześlij tło",
  "downloadAsModalLabel": "Zapisz obraz jako",
  "circle": "Koło",
  "imageDimensionsHoverTitle": "Rozmiar zapisanego obrazu (szerokość x wysokość)",
  "addByUrl": "+ Dodaj adres URL",
  "changesLoseConfirmationHint": "Czy na pewno chcesz kontynuować?",
  "redoTitle": "Ponownie wykonaj ostatnią operację",
  "feedLandscapePhoto": "Zdjęcie krajobrazowe paszy",
  "invalidImageError": "Podano nieprawidłowy obraz.",
  "shadow": "Cień",
  "ellipse": "Elipsa",
  "square": "Kwadrat",
  "blur": "Rozmycie",
  "sides": "Boki",
  "vertical": "Pionowo",
  "igFeedLandscapePhotoSize": "1080x566px",
  "imageTool": "Obraz",
  "areNotImages": "nie są obrazami",
  "resetSize": "Przywracanie oryginalnego rozmiaru obrazu",
  "extension": "Przedłużenie",
  "opacity": "Krycie",
  "cancelEditing": "Anuluj edycję",
  "liCompanyPageCoverSize": "1128x191px",
  "transparency": "Krycie",
  "profilePhoto": "Zdjęcie profilowe",
  "igFeedPortraitPhotoSize": "1080x1350px",
  "fitSize": "Rozmiar dopasowany",
  "flipX": "Przerzuć X",
  "fbProfileCoverPhotoSize": "851x315px",
  "fbTimelinePhotoSize": "1200x630px",
  "resizeHeightTitle": "Wysokość w pikselach",
  "companyLogo": "Logo firmy"
}
