import af from './af.d.ts';
import ar from './ar.d.ts';
import de from './de.d.ts';
import en from './en.d.ts';
import enUS from './en-us.d.ts';
import es from './es.d.ts';
import fr from './fr.d.ts';
import he from './he.d.ts';
import it from './it.d.ts';
import ja from './ja.d.ts';
import ko from './ko.d.ts';
import nl from './nl.d.ts';
import pl from './pl.d.ts';
import pt from './pt.d.ts';
import ptBR from './pt-br.d.ts';
import ro from './ro.d.ts';
import ru from './ru.d.ts';
import sk from './sk.d.ts';
import sl from './sl.d.ts';
import vi from './vi.d.ts';
import zh from './zh.d.ts';

export const langs = {
  vi: { translation: vi },
  he: { translation: he },
  enUS: { translation: enUS },
  ko: { translation: ko },
  it: { translation: it },
  ja: { translation: ja },
  zh: { translation: zh },
  ar: { translation: ar },
  ptBR: { translation: ptBR },
  ru: { translation: ru },
  en: { translation: en },
  de: { translation: de },
  fr: { translation: fr },
  es: { translation: es },
  nl: { translation: nl },
  pl: { translation: pl },
  pt: { translation: pt },
  ro: { translation: ro },
  af: { translation: af },
  sk: { translation: sk },
  sl: { translation: sl },
};
