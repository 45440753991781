import { useState, useCallback, useEffect, useRef } from 'react';

import config from '../../../app/config/config';

import {
  ModalInnerWrapper,
  ModalContent,
  ModalTitle,
  UploadChooseBarWrapper,
  UploadChooseBar,
  UploadTypeButton,
  ImageContainer,
} from './Modal.style';
import { TopBar, ModalBody, LoadImage, Footer } from './Modal.style';
import { ButtonOk, ButtonCancel } from '../UI.style';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { ImageDropzone } from '../Dropzone/Dropzone';

import { ErrorCode, FileRejection, useDropzone } from 'react-dropzone';

import {
  GalleryFolderHandler,
  GalleryFolder,
  GalleryFolderAction,
  AlertType,
  UpFile,
  UploadStatus,
  FullStatus,
  GalleryResponse,
  GalleryItemPropType,
  GalleryItemsTypes,
} from '../../../custom.types';

import {
  byteConverter,
  encode,
  getImageSize,
  getMediaType,
} from '../../../app/helpers/common.helper';
import {
  dashboard,
  fetchMedia,
  saveFiles,
} from '../../../app/actions/gallery.action';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { AddInFolder } from '../../AddInFolder/AddInFolder';
import {
  closeEditor,
  incrPage,
  setItemsTypeState,
} from '../../../app/reducers/gallery.reducer';
import { RootState } from '../../../app/store';
import UrlInput from '../UrlInput/UrlInput';
import { InnerAlert } from '../Alert/Alert';
import { UploadMediaRow } from '../UploadMediaRow/UploadMediaRow';

let key = AlertType.SUCCESS;
let message: string;
let translationOpts = {};

export default function Add() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [uploadType, setUploadType] = useState<string>('PC');
  const [showImagesList, setShowImagesList] = useState<boolean>(true);
  const [selectedFolders, setSelectedFolders] = useState<GalleryFolder[]>([]);
  const [uploadError, setUploadError] = useState<boolean>(false);

  const [uploadSize, setUploadSize] = useState<number>(0);

  const [filesToUpload, setFilesToUpload] = useState<UpFile[]>([]);
  const [inProgress, setInProgress] = useState<boolean>(false);

  const [canSave, setCanSave] = useState<boolean>(false);

  const memory = useAppSelector((state: RootState) => state.gallery.memory);

  const uploadProgress = useRef<FullStatus>({});

  const itemsType = useAppSelector(
    (state: RootState) => state.gallery.itemsType
  );

  useEffect(() => {
    if (itemsType !== GalleryItemsTypes.MY_ITEMS) {
      dispatch(setItemsTypeState(GalleryItemsTypes.MY_ITEMS));
    }
  }, []);

  const addImageHandler = () => {
    if (memory.total - memory.used.original - uploadSize < 0) {
      key = AlertType.WARNING;
      message = 'upload_WARNING';
      setShowImagesList(false);
      return;
    }
    setInProgress(true);
  };

  const removeImageHandler = (id: number) => {
    setFilesToUpload((oldState) => oldState.filter((image) => image.id !== id));
  };

  const getUpFiles = (
    files: File[],
    origin: 'dropzone' | 'url',
    payload = ''
  ) => {
    const upFiles = [];
    let totalSizes = 0;
    let upFile: UpFile;

    if (origin === 'url') {
      const fileName = `${(Math.random() * 10000).toFixed(0)}-${(
        Math.random() * 10000
      ).toFixed(0)}.jpeg`;
      const imgSize = getImageSize(payload);
      upFile = {
        id: 0,
        name: fileName,
        date: new Date(),
        file: files[0],
        base64: payload,
        type: GalleryItemPropType.IMAGE,
        mimeType: 'image/jpeg',
        ext: 'jpeg',
        size: imgSize,
        status: UploadStatus.INIT,
        folderIds: [],
      };

      uploadProgress.current = {
        ...uploadProgress.current,
        0: {
          folderName: '',
          status: upFile.status,
        },
      };

      totalSizes = imgSize;
      upFiles.push(upFile);
    } else {
      for (let i = 0; i < files.length; i++) {
        upFile = {
          file: files[i],
          id: i,
          size: files[i].size,
          mimeType: files[i].type,
          name: files[i].name,
          type: getMediaType(files[i].type),
          date: files[i].lastModified,
          folderIds: [],
          ext: acceptedExt[files[i].type][0].replace('.', ''),
          status: UploadStatus.INIT,
        };

        upFiles.push(upFile);

        uploadProgress.current = {
          ...uploadProgress.current,
          [i]: {
            folderName: '',
            status: upFile.status,
          },
        };
        totalSizes += files[i].size;
      }
    }
    return { upFiles, totalSizes };
  };

  const onDrop = useCallback((uploadedFiles: File[]) => {
    const { upFiles, totalSizes } = getUpFiles(uploadedFiles, 'dropzone');
    setFilesToUpload(upFiles);
    setUploadSize((old) => (old += totalSizes));
  }, []);

  useEffect(() => {
    if (canSave) {
      dispatch(saveFiles(filesToUpload))
        .then((res) => {
          setCanSave(false);
          setFilesToUpload([]);
          uploadProgress.current = {};
          dispatch(closeEditor());
          dispatch(dashboard()).then(() => dispatch(incrPage()));
        })
        .catch((err) => {
          setShowImagesList(false);
          setUploadError(true);
        });
    }
  }, [canSave]);

  const onDropReject = useCallback((fileRejections: FileRejection[]) => {
    setUploadError(true);
    if (fileRejections.length > config.dropzone.maxUploadNumber) {
      key = AlertType.WARNING;
      message = 'upload_TOOMANYFILES';
      translationOpts = { max: config.dropzone.maxUploadNumber };
      setShowImagesList(false);
      return;
    }
    const firstError = fileRejections[0];
    if (firstError.errors[0].code === ErrorCode.FileTooLarge) {
      key = AlertType.WARNING;
      message = 'upload_FILETOOLARGE';
      const max = byteConverter(config.dropzone.maxUploadSize);
      translationOpts = { max };
      setShowImagesList(false);
      return;
    }
  }, []);

  const acceptedExt: { [key: string]: string[] } = {
    'image/png': ['.png'],
    'image/bmp': ['.bmp'],
    'image/gif': ['.gif'],
    'image/jpeg': ['.jpg', '.jpeg'],
    'image/tiff': ['.tif', '.tiff'],
    'image/webp': ['.webp'],
    'image/avif': ['.avif'],
    'image/raw': ['.arw'],
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: acceptedExt,
    maxFiles: config.dropzone.maxUploadNumber, // TODO: get right value and set it into config.ts file.
    maxSize: config.dropzone.maxUploadSize, // TODO: get right value and set it into config.ts file.
    onDropAccepted: onDrop,
    onDropRejected: onDropReject,
  });

  const selectFolderHandler = ({ fn, folder }: GalleryFolderHandler) => {
    const index = selectedFolders.findIndex((f) => f.id === folder.id);
    switch (fn) {
      case GalleryFolderAction.ADD:
        if (index === -1) {
          setSelectedFolders((oldState) => [...oldState, ...[folder]]);
        }
        break;
      case GalleryFolderAction.DELETE:
        if (index !== -1) {
          setSelectedFolders((oldState) =>
            oldState.filter((f) => f.id !== folder.id)
          );
        }
        break;
    }
  };

  const uploadCompletedHandler = (
    id: number,
    folderName: string,
    params: object
  ) => {
    uploadProgress.current = {
      ...uploadProgress.current,
      [id]: {
        folderName,
        status: UploadStatus.DONE,
      },
    };

    const progArr = Object.values(uploadProgress.current);
    if (progArr.every((s) => s.status === UploadStatus.DONE)) {
      const folderIds = selectedFolders.map((f) => f.id);
      setFilesToUpload((files) =>
        files.map((f) => ({
          ...f,
          folderName: progArr[f.id].folderName,
          folderIds,
          ...params,
        }))
      );
      setInProgress(false);
      setCanSave(true);
    }
  };

  const onImportURL = async (url: string) => {
    const encodedURL = await encode(url);
    dispatch(fetchMedia(encodedURL))
      .then(async (res) => {
        const { data } = res.payload as GalleryResponse;
        await fetch(data).then((res) => {
          res.blob()
            .then((blob) => {
              const file = new File([blob], 'test');
              const { upFiles, totalSizes } = getUpFiles([file], 'url', data);
              setFilesToUpload(upFiles);
              setUploadSize((old) => (old += totalSizes));
            })
            .catch((err) => {
              key = AlertType.DANGER;
              message = 'upload_DANGER_one';
              setShowImagesList(false);
              return;
            });
        });
      })
      .catch((err) => {
        key = AlertType.DANGER;
        message = 'upload_DANGER_one';
        setShowImagesList(false);
        return;
      });
  };

  return (
    <ModalContent width="600px" height="auto">
      <ModalInnerWrapper>
        <TopBar onClick={() => dispatch(closeEditor())}>
          <ModalTitle color={theme.ada.palette['medium-green']}>{`${t(
            'UploadFiles'
          )}`}</ModalTitle>
          <FontAwesomeIcon
            color={theme.ada.palette['medium-grey']}
            icon={faX}
          />
        </TopBar>
        <ModalBody>
          {showImagesList ? (
            <>
              <UploadChooseBarWrapper>
                <UploadChooseBar>
                  <UploadTypeButton
                    uploadType="PC"
                    isActive={uploadType === 'PC'}
                    onClick={() => setUploadType('PC')}
                  >{`${t('Carica')}`}</UploadTypeButton>
                  <UploadTypeButton
                    uploadType="URL"
                    isActive={uploadType === 'URL'}
                    onClick={() => setUploadType('URL')}
                  >{`${t('Importa da URL')}`}</UploadTypeButton>
                </UploadChooseBar>
              </UploadChooseBarWrapper>
              {filesToUpload.length === 0 ? (
                <LoadImage>
                  {uploadType === 'PC' ? (
                    <ImageDropzone
                      rootProps={getRootProps}
                      inputProps={getInputProps}
                    />
                  ) : (
                    <UrlInput onImportURL={onImportURL} />
                  )}
                </LoadImage>
              ) : (
                <ImageContainer>
                  {filesToUpload.map((f, i) => (
                    <UploadMediaRow
                      droppedFile={f}
                      key={i}
                      inProgress={inProgress}
                      onRemove={removeImageHandler}
                      onDone={uploadCompletedHandler}
                    />
                  ))}
                </ImageContainer>
              )}
              <AddInFolder
                label={`${t('PlaceInFolder', {
                  action: 'Caricare',
                  key: 'TheFile_one',
                })}`}
                handler={selectFolderHandler}
                selectedFolders={selectedFolders}
                disabled={inProgress}
              />
            </>
          ) : (
            <InnerAlert type={key} isExternal={false}>
              {t(message, translationOpts)}
            </InnerAlert>
          )}
        </ModalBody>
        <Footer>
          {showImagesList ? (
            <>
              {!inProgress && (
                <>
                  <ButtonOk
                    onClick={addImageHandler}
                    disabled={filesToUpload.length < 1}
                  >
                    {t('Aggiungi')}
                  </ButtonOk>
                  <ButtonCancel onClick={() => dispatch(closeEditor())}>
                    {t('Annulla')}
                  </ButtonCancel>
                </>
              )}
            </>
          ) : (
            <ButtonOk
              onClick={() => {
                dispatch(closeEditor());
                if (!uploadError) {
                  dispatch(dashboard()).then(() => dispatch(incrPage()));
                }
                setUploadError(false);
              }}
            >
              {t('OK')}
            </ButtonOk>
          )}
        </Footer>
      </ModalInnerWrapper>
    </ModalContent>
  );
}
