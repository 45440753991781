import { useTranslation } from 'react-i18next';

import { useState, MouseEvent, useEffect, useRef, Fragment } from 'react';
import {
  SelectFolderAreaWrapper,
  SelectFolderArea,
  SelectWrapper,
  SelectFolder,
  SelectList,
  CaretWrapper,
  SelectListItem,
} from './Select.style';

import { getSelFolderId, useAppSelector } from '../../../app/hooks';

import { GalleryFolder } from '../../../custom.types';

import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { RootState } from '../../../app/store';
import { incrPage, selFolder } from '../../../app/reducers/gallery.reducer';
import { useAppDispatch } from '../../../app/hooks';

import { SmallText } from '../../FilterBar/FilterBar.style';

export const Select = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const folders = useAppSelector((state: RootState) => state.gallery.folders);

  const [dropdownVisible, setDropdownVisible] = useState<boolean>(false);
  const [objSelectedFolder, setObjSelectedFolder] = useState<GalleryFolder>();

  const selectedFolder = getSelFolderId();

  const handleSelectFolder = (event: MouseEvent<HTMLButtonElement>) => {
    const selValue = String(event.currentTarget.value);

    const folder = folders.find((el) => el.id === selValue);

    setObjSelectedFolder(folder as GalleryFolder);

    if (folder) {
      dispatch(selFolder(folder.id));
      setTimeout(() => {
        dispatch(incrPage());
      }, 0);
    }

    close();
  };

  // useEffect(() => {
  //   dispatch(getFoldersList());
  // }, []);

  useEffect(() => {
    setObjSelectedFolder((prevSelFolder) => {
      const selectedFolder = folders.find((el) => el.selected) as GalleryFolder;
      if (selectedFolder) {
        if (
          !prevSelFolder ||
          prevSelFolder?.id !== selectedFolder.id ||
          prevSelFolder.source !== selectedFolder.source
        ) {
          return folders.find((f) => f.id === selectedFolder.id);
        }
      }
      return prevSelFolder;
    });
  }, [folders]);

  const menuRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (menuRef.current && dropdownVisible) {
      menuRef.current.focus();
    }
  }, [dropdownVisible]);

  function close() {
    setDropdownVisible(false);
  }

  return (
    <SelectFolderAreaWrapper>
      <SelectFolderArea>
        <label htmlFor="selectFolder">
          <SmallText>{t('Cartella')}:</SmallText>
        </label>
        <SelectWrapper onClick={() => setDropdownVisible(!dropdownVisible)}>
          {objSelectedFolder && (
            <SelectFolder defaultValue={selectedFolder}>
              {objSelectedFolder.id !== '0' && objSelectedFolder.name}
              {objSelectedFolder.id === '0' && t(objSelectedFolder.name)}
            </SelectFolder>
          )}
          <CaretWrapper visible={dropdownVisible}>
            <FontAwesomeIcon icon={faCaretDown} pull={'right'} fixedWidth />
          </CaretWrapper>
        </SelectWrapper>
        <SelectList visible={dropdownVisible} onBlur={close}>
          {folders.map((folder, i) => (
            <Fragment key={i}>
              {i === 0 ? (
                <SelectListItem
                  value={folder.id}
                  onMouseDown={handleSelectFolder}
                  ref={menuRef}
                >
                  {t(folder.name)}
                </SelectListItem>
              ) : (
                <SelectListItem
                  value={folder.id}
                  onMouseDown={handleSelectFolder}
                >
                  {folder.name}
                </SelectListItem>
              )}
            </Fragment>
          ))}
        </SelectList>
      </SelectFolderArea>
    </SelectFolderAreaWrapper>
  );
};
