import { useState } from 'react';
import {
  FilterDropdownWrapper,
  DropdownList,
  DropdownListItem,
  IconContainer,
} from './FilterBar.style';

import { faSortDown, faSortUp } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemProps } from '../../custom.types';
import { useAppDispatch } from '../../app/hooks';
import { incrPage, setOrder } from '../../app/reducers/gallery.reducer';

export const FilterDropdown = ({ listItems, visible } : { listItems: ListItemProps[], visible: boolean }) => {

  const dispatch = useAppDispatch();
  const [elements, setElements] = useState<ListItemProps[]>(listItems);

  const handleSelectOrder = (id: number) => {
    const elem = elements.find((item) => item.id === id);
    if(elem) {
      if(elem.selected) {
        setElements([ ...elements.filter((e) => e.id !== elem.id), { ...elem, dir: elem.dir * -1 }].sort((a, b) => (a.id - b.id)));
      } else {
        setElements([ ...elements.filter((e) => e.id !== elem.id).map((e) => ({ ...e, selected: false})), { ...elem, dir: elem.dir * -1, selected: true } ].sort((a, b) => (a.id - b.id)));
      }
      dispatch(setOrder({ key: elem.type, direction: elem.dir }));
      setTimeout(() => {
        dispatch(incrPage());
      }, 0);
    }
  };

  const getIcon = (item: ListItemProps) => {
    return item.dir === -1 ? faSortDown : faSortUp;
  };

  return (
    <FilterDropdownWrapper visible={visible}>
      <DropdownList>
        {elements.map((item, i) => (
          <DropdownListItem
            key={i}
            onMouseDown={() => handleSelectOrder(item.id)}
            selected={item.selected}
          >
            <div>{item.value}</div>
            <IconContainer>
              {item.selected && (
                <FontAwesomeIcon icon={getIcon(item)} pull="right" />
              )}
            </IconContainer>
          </DropdownListItem>
        ))}
      </DropdownList>
    </FilterDropdownWrapper>
  );
};
