import React from 'react';
import { createRoot } from 'react-dom/client';
/* import { BrowserRouter } from 'react-router-dom'; */
import { ThemeProvider } from 'styled-components';
import { Provider } from 'react-redux';
import { store } from './app/store';
import './app/i18n';
import App, { AdaGallery } from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import adaTheme from './theme';

const container = document.getElementById('ada-gallery') || null;

if (container !== null) {
  const root = createRoot(container);

  /* root.render(
    <Provider store={store}>
      <App container={container} />
    </Provider>
  ); */
  root.render(
    <Provider store={store}>
      <ThemeProvider theme={adaTheme}>
        <App container={container} />
      </ThemeProvider>
    </Provider>
  );
  /* root.render(
    <Provider store={store}>
      <MyContext.Provider value="pluto">
        <App container={container} />
      </MyContext.Provider>
    </Provider>
  ); */
  /* root.render(
    <React.StrictMode>
      <Provider store={store}>
        <App />
      </Provider>
    </React.StrictMode>
  ); */
  /* root.render(
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  ); */

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
} else {
  alert('no container found!!');
}

// export { default as MyLibrary } from './App';
export default AdaGallery;
