/** External Dependencies */
import styled, { css } from 'styled-components';
import Button from '@scaleflex/ui/core/button';
import IconButton from '@scaleflex/ui/core/icon-button';
import Input from '@scaleflex/ui/core/input';
import Label from '@scaleflex/ui/core/label';
import Select from '@scaleflex/ui/core/select';

const StyledTopbar = styled.div`
  padding: 12px 12px 8px 12px;
  box-sizing: border-box;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${({ reverseDirection }) =>
    reverseDirection ? 'flex-direction: row-reverse' : ''};

  [data-phone='true'] & {
    padding: 6px 6px 4px 6px;
  }
  overflow: auto;
  & > div:first-child {
  }
`;

const StyledHistoryButtonsWrapper = styled.div`
  display: flex;
  margin: 0 4px;
  column-gap: 4px;
  align-items: center;
  position: absolute;
  left: 0;
  color: ${({ theme: { ada: theme } }) => theme.palette['medium-grey']};
`;

const StyledHistoryButton = styled(IconButton)`
  margin: ${({ margin }) => margin ?? '0 4px'};
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 8px;
`;

const DimensionsLabel = styled(Label)`
  flex-shrink: 0;
  font-size: 16px;
`;

const common = (theme) => `
  padding: 12px;
  border-radius: 4px;
  position: relative;
  flex: 1 1 auto;
  display: flex;
  cursor: pointer;
  border: 1px solid ${theme.palette['medium-grey']};
`;

const SmallButton = styled(IconButton)`
  ${({ theme: { ada: theme } }) => common(theme)}
  font-size: 12px;
  color: ${({ theme: { ada: theme } }) => theme.palette['medium-grey']};
  ${(props) =>
    props.br &&
    css`
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      margin-left: -1px;
    `}
  ${(props) =>
    props.bl &&
    css`
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    `}
`;

const SaveButtonWrapper = styled.div`
  justify-content: flex-start;
  width: 16%;
  flex: 0 0 auto;
`;

const StyledFlexCenterAlignedContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1 1 auto;
  position: relative;
  justify-content: center;
  fon
  ${({ reverseDirection }) =>
    reverseDirection ? 'flex-direction: row-reverse' : ''};
`;

const ZoomPercentage = styled(Label)`
  ${({ theme: { ada: theme } }) => common(theme)}
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  margin: 0 0 0 -1px;
  font-size: 14.4px;
  align-items: center;
`;

const StyledBackButtonLabel = styled.span`
  font-size: 11px;
  line-height: 12px;
`;

const StyledCloseOrBackButton = styled(IconButton)`
  padding: 0;
  z-index: 111;
`;

const StyledSaveButton = styled(Button)`
  padding: 4px 12px;
`;

const StyledFileNameInput = styled(Input)`
  width: 200px;
`;

const StyledFileExtensionSelect = styled(Select)`
  width: 60px;
  margin-left: 4px;
`;

const StyledQualityWrapper = styled.div`
  width: 100%;
  margin-top: 8px;
`;

const StyledResizeOnSave = styled.div`
  margin-top: 8px;
  width: 100%;
`;

const ZoomWrapper = styled.div`
  position: relative;
  display: inline-flex;
  vertical-align: middle;
  margin: 4px 2px 4px 16px;
`;

export {
  StyledTopbar,
  SaveButtonWrapper,
  StyledFlexCenterAlignedContainer,
  StyledHistoryButton,
  SmallButton,
  ZoomPercentage,
  StyledBackButtonLabel,
  StyledCloseOrBackButton,
  StyledSaveButton,
  StyledFileNameInput,
  StyledFileExtensionSelect,
  StyledQualityWrapper,
  StyledResizeOnSave,
  DimensionsLabel,
  StyledHistoryButtonsWrapper,
  ZoomWrapper,
};
