import { useTranslation } from 'react-i18next';

import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { useAppDispatch } from '../../../app/hooks';
import { closeEditor, incrPage, renameItem } from '../../../app/reducers/gallery.reducer';

import { GalleryItemType } from '../../../custom.types';

import { ModalContent, ModalTitle } from './Modal.style';
import { TopBar, ModalBody, Footer } from './Modal.style';
import { ButtonOk, ButtonCancel, Input } from '../UI.style';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../../theme';
import { dashboard, editItem } from '../../../app/actions/gallery.action';
import { validateFileName } from '../../../app/helpers/common.helper';
import { ValidationMessage } from '../ValidationMessage';

let errMessage: string;

export default function Rename({
  currentItem,
}: {
  currentItem: GalleryItemType;
}) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [ext, setExt] = useState<string>('');
  const [name, setName] = useState<string>('');

  const timerID = useRef<number | undefined>(undefined);
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (currentItem.name) {
      setExt(currentItem.ext);
      setName(currentItem.name);
      // const arr = currentItem.name.split('.');
      // const ext = arr.pop();
      // if(ext) {
      //   setExt(ext);
      //   setName(arr.join('.'));
      // }
    }
  }, [currentItem]);

  const onChangeHandler = (evt: ChangeEvent<HTMLInputElement>) => {
    const filename = inputRef.current?.value || '';
    setName(() => filename);

    if(timerID.current) {
      clearTimeout(timerID.current);
    }

    timerID.current = window.setTimeout(() => {
      const isValidFilename = validateFileName(filename);
      if(!isValidFilename) {
        errMessage = 'invalid_name';
      }
      setIsValid(() => isValidFilename);
    }, 300);

  }

  const renameItemHandler = () => {
    if (inputRef && currentItem && inputRef.current) {
      const name = inputRef.current?.value || '';
      setIsValid(() => validateFileName(name));
      dispatch(editItem({ name: `${name}.${ext}` }))
        .then(() => dispatch(closeEditor(true)));
      
      dispatch(dashboard()).then(() => dispatch(incrPage()));
    }
  };
  return (
    <>
      {currentItem && (
        <ModalContent width="600px" height="300px">
          <TopBar onClick={() => dispatch(closeEditor())}>
            <ModalTitle color={theme.ada.palette['medium-green']}>{`${t(
              'Rinomina'
            )}`}</ModalTitle>
            <FontAwesomeIcon
              color={theme.ada.palette['medium-grey']}
              icon={faX}
            />
          </TopBar>
          <ModalBody>
            <p>{`${t('Inserisci il nuovo nome')}`}</p>
            <Input value={name} ref={inputRef} onChange={onChangeHandler} />
            {!isValid && <ValidationMessage>{t(errMessage, {})}</ValidationMessage>}
          </ModalBody>
          <Footer>
            <ButtonOk onClick={renameItemHandler} disabled={!isValid}>{t('Conferma')}</ButtonOk>
            <ButtonCancel onClick={() => dispatch(closeEditor())}>
              {t('Annulla')}
            </ButtonCancel>
          </Footer>
        </ModalContent>
      )}
    </>
  );
}
