import styled, { css } from "styled-components";

export const UploadProgressBarWrapper = styled.div(
  ({ theme: { ada: theme } }) => css`
    width: 100%;
    border-radius: 30px;
    height: 20px;
    border: 1px solid ${theme.palette['medium-green']};
    display: flex;
    position: relative;
  `
);

export const Bar = styled.div<{ progress: number }>(
  ({ progress, theme: { ada: theme } }) => css`
    background: linear-gradient(to right, ${theme.palette['medium-green']} ${progress}%, white 0);
    width: 100%;
    height: 90%;
    border-radius: 30px;
    margin: 1px;
    justify-content: flex-start;
    align-items: center;
  `
);

export const ProgressValue = styled.p<{ progress: number }>(
  ({ progress, theme: { ada: theme } }) => css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: linear-gradient(to right, white ${progress}%, ${theme.palette['medium-green']} 0);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    -webkit-text-fill-color: transparent;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
    font-size: 10px;
  `
);