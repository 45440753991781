export default {
  "cropTool": "Recadrage",
  "nameIsRequired": "Le nom est requis.",
  "adjustTab": "Ajuster",
  "socialMedia": "Médias sociaux",
  "padding": "Rembourrage",
  "addBackgroundAsText": "Ajouter comme texte",
  "toBeUploaded": "à enregistrer",
  "imageTypeWillChangeOnSave": "Lors de l'enregistrement, vous choisirez un nouveau type/extension d'image pour enregistrer",
  "IconTab": "Icône",
  "reset": "Réinitialiser",
  "addIconTitle": "Choisissez le type d'icône",
  "classicTv": "TV classique",
  "importing": "import...",
  "brightnessTool": "Brillance",
  "storyPhoto": "Photo de Story",
  "resizeTab": "Changer taille",
  "twInStreamPhotoSize": "1600x1900px",
  "portrait": "Portrait",
  "toggleZoomMenuTitle": "Basculer le menu de zoom",
  "saveAs": "Enregistrer sous",
  "uploadWatermark": "Charger une watermark",
  "closeEditor": "Fermer l'éditeur",
  "resetZoomTitle": "Pourcentage de zoom (cliquer pour réinitialiser)",
  "headerPhoto": "Photo de couverture",
  "letterSpacing": "Espacement du lettrage",
  "feedSquarePhoto": "Photo format carré",
  "rectangleTool": "Rectangle",
  "textTool": "Texte",
  "arrowTool": "Flèche",
  "rotateTool": "Rotation",
  "twHeaderPhotoSize": "1500x500px",
  "igStoryPhotoSize": "1080x1920px",
  "position": "Position",
  "contrastTool": "Contraste",
  "unFlipX": "Renverser X",
  "igProfilePhoto": "320x320px",
  "horizontal": "Horizontal",
  "quality": "Qualité",
  "ellipseTool": "Elipse",
  "fbStoryPhotoSize": "1080x1920px",
  "annotateTab": "Dessiner",
  "value": "Valeur",
  "filtersTab": "Filtres",
  "zoomOutTitle": "Dézoomer",
  "hsvTool": "HSV",
  "igFeedSquarePhotoSize": "1080x1080px",
  "lineHeight": "Hauteur de ligne",
  "instagram": "Instagram",
  "addBackground": "+ Ajouter un arrière-plan",
  "previewTool": "Prévisualisation",
  "liProfilePhotoSize": "400x400px",
  "finetuneTab": "Affiner",
  "uploadIcon": "Téléchargement icône",
  "liProfileCoverPhotoSize": "1584x396px",
  "inStreamPhoto": "Photo en ligne",
  "twProfilePhotoSize": "400x400px",
  "resizeWidthTitle": "Largeur en pixels",
  "toggleRatioLockTitle": "Blockage de ratio",
  "annotationVariable": "Définir une variable d'annotation",
  "uploadImageError": "Erreur à la sauvegarde de l'image.",
  "custom": "Personnalisé",
  "cropSizeLowerThanResizedWarning": "Remarque : la zone de recadrage sélectionnée est inférieure au redimensionnement appliqué, ce qui peut entraîner une baisse de qualité.",
  "showImageTitle": "Montrer l'image originale",
  "continue": "Continuer",
  "linkedIn": "LinkedIn",
  "actualSize": "Taille réelle (100%)",
  "back": "Retour",
  "resize": "Redimensionner",
  "flipY": "Renverser Y",
  "addImage": "+ Ajouter une image",
  "fontFamily": "Famille de police",
  "polygonTool": "Polygone",
  "blurRadius": "Radius de flou",
  "imageTypeNotSavingSupported": "Le type/l'extension d'image n'est PAS pris en charge lors de l'enregistrement",
  "penTool": "Stylo",
  "saturation": "Saturation",
  "addBackgroundTitle": "Choisir le type d'arrière-plan",
  "zoomInTitle": "Zoomer",
  "addWatermark": "+ Ajouter un watermark",
  "isNotImage": "n'est pas une image",
  "profileCoverPhoto": "Photo de couverture",
  "feedPortraitPhoto": "Photo format Portrait",
  "textAlignment": "Alignement du texte",
  "watermarkTab": "Filigrane",
  "twitter": "Twitter",
  "loading": "Chargement...",
  "unFlipY": "Renverser Y",
  "landscape": "Paysage",
  "original": "Originale",
  "saveAsModalLabel": "Enregistrer comme",
  "size": "Taille",
  "liCompanyLogoSize": "300x300px",
  "backgroundTab": "Arrière-plan",
  "addWatermarkTitle": "Choisir le type de watermark",
  "igProfilePhotoSize": "320x320px",
  "cornerRadius": "Radius d'angle",
  "addIcon": "+ Ajouter une icône",
  "liBlogPostPhotoSize": "1200x627px",
  "cinemascope": "Cinémascope",
  "addWatermarkAsText": "Ajouter comme texte",
  "lineTool": "Ligne",
  "warmthTool": "Chaleur",
  "measureTool": "Mesure",
  "save": "Sauvez",
  "timelinePhoto": "Photo de Timeline",
  "eventCoverPhoto": "Photo de couverture de l'événement",
  "facebook": "Facebook",
  "changesLoseConfirmation": "Tous le changements seront perdus",
  "fbEventCoverPhotoSize": "1200x628px",
  "companyPageCover": "Page de la société",
  "blogPostPhoto": "Photo de blog",
  "undoTitle": "Annuler la dernière opération",
  "resetOperations": "Annuler toutes les opérations",
  "stroke": "Rayon",
  "name": "Nom",
  "cancel": "Annuler",
  "download": "Télécharger",
  "addIconAsText": "Ajouter comme texte",
  "hue": "Hue",
  "textSpacings": "Espaces de texte",
  "fbProfilePhotoSize": "170x170px",
  "textTab": "Texte",
  "srTab": "SR",
  "blurTool": "Flou",
  "uploadBackground": "Charger un arrière-plan",
  "downloadAsModalLabel": "Enregistrez l'image en tant que",
  "circle": "Cercle",
  "imageDimensionsHoverTitle": "Taille de l'image sauvegardée (largeur x hauteur)",
  "addByUrl": "+ Ajouter un URL",
  "changesLoseConfirmationHint": "Etes-vous sûr de vouloir continuer ?",
  "redoTitle": "Répéter",
  "feedLandscapePhoto": "Photo format paysage",
  "invalidImageError": "Image non valide.",
  "shadow": "Ombre",
  "ellipse": "Elipser",
  "square": "Carré",
  "blur": "Flouter",
  "sides": "Côtés",
  "vertical": "Verticale",
  "igFeedLandscapePhotoSize": "1080x566px",
  "imageTool": "Image",
  "areNotImages": "Ne sont pas des images",
  "resetSize": "Réinitialiser à la taille d'origine",
  "extension": "Extention",
  "opacity": "Opacité",
  "cancelEditing": "Annuler la modification",
  "liCompanyPageCoverSize": "1128x191px",
  "transparency": "Opacité",
  "profilePhoto": "Photo de profil",
  "igFeedPortraitPhotoSize": "1080x1350px",
  "fitSize": "Taille adaptée",
  "flipX": "Renverser X",
  "fbProfileCoverPhotoSize": "851x315px",
  "fbTimelinePhotoSize": "1200x630px",
  "resizeHeightTitle": "Hauteur en pixels",
  "companyLogo": "Logo de la société"
}
