import { configureStore } from '@reduxjs/toolkit';
import config from './config/config';
import galleryReducer from './reducers/gallery.reducer';
import mainReducer from './reducers/main.reducer';

export const store = configureStore({
  reducer: {
    gallery: galleryReducer,
    main: mainReducer,
  },
  devTools: config.reduxDevTools,
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
