import styled from 'styled-components';

export const Title = styled.h3`
  font-weight: 700 !important;
  margin: 0;
  padding: 0 2em 1em 0;
`;

export const TabSelector = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
`;

export const Link = styled.button<{ isActive?: boolean }>`
  font-size: 14px;
  padding: 0;
  margin: 10px 0;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: 0;
  text-align: left;
  font-weight: 700;

  color: ${({ isActive, theme: { ada: theme } }) =>
    isActive ? theme.palette['medium-green'] : theme.palette['dark-grey']};
`;
