import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToolbarButtonWrapper } from './Toolbar.style';
import { ToolbarButtonProps } from '../../custom.types';

export default function ToolbarButton(props: ToolbarButtonProps) {
  return (
    <ToolbarButtonWrapper onClick={props.onClick} disable={props.disable}>
      <FontAwesomeIcon
        icon={props.icon}
        size="2x"
        {...(props.text ? { pull: 'left' } : {})}
      />
      {props.text}
    </ToolbarButtonWrapper>
  );
}
