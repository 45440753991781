import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';

import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import GalleryItem from '../GalleryItem/GalleryItem';
import {
  GalleryContainerWrapper,
  GalleryContainerInner,
  EmptyGalleryContainer,
  ScrollHint,
} from './GalleryContainer.style';
import { incrPage, removeAlerts } from '../../app/reducers/gallery.reducer';
import {
  getGalleryList,
  getFoldersList,
} from '../../app/actions/gallery.action';
import { Alert } from '../UI/Alert/Alert';

export default function GalleryContainer() {
  const { t } = useTranslation();
  const items = useAppSelector((state: RootState) => state.gallery.items);
  const alerts = useAppSelector((state: RootState) => state.gallery.alerts);
  const isExternal = useAppSelector((state: RootState) => state.gallery.isExternal);
  const page = useAppSelector((state: RootState) => state.gallery.currentPage);
  const loadedPages = useAppSelector(
    (state: RootState) => state.gallery.loadedPages
  );
  const totalPages = useAppSelector(
    (state: RootState) => state.gallery.totalPages
  );
  const itemsType = useAppSelector(
    (state: RootState) => state.gallery.itemsType
  );
  const dispatch = useAppDispatch();
  const containerRef = useRef(null);

  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);

  const [alertVisible, setAlertVisible] = useState<boolean>(false);

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  const callbackFn = (entries: any[]) => {
    if (entries[0].isIntersecting && showLoader) {
      dispatch(incrPage());
    }
  };

  useEffect(() => {
    const observer = new IntersectionObserver(callbackFn, {
      root: null,
      threshold: 0.5,
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [showLoader]);

  useEffect(() => {
    // if (page === 1) dispatch(getGalleryList(page));
    if (page > loadedPages) dispatch(getGalleryList());
    if (page >= totalPages) {
      setShowLoader(false)
    }
  }, [page]);

  useEffect(() => {
    if(itemsType) {
      // dispatch(getFoldersList())
      // .then(() => setShowLoader(false));
      dispatch(getFoldersList());
    }
  }, [itemsType]);

  useEffect(() => {
    if(alerts) {
      setShowAlert(true);
      setTimeout(() => {
        setAlertVisible(true);
      }, 300)
      setTimeout(() => {
        setShowAlert(false);
        setAlertVisible(false);
        dispatch(removeAlerts());
      }, 5000)
    }
  }, [alerts]);

  const closeAlert = () => {
    setAlertVisible(false);
    setTimeout(() => {
      setShowAlert(false);
      dispatch(removeAlerts());
    }, 300);
  }

  const imgOnLoad = () => setShowLoader(true);

  return (
    <>
      <GalleryContainerWrapper>
        {showAlert && alerts && (
          <Alert type={alerts.alertKey} isExternal={isExternal} show={alertVisible} onClick={closeAlert}>
            {t(alerts.message, alerts.options)}
          </Alert>
        )}
        {items.length > 0 ? (
          <GalleryContainerInner>
            {items.map((img, i) => (
              <GalleryItem
                key={i}
                img={img}
                imgOnLoad={i + 1 === items.length && page < totalPages ? imgOnLoad : undefined}
              />
            ))}
            {page !== totalPages && <ScrollHint />}
            {showLoader && (
              <div
                ref={containerRef}
                /* ref={ref} */
                style={{
                  bottom: '0px',
                  height: '50px',
                  width: '100%',
                  /* backgroundColor: 'red', */
                  marginTop: '50px',
                }}
              ></div>
            )}
          </GalleryContainerInner>
        ) : (
          <EmptyGalleryContainer>
            <h3>{t('Carica la tua prima immagine')}</h3>
          </EmptyGalleryContainer>
        )}
      </GalleryContainerWrapper>
    </>
  );
}
