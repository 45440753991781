/** External Dependencies */
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowsRotate,
  faTriangleExclamation,
} from '@fortawesome/pro-light-svg-icons';

/** Internal Dependencies */
import { useStore } from 'ada-image-editor/hooks';
import Modal from 'ada-image-editor/components/common/Modal';
import { RESET } from 'ada-image-editor/actions';
import { StyledHistoryButton } from './Topbar.styled';

const ResetButton = ({ margin }) => {
  const {
    dispatch,
    isResetted = true,
    theme,
    feedback,
    t,
    config,
  } = useStore();

  const isBlockerError = feedback.duration === 0;

  const [isModalOpened, setIsModalOpened] = useState(false);

  const cancelModal = () => {
    setIsModalOpened(false);
  };

  const openModal = () => {
    setIsModalOpened(true);
  };

  const dispatchReset = useCallback(() => {
    dispatch({ type: RESET, payload: { config } });
    cancelModal();
  }, [config]);

  const WarningIcon = () => (
    <FontAwesomeIcon
      size="2x"
      icon={faTriangleExclamation}
      color={theme.palette.warning}
    />
  );

  return (
    <>
      <StyledHistoryButton
        className="FIE_topbar-reset-button"
        color="link"
        onClick={isResetted ? undefined : openModal}
        disabled={isResetted || isBlockerError}
        title={t('resetOperations')}
        margin={margin}
      >
        <FontAwesomeIcon size="2x" icon={faArrowsRotate} />
      </StyledHistoryButton>
      {isModalOpened && (
        <Modal
          title={t('changesLoseConfirmation')}
          hint={t('changesLoseConfirmationHint')}
          isOpened={isModalOpened}
          onCancel={cancelModal}
          onDone={dispatchReset}
          Icon={WarningIcon}
          doneLabel={t('continue')}
          cancelLabel={t('cancel')}
          doneButtonColor="error"
          doneButtonStyle={{ background: theme.ada.palette.pink }}
        />
      )}
    </>
  );
};

ResetButton.defaultProps = {
  margin: undefined,
};

ResetButton.propTypes = {
  margin: PropTypes.string,
};

export default ResetButton;
