import { UserDataResponse } from './custom.types';

export const mockUserDataResponse: { data: UserDataResponse } = {
  data: {
    credentials: {
      idPersonalData: '1081',
      client_id: 'fB5B34177bcc307E029B60B0058F6F6f',
      client_secret: '986650bd1665B34D7550f6fFF4562D22',
    },
    customFields: [
      {
        text: 'Nome ',
        value: '{{name}}',
      },
      {
        text: 'Cognome',
        value: '{{surname}}',
      },
      {
        text: 'Data di nascita',
        value: '{{birthDate}}',
      },
      {
        text: 'Sesso',
        value: '{{sex}}',
      },
      {
        text: 'Città',
        value: '{{city}}',
      },
      {
        text: 'Cap',
        value: '{{cap}}',
      },
      {
        text: 'Indirizzo',
        value: '{{address}}',
      },
      {
        text: 'E-mail',
        value: '{{email}}',
      },
      {
        text: 'Custom field 1',
        value: '{{custom1}}',
      },
      {
        text: 'Custom field 2',
        value: '{{custom2}}',
      },
      {
        text: 'Custom field 3',
        value: '{{custom3}}',
      },
      {
        text: 'Custom field 4',
        value: '{{custom4}}',
      },
    ],
    availableProducts: [
      {
        id: '0',
        label: 'Landing Promotion',
      },
      {
        id: '3',
        label: 'Landing Lead',
      },
      {
        id: '6',
        label: 'Landing Social Coupon',
      },
      {
        id: '7',
        label: 'Landing Survey',
      },
      {
        id: '9',
        label: 'Landing Booking',
      },
      {
        id: '8',
        label: 'Landing Deal',
      },
      {
        id: '4',
        label: 'Landing mFlyer',
      },
      {
        id: '2',
        label: 'Landing Donation',
      },
      {
        id: 'chatBot',
        label: 'ChatBot',
      },
      {
        id: 'movieTelling',
        label: 'MovieTelling',
      },
      {
        id: 'landingPageAmp',
        label: 'LandingPageAmp',
      },
    ],
  },
};

export default mockUserDataResponse;
