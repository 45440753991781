import styled from 'styled-components';

import { SelectListProps } from '../../../custom.types';

export const SelectFolderAreaWrapper = styled.div`
  width: 25%;
`;

export const SelectFolderArea = styled.div`
  display: flex;
  align-items: center;
  width: 70%;
  position: relative;
`;

export const SelectWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
`;

export const SelectFolder = styled.div(
  ({ theme: { ada: theme } }) => `
    cursor: pointer;
    color: ${theme.palette['medium-green']};
    font-weight: 700;
    font-size: 12px;
  `
);

export const CaretWrapper = styled.div<SelectListProps>`
  cursor: pointer;

  & > svg {
    color: ${({ theme: { ada: theme } }) => theme.palette['medium-green']};
    transform: ${(props) =>
      props.visible ? 'rotate(180deg)' : 'rotate(0deg)'};
  }
`;

export const SelectList = styled.ul<SelectListProps>`
  display: ${(props) => (props.visible ? 'block' : 'none')};
  position: absolute;
  height: 200px;
  z-index: 20;
  overflow: auto scroll;
  background-color: white;
  top: 20px;
  left: 20%;
  list-style: none;
  padding: 0 0;
`;

export const SelectListItem = styled.button`
  text-decoration: none;
  white-space: nowrap;
  color: ${({ theme: { ada: theme } }) => theme.palette['medium-green']};
  width: 100%;
  padding: 0 15px 0 5px;
  cursor: pointer;
  font-size: 12px;
  border: 0;
  outline: none;
  background-color: transparent;
  text-align: left;

  &:hover {
    color: white;
    background-color: ${({ theme: { ada: theme } }) =>
      theme.palette['medium-green']};
  }
`;

export const Select = styled.select`
  width: 100%;
  padding: 7px;
  color: ${({ theme: { ada: theme } }) => theme.palette['dark-grey']};
  border: 1px solid ${({ theme: { ada: theme } }) => theme.palette['dark-green']};
  border-radius: 5px;
  min-height: 38px;
  font-size: 14px;
  line-height: 20px;
  background-color: #fff;
`;
