import styled from 'styled-components';
import { FolderPillProps } from '../../custom.types';

export const FolderPillWrapper = styled.div<FolderPillProps>(
  ({ theme: { ada: theme } }) => `
    padding: 3px 10px;
    font-size: 10px;
    border-radius: 50px;
    margin: 2px 5px 0 0;
    color: white;
    background-color: ${theme.palette['medium-green']};
    border: 1px solid ${theme.palette['medium-green']};
    display: flex;
    justify-content: space-around;
    align-items: center;
  `
);

export const FolderPillInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

export const FontAwesomeIconWrapper = styled.button<{
  onClick: (id: string) => void;
}>`
  background-color: unset;
  border: none;
  color: white;
  padding: 0 0 0 3px;
`;
