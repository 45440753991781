import { FolderSelectionSection } from './AddInFolder.style';
import { useTranslation } from 'react-i18next';

import {
  GalleryFolder,
  GalleryFolderAction,
  GalleryFolderHandler,
} from '../../custom.types';

import { Folders } from '../UI/Select/Folders';
import { FolderPill } from '../FoldersPillsSelection/FolderPill';

export const AddInFolder = ({
  label,
  handler,
  selectedFolders,
  disabled
}: {
  label: string;
  handler: (props: GalleryFolderHandler) => void;
  selectedFolders: GalleryFolder[];
  disabled: boolean
}) => {
  const { t } = useTranslation();

  const addFolderClick = (folder: GalleryFolder) => {
    const index = selectedFolders.findIndex((f) => f.id === folder.id);

    if (folder && index === -1) {
      handler({ fn: GalleryFolderAction.ADD, folder });
    }
  };

  const removeFolderClick = (folder: GalleryFolder) => {
    const index = selectedFolders.findIndex((f) => f.id === folder.id);
    if (index !== -1) {
      handler({ fn: GalleryFolderAction.DELETE, folder });
    }
  };

  return (
    <>
      <Folders handler={addFolderClick} label={label} placeholder={`${t('Select')}`} disabled={disabled} />
      <FolderSelectionSection>
        {selectedFolders.map((f) => (
          <FolderPill
            id={f.id}
            key={f.id}
            name={f.name}
            onClick={() => removeFolderClick(f)}
          />
        ))}
      </FolderSelectionSection>
    </>
  );
};
