import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GalleryFolder,
  GalleryFolderAction,
  GalleryFolderPayload,
  GalleryItemsTypes,
  GalleryItemType,
  GalleryResponse,
  GalleryState,
  GalleryStateEditor,
  GalleryViewState,
  OrderParams,
  ResponseMsgPayload,
} from '../../custom.types';
import {
  dashboard,
  deleteItems,
  editItem,
  getFoldersList,
  getGalleryList,
  manageFolder,
  saveFiles,
  saveTempMedia,
} from '../actions/gallery.action';

const initialState = {
  items: [],
  itemsType: null,
  currentPage: 0,
  loadedPages: 0,
  totalPages: -1,
  pageSize: 12,
  totalItems: 0,
  currentItem: null,
  view: null,
  isExternal: false,
  editorState: GalleryStateEditor.EMPTY,
  folders: [],
  memory: { total: 0, used: { original: 0, all: 0 } },
  sources: [],
  alerts: null,
  searchValue: '',
  order: { key: 'updateDate', direction: -1 },
  xAdaDestination: '',
} as GalleryState;

const gallerySlice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setView(
      state,
      action: PayloadAction<{ view: GalleryViewState; isExternal?: boolean }>
    ) {
      const { view, isExternal } = action.payload;
      state.isExternal = !!isExternal;
      if (GalleryViewState[view] && state.view !== view) {
        if (view === GalleryViewState.EDITOR) {
          state.editorState = GalleryStateEditor.EDIT;
          if (isExternal) state.view = view;
        } else {
          state.view = view;
        }
      }
    },
    closeEditor(state, action: PayloadAction<boolean | undefined>) {
      state.editorState = GalleryStateEditor.EMPTY;
      if (action.payload) {
        state.items = state.items.map((item: GalleryItemType) => {
          item.selected = false;
          return item;
        });
      }
    },
    closeGallery(state, action: PayloadAction<boolean | undefined>) {
      state.view = null;
      state.itemsType = null;
      resetPages(state, false);
    },
    setEditorState(state, action: PayloadAction<GalleryStateEditor>) {
      // state.editorState = GalleryStateEditor.PREVIEW;
      state.editorState = action.payload;
    },
    setItemsTypeState(state, action: PayloadAction<GalleryItemsTypes>) {
      state.itemsType = action.payload;
      state.searchValue = '';
      resetPages(state);
    },
    incrPage(state) {
      const currentPage = state.currentPage + 1;
      if (state.totalPages < 0 || currentPage <= state.totalPages) {
        state.currentPage = currentPage;
      }
    },
    setCurrent(state, action: PayloadAction<GalleryItemType | null>) {
      state.currentItem = action.payload;
    },
    setCurrentId(state, action: PayloadAction<string>) {
      if (state.currentItem) {
        state.currentItem.id = action.payload;
      }
    },
    renameItem(state, action: PayloadAction<{ id: string; name: string }>) {
      const f = state.items.find(
        (item) => item.id === action.payload.id
      ) as GalleryItemType;
      if (f) {
        f.name = action.payload.name;
        state.currentItem = f;
      }
    },
    moveItems(
      state,
      action: PayloadAction<{ ids: string[]; folderIds: string[] }>
    ) {
      for (let i = 0; i < action.payload.ids.length; i++) {
        const id = action.payload.ids[i];
        const f = state.items.find((item) => item.id === id) as GalleryItemType;
        if (f) {
          f.folderIds = action.payload.folderIds;
        }
      }
    },
    selDeselItem(
      state,
      action: PayloadAction<{ item: GalleryItemType; isChecked: boolean }>
    ) {
      const f = state.items.find((item) => item.id === action.payload.item.id);
      if (f) {
        f.selected = action.payload.isChecked;
        if (state.items.filter((it) => it.selected === true).length === 1) {
          state.currentItem = f;
        }
      }
    },
    selDeselItems(state, action: PayloadAction<boolean>) {
      state.items.map((item: GalleryItemType) => {
        item.selected = action.payload;
        return item;
      });
    },
    selFolder(state, action: PayloadAction<string>) {
      resetPages(state);
      state.folders = state.folders.map((f: GalleryFolder) => {
        return { ...f, selected: f.id === action.payload };
      });
    },
    setSearch(state, action: PayloadAction<string>) {
      resetPages(state);
      state.searchValue = action.payload;
    },
    setOrder(state, action: PayloadAction<OrderParams>) {
      resetPages(state);
      state.order = action.payload;
    },
    removeAlerts(state) {
      state.alerts = null;
    },
    /* deleteItems(state) {
      state.items = state.items.filter((it) => it.selected === false);
      /* state.items = [];
      state.currentPage = 1;
      state.loadedPages = 0; * /
    }, */
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFoldersList.fulfilled, (state, action) => {
        let name = 'Aggiunti di recente';
        let source = GalleryItemsTypes.MY_ITEMS;
        if (state.itemsType === GalleryItemsTypes.FREE_ITEMS) {
          name = 'Seleziona...';
          source = GalleryItemsTypes.FREE_ITEMS;
        }
        const folders = action.payload.folders.map((f: GalleryFolder) => {
          return { ...f, selected: false };
        });
        state.currentPage = 1;
        state.loadedPages = 0;
        state.folders = [
          {
            id: '0',
            name,
            selected: true,
            source,
          },
          ...folders,
        ];
      })
      .addCase(getGalleryList.fulfilled, (state, action) => {
        state.items = [
          ...state.items,
          ...action.payload.items.map((item: GalleryItemType) => {
            item.selected = false;
            return item;
          }),
        ];
        // state.totalPages = action.payload.metadata.totalPages;
        state.totalItems = action.payload.metadata.totalItems;
        state.totalPages = Math.ceil(
          action.payload.metadata.totalItems / state.pageSize
        );
        state.loadedPages = state.loadedPages + 1;
      })
      .addCase(editItem.fulfilled, (state, action) => {
        const item = state.items.find((it) => it.selected === true);
        if (item) {
          const items = state.items.filter((it) => it.selected === false);
          item.selected = false;
          if (action.payload?.name) {
            item.name = action.payload.name;
          }
          if (action.payload?.folderIds) {
            item.folderIds = action.payload.folderIds;
          }
          state.items = [...items, item];
        }
        if (action.payload?.responseMessage) {
          state.alerts = action.payload.responseMessage;
        }
        state.currentItem = null;
      })
      .addCase(dashboard.fulfilled, (state, action) => {
        // state.memory = { total: 0, used: { original: 0, all: 0 } };
        resetPages(state);
        state.memory = { ...action.payload.memory };
        state.sources = [...action.payload.sources];
        state.itemsType = null;
        if (action.payload.sources && action.payload.sources.length > 0) {
          state.itemsType = action.payload.sources[0].value;
        }
      })
      .addCase(
        saveFiles.fulfilled,
        (state, action: PayloadAction<GalleryResponse>) => {
          if (state.currentItem) {
            state.currentItem.id = action.payload.id;
            state.currentItem.src = action.payload.src;
            state.currentItem.scaledImages = action.payload.scaledImages;
            state.currentItem.resolution = action.payload.resolution;
          }
          if (action.payload.metadata) {
            state.alerts = action.payload.metadata.responseMessage;
          }
          state.xAdaDestination = '';
        }
      )
      .addCase(
        manageFolder.fulfilled,
        (state, action: PayloadAction<GalleryFolderPayload>) => {
          const { fn, folder, responseMessage } = action.payload;
          if (responseMessage) {
            state.alerts = { ...responseMessage };
          }
          if (folder) {
            switch (fn) {
              case GalleryFolderAction.DELETE:
                state.folders = state.folders.filter((f) => f.id !== folder.id);
                break;
              case GalleryFolderAction.EDIT:
                state.folders = state.folders.map((f) => {
                  return f.id === folder.id ? folder : f;
                });
                break;
              case GalleryFolderAction.ADD:
                state.folders = [...state.folders, folder];
                break;
            }
          }
        }
      )
      .addCase(
        deleteItems.fulfilled,
        (state, action: PayloadAction<ResponseMsgPayload>) => {
          const { responseMessage } = action.payload;
          if (responseMessage) {
            state.alerts = { ...responseMessage };
          }
        }
      )
      .addCase(saveTempMedia.fulfilled, (state, action) => {
        if (state.xAdaDestination === '') {
          state.xAdaDestination = action.payload.xAdaDestination;
        }
      });
  },
});

const resetPages = (state: GalleryState, resetCurrent = true) => {
  state.items = [];
  state.currentPage = 0;
  state.loadedPages = 0;
  state.totalPages = -1;
  if (resetCurrent) {
    state.currentItem = null;
  }
};

export const {
  incrPage,
  setCurrent,
  setCurrentId,
  selDeselItem,
  selDeselItems,
  setView,
  closeEditor,
  closeGallery,
  setEditorState,
  setItemsTypeState,
  renameItem,
  selFolder,
  moveItems,
  setSearch,
  setOrder,
  removeAlerts,
} = gallerySlice.actions;
export default gallerySlice.reducer;
