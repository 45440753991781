export default {
  "cropTool": "Cultivo",
  "nameIsRequired": "El nombre es obligatorio.",
  "adjustTab": "Ajustar",
  "socialMedia": "Medios de comunicación social",
  "padding": "Acolchado",
  "addBackgroundAsText": "Añadir como texto",
  "toBeUploaded": "para ser cargado",
  "imageTypeWillChangeOnSave": "Al guardar, elegirá un nuevo tipo de imagen/extensión para guardar",
  "IconTab": "Icono",
  "reset": "Reiniciar",
  "addIconTitle": "Elija el tipo de icono",
  "classicTv": "Televisión clásica",
  "importing": "Importar...",
  "brightnessTool": "Luminosidad",
  "storyPhoto": "Foto de la Story",
  "resizeTab": "Cambiar el tamaño",
  "twInStreamPhotoSize": "1600x1900px",
  "portrait": "Retrato",
  "toggleZoomMenuTitle": "Alternar el menú de zoom",
  "saveAs": "Guardar como",
  "uploadWatermark": "Cargar Watermark",
  "closeEditor": "Cerrar el editor",
  "resetZoomTitle": "Porcentaje de zoom (Haga clic para restablecer)",
  "headerPhoto": "Foto de encabezado",
  "letterSpacing": "Espacio entre letras",
  "feedSquarePhoto": "Foto en formato cuadrado",
  "rectangleTool": "Rectángulo",
  "textTool": "Texto",
  "arrowTool": "Flecha",
  "rotateTool": "Rotar",
  "twHeaderPhotoSize": "1500x500px",
  "igStoryPhotoSize": "1080x1920px",
  "position": "Posición",
  "contrastTool": "Contraste",
  "unFlipX": "Re-Girar X",
  "igProfilePhoto": "320x320px",
  "horizontal": "Horizontal",
  "quality": "Calidad",
  "ellipseTool": "Elipse",
  "fbStoryPhotoSize": "1080x1920px",
  "annotateTab": "Dibujar",
  "value": "Valor",
  "filtersTab": "Filtros",
  "zoomOutTitle": "Alejar el zoom",
  "hsvTool": "HSV",
  "igFeedSquarePhotoSize": "1080x1080px",
  "lineHeight": "Altura de la línea",
  "instagram": "Instagram",
  "addBackground": "+ Añadir fondo",
  "previewTool": "Vista previa",
  "liProfilePhotoSize": "400x400px",
  "finetuneTab": "Ajuste fino",
  "uploadIcon": "Carga icono",
  "liProfileCoverPhotoSize": "1584x396px",
  "inStreamPhoto": "Foto In-stream",
  "twProfilePhotoSize": "400x400px",
  "resizeWidthTitle": "Anchura en píxeles",
  "toggleRatioLockTitle": "Bloqueo de la proporción",
  "annotationVariable": "Fijar la variable de anotación",
  "uploadImageError": "Error al subir la imagen.",
  "custom": "Personalizado",
  "cropSizeLowerThanResizedWarning": "Tenga en cuenta que el área de recorte seleccionada es inferior a la del cambio de tamaño aplicado, lo que puede provocar una disminución de la calidad",
  "showImageTitle": "Mostrar la imagen original",
  "continue": "Continuar",
  "linkedIn": "LinkedIn",
  "actualSize": "Tamaño real (100%)",
  "back": "Volver",
  "resize": "Redimensionar",
  "flipY": "Girar Y",
  "addImage": "+ Añadir imagen",
  "fontFamily": "Familia de fuentes",
  "polygonTool": "Polígono",
  "blurRadius": "radio de desenfoque",
  "imageTypeNotSavingSupported": "El tipo/extensión de imagen NO se admite al guardar",
  "penTool": "Pluma",
  "saturation": "Saturación",
  "addBackgroundTitle": "Elija el tipo de fondo",
  "zoomInTitle": "Acercarse",
  "addWatermark": "+ Añadir marca de agua",
  "isNotImage": "no es imagen",
  "profileCoverPhoto": "Foto de portada",
  "feedPortraitPhoto": "Foto en formato retrato",
  "textAlignment": "Alineación del texto",
  "watermarkTab": "Marca de agua",
  "twitter": "Twitter",
  "loading": "Cargando...",
  "unFlipY": "Re-Girar Y",
  "landscape": "Paisaje",
  "original": "Original",
  "saveAsModalLabel": "Guardar la imagen como",
  "size": "Tamaño",
  "liCompanyLogoSize": "300x300px",
  "backgroundTab": "Fondo",
  "addWatermarkTitle": "Elija el tipo de marca de agua",
  "igProfilePhotoSize": "320x320px",
  "cornerRadius": "Radio de la esquina",
  "addIcon": "+ Añadir icono",
  "liBlogPostPhotoSize": "1200x627px",
  "cinemascope": "Cinemascope",
  "addWatermarkAsText": "Añadir como texto",
  "lineTool": "Línea",
  "warmthTool": "Calidez",
  "measureTool": "Medida",
  "save": "Guardar",
  "timelinePhoto": "Foto de Timeline",
  "eventCoverPhoto": "Foto de portada del evento",
  "facebook": "Facebook",
  "changesLoseConfirmation": "Todos los cambios se perderán",
  "fbEventCoverPhotoSize": "1200x628px",
  "companyPageCover": "Portada de la página de la empresa",
  "blogPostPhoto": "Foto de la entrada del blog",
  "undoTitle": "Deshacer la última operación",
  "resetOperations": "Reiniciar/borrar todas las operaciones",
  "stroke": "Accidente cerebrovascular",
  "name": "Nombre",
  "cancel": "Cancelar",
  "download": "Descargar",
  "addIconAsText": "Añadir como texto",
  "hue": "Hue",
  "textSpacings": "Espacios de texto",
  "fbProfilePhotoSize": "170x170px",
  "textTab": "Texto",
  "srTab": "SR",
  "blurTool": "Desenfoque",
  "uploadBackground": "Cargar fondo",
  "downloadAsModalLabel": "Guardar la imagen como",
  "circle": "Círculo",
  "imageDimensionsHoverTitle": "Tamaño de la imagen guardada (ancho x alto)",
  "addByUrl": "+ Añadir URL",
  "changesLoseConfirmationHint": "¿Estás seguro de que quieres continuar?",
  "redoTitle": "Rehacer la última operación",
  "feedLandscapePhoto": "Foto en formato paisaje",
  "invalidImageError": "La imagen proporcionada no es válida.",
  "shadow": "Sombra",
  "ellipse": "Elipse",
  "square": "Cuadrado",
  "blur": "Desenfoque",
  "sides": "Lados",
  "vertical": "Vertical",
  "igFeedLandscapePhotoSize": "1080x566px",
  "imageTool": "Imagen",
  "areNotImages": "no son imágenes",
  "resetSize": "Restablecer el tamaño original de la imagen",
  "extension": "Extensión",
  "opacity": "Opacidad",
  "cancelEditing": "Cancelar edición",
  "liCompanyPageCoverSize": "1128x191px",
  "transparency": "Opacidad",
  "profilePhoto": "Foto de perfil",
  "igFeedPortraitPhotoSize": "1080x1350px",
  "fitSize": "Talla de ajuste",
  "flipX": "Girar X",
  "fbProfileCoverPhotoSize": "851x315px",
  "fbTimelinePhotoSize": "1200x630px",
  "resizeHeightTitle": "Altura en píxeles",
  "companyLogo": "Logotipo de la empresa"
}
