import styled from 'styled-components';

export const AdaButton = styled.button`
  background-color: #9c495d;
  padding: 14px;
  color: white;
  border-radius: 30px;
  border: none;
  font-size: 10px;
`;

export const ButtonDisplay = styled.button`
  color: #7e7e7e;
  border: none;
  background-color: unset;
  cursor: pointer;
`;

export const ImageRow = styled.img`
  border-radius: 10px;
  width: 20%;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 80%;
  padding: 3rem 3rem;
`;
