import styled from 'styled-components';

export const DropzoneWrapper = styled.div`
  height: 60%;
  border: 2px dashed
    ${({ theme: { ada: theme } }) => theme.palette['medium-grey']};
  background-color: ${({ theme: { ada: theme } }) => theme.palette['light-w']};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 15px auto 0;
  cursor: pointer;
  width: 100%;
`;
