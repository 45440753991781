import { useEffect, useState, useRef } from "react";

import { UploadMediaRowWrapper, MediaPreview, MediaName } from "./UploadMediaRow.style"
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getBase64, getIcon } from "../../../app/helpers/common.helper";
import { GalleryResponse, UpFile, UploadStatus } from "../../../custom.types";
import { useAppDispatch } from "../../../app/hooks";
import { saveTempMedia } from "../../../app/actions/gallery.action";
import { UploadProgressBar } from "./UploadProgressBar";
import { DeleteListItem, ImagePreview } from '../Modals/Modal.style';

export const UploadMediaRow = (
  { droppedFile, inProgress, onRemove, onDone }
  : 
  { 
    droppedFile: UpFile, 
    inProgress: boolean, 
    onRemove: (id: number) => void,
    onDone: (id: number, folderName: string, params: object) => void 
  }) => {

  const [progress, setProgress] = useState<number>(0);
  
  const [src, setSrc] = useState<string>('');

  const fileSize = useRef<number>(0);
  const chunkCount = useRef<number>(0);
  const chunkStart = useRef<number>(0);
  const chunkEnd = useRef<number>(0);
  const counter = useRef<number>(-1);
  const folderName = useRef<string>('');
  
  const fileID = useRef<number>(Number(droppedFile.id));

  const imgRef = useRef<HTMLImageElement | null>(null);

  const dispatch = useAppDispatch();
  
  const chunkSize = 1048576; // 1MiB

  useEffect(() => {
    if(droppedFile.base64) {
      setSrc(droppedFile.base64 || '');
    } else {
      getBase64(droppedFile.file).then((b64) => setSrc(b64));
    }
  }, [droppedFile])

  useEffect(() => {
    fileSize.current = droppedFile.size;
    chunkCount.current = droppedFile.size % chunkSize === 0 
      ? droppedFile.size / chunkSize
      : Math.floor(droppedFile.size / chunkSize) + 1;
    
    chunkStart.current = 0;
    chunkEnd.current = chunkSize;
    counter.current = 0;
  }, []);


  useEffect(() => {
    if(progress > 1 && counter.current < chunkCount.current) {
      fileUpload();
    }
    if(progress === 100) {
      droppedFile.status = UploadStatus.DONE;

      const { naturalWidth: width, naturalHeight: height } = imgRef.current as HTMLImageElement;

      const resolution = {
        full: `${width}x${height}`, 
        width,
        height, 
        ar: Number((width / height).toFixed(3))
      };

      onDone(fileID.current, folderName.current, { resolution });
    }
  }, [progress]);

  useEffect(() => {
    if(inProgress) {
      fileUpload();
    }
  }, [inProgress])

  const fileUpload = () => {
    counter.current += 1;
    
    if(droppedFile && counter.current <= chunkCount.current) {
      const chunk = droppedFile.file.slice(chunkStart.current, chunkEnd.current);
      uploadChunk(chunk);
    }
  }

  const uploadChunk = (chunk: Blob) => {
    
    if(!droppedFile) return

    const input = {
      id: fileID.current,
      name: droppedFile.name,
      type: droppedFile.type,
      ext: droppedFile.ext,
      chunk,
      currentChunk: counter.current, 
      totalChunks: chunkCount.current,
      folderName: folderName.current
    };

    dispatch(saveTempMedia(input))
      .then((res: GalleryResponse) => {
        const { data, destination } = res.payload;
        const { options: { currentChunk, folderName: fn } } = data.responseMessage;
        folderName.current = fn;
        counter.current = currentChunk;
        chunkStart.current = chunkEnd.current;
        chunkEnd.current = chunkEnd.current + chunkSize;

        const currentProg = counter.current / chunkCount.current * 100;

        setProgress(currentProg);
      })
      .catch((err) => {
        console.log(err)
      });
    }

  return (
    <>
      <UploadMediaRowWrapper>
        <MediaPreview>
          { 
            src === '' 
              ? 
                <FontAwesomeIcon icon={ getIcon(droppedFile.type) } size="3x" /> 
              :
                <ImagePreview
                  src={ src }
                  height="auto"
                  width="75px"
                  ref={imgRef}
                />
          }
        </MediaPreview>
        <MediaName>
          <p>{ droppedFile.name }</p>
          { inProgress && <UploadProgressBar progress={ progress } /> }
        </MediaName>
        <DeleteListItem onClick={onRemove.bind(null, fileID.current)} visible={!inProgress}>
          <FontAwesomeIcon icon={faX} pull="right" />
        </DeleteListItem>
      </UploadMediaRowWrapper>
      <hr />
    </>
  )
}