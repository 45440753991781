import { concatMap, map, Observable, of } from 'rxjs';
import { ajax, AjaxConfig } from 'rxjs/ajax';
import {
    boGatewayGeneric, GalleryAjaxResponse,
    GalleryResponse,
    TokenCache
} from '../../custom.types';
import config from '../config/config';
import { encode, getBeDomain, getTdbOauthDomain, getUserDataUrl } from '../helpers/common.helper';

export default class BeDirectService {
  private static _instance: BeDirectService;
  private _tokens: TokenCache[] = [];

  public static getInstance(): BeDirectService {
    if (!BeDirectService._instance) {
      BeDirectService._instance = new BeDirectService();
    }

    return BeDirectService._instance;
  }

  request(
    credentials: GalleryResponse,
    data: boGatewayGeneric,
    xAdaDestination = ''
  ): Observable<GalleryResponse> {
    return this.getOauthToken(credentials).pipe(
      concatMap((token: GalleryResponse) => {
          token = token.data || token;
          const newToken = token as TokenCache;
          return this.saveTokenCache(newToken);
        }
      ),
      concatMap((token) =>
        this.getData(credentials.idPersonalData, data, token.accessToken, xAdaDestination)
      )
    );
  }

  saveTokenCache(token: TokenCache) {
    if(token?.user?.id) {
      const findToken = this._tokens.findIndex(t => String(t.user.id) === String(token.user.id));
      if(findToken === -1) {
        this._tokens.push(token);
      }
    }
    return of(token);
  }

  getTokenCache(idPersonalData: string): TokenCache | null {
    const findToken = this._tokens.find(t => String(t.user.id) === String(idPersonalData));
    if(findToken) {
      const expire = new Date(findToken.accessTokenExpiresAt).getTime();
      const now = Date.now();
      if((expire - 5000) > now) {
        return findToken;
      } else {
        this._tokens = this._tokens.filter(t => String(t.user.id) !== String(idPersonalData));
      }
    }
    return null;
  }

  getData(
    idPersonalData: string,
    data: boGatewayGeneric,
    token: string,
    xAdaDestination = ''
  ): Observable<GalleryResponse> {
    if(!data) {
      return of({})
    }
    const headers: Record<string, string> = {
      // 'content-type': 'application/json',
      // 'Content-Type': 'multipart/form-data',
      'X-Requested-With': 'XMLHttpRequest', 
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }

    let options = {};
    const { chunk, name, type, currentChunk, totalChunks, ext, folderName, url, fn } = data;

    if (fn === 'saveChunk') {
      if(xAdaDestination !== '') {
        headers['x-ada-destination'] = xAdaDestination;
      }

      const formdata = new FormData();
      formdata.append('file', chunk as Blob, name as string);
      formdata.append('name', name as string);
      formdata.append('type', type as string);
      formdata.append('currentChunk', String(currentChunk));
      formdata.append('totalChunks', String(totalChunks));
      formdata.append('ext', ext as string);
      formdata.append('idPersonalData', idPersonalData);
  
      if(folderName) {
        formdata.append('folderName', folderName as string);
      }
  
      options = {
        method: 'POST',
        headers,
        body: formdata,
        url: `${getBeDomain()}/save-chunk`,
      };
    
    } else if (fn === 'fetchMedia') {
      
      options = {
        method: 'GET',
        headers,
        url: `${getBeDomain()}/${idPersonalData}/fetch-media/${url}`,
        responseType: 'arraybuffer'
      }
    }
    return this.doRequest(options as AjaxConfig);
  }

  getUserData(idPersonalData: string): Observable<GalleryResponse> {
    if (!config.tdbOauth.enable) {
      return of({ credentials: null });
    }
    const body = `idPersonalData=${idPersonalData}`;
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      body,
      url: getUserDataUrl(),
    };
    return this.doRequest(options);
  }
  
  getOauthToken(credentials: GalleryResponse): Observable<GalleryResponse> {
    if (!config.tdbOauth.enable) {
      return of({ accessToken: '' });
    }
    /* const client_id = '74F027eEC425350C0a341D21c1acB609';
    const client_secret = '3E0f911Dde85fC36C39B9658423b1e27'; */
    const { client_id, client_secret, idPersonalData } = credentials;
    const findToken = this.getTokenCache(idPersonalData);
    if(findToken) {
      return of(findToken);
    }
    const body = `client_id=${client_id}&client_secret=${client_secret}&grant_type=client_credentials&scope=ada_gallery`;
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      body,
      url: `${getTdbOauthDomain()}/${idPersonalData}${config.tdbOauth.paths.token}`
    };
    return this.doRequest(options);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  doRequest(options: AjaxConfig): Observable<GalleryResponse> {
    return ajax<GalleryAjaxResponse>(options).pipe(
      map(async (response) => {
        if(response.xhr.responseType === 'arraybuffer') {
          const arr = new Uint8Array(response.xhr.response as ArrayBuffer);
          const res = await encode(arr)
          return { data: `data:image/jpeg;base64,${res}` }
        } else {
          const xAdaDestination =
            response.xhr.getAllResponseHeaders().indexOf('x-ada-destination') >= 0
              ? response.xhr.getResponseHeader('x-ada-destination')
              : '';
          return { data: response?.response?.data, xAdaDestination };
        }
      }),
      concatMap((response) => response)
    )
  }
}
