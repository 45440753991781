import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OverlayTypeWrapper } from './GalleryItem.style';
import theme from '../../theme';
import { GalleryItemType } from '../../custom.types';
import { getIcon } from '../../app/helpers/common.helper';

export default function OverlayType({ img }: { img: GalleryItemType }) {
  return (
    <OverlayTypeWrapper>
      <FontAwesomeIcon
        icon={getIcon(img.type)}
        size="2x"
        color={theme.ada.palette['light-w']}
      />
    </OverlayTypeWrapper>
  );
}
