import { useAppDispatch, useAppSelector } from '../../../app/hooks';
// import { deleteItems } from '../../../app/reducers/gallery.reducer';
import { dashboard, deleteItems } from '../../../app/actions/gallery.action';

import { ModalContent, ModalTitle } from './Modal.style';
import { TopBar, Footer } from './Modal.style';
import { ButtonOk, ButtonCancel } from '../UI.style';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { RootState } from '../../../app/store';
import { closeEditor, incrPage } from '../../../app/reducers/gallery.reducer';

export default function Delete() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const items = useAppSelector((state: RootState) => state.gallery.items);
  const selected = items.filter((it) => it.selected === true).length;
  const suffix = selected === 1 ? 'one' : 'other';
  const deleteItemsHandler = () => {
    dispatch(deleteItems())
    .then(() => {
      dispatch(closeEditor(true));
      dispatch(dashboard()).then(() => dispatch(incrPage()));
    })
  };
  return (
    <ModalContent width="600px" height="auto">
      <TopBar onClick={() => dispatch(closeEditor())}>
        <ModalTitle color={theme.ada.palette['medium-green']}>{`${t(
          'Elimina'
        )}`}</ModalTitle>
        <FontAwesomeIcon
          color={theme.ada.palette['medium-grey']}
          icon={faX}
        />
      </TopBar>
      <p style={{fontSize: '18px', textAlign: 'center'}}>{t('Conferma eliminazione', { count: selected, key: `TheFile_${suffix}` })}</p>
      <Footer>
        <ButtonOk onClick={deleteItemsHandler}>{t('Conferma')}</ButtonOk>
        <ButtonCancel onClick={() => dispatch(closeEditor())}>
          {t('Annulla')}
        </ButtonCancel>
      </Footer>
    </ModalContent>
  );
}
