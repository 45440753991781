export default {
  // GALLERY
  'Galleria immagini': 'Galleria immagini',
  'I miei file': 'I miei file',
  MY_ITEMS: 'I miei file',
  'Immagini gratuite': 'Immagini gratuite',
  FREE_ITEMS: 'Immagini gratuite',
  usato: 'usato',
  su: 'su',
  'Usa Immagine': 'Usa Immagine',
  'Aggiungi immagine': 'Aggiungi file',
  Anteprima: 'Anteprima', // previewTool
  Modifica: 'Modifica',
  Rinomina: 'Rinomina',
  Sposta: 'Sposta',
  Elimina: 'Elimina',
  Usa: 'Usa',
  Data: 'Data',
  Risoluzione: 'Risoluzione',
  Dimensione: 'Dimensione',
  Cartella_one: 'Cartella',
  Cartella_other: 'Cartelle',
  TheFolder_one: 'la cartella',
  TheFolder_other: 'le cartelle',
  Select: 'Seleziona',
  Seleziona: 'Seleziona $t({{key}})',
  'Seleziona...': 'Seleziona...',
  Gestisci: 'Gestisci $t({{key}})',
  Crea: 'Crea $t({{key}})',
  'Seleziona tutto': 'Seleziona tutto',
  Salva: 'Salva', // save
  Annulla: 'Annulla',
  Cerca: 'Cerca',
  Ok: 'Ok',
  Carica: 'Carica',
  'i file nella cartella': '{{key}} il file nella cartella',
  //'i file nella cartella_other': '{{key}} i file nella cartella',
  // 'Conferma eliminazione_one': 'Conferma eliminazione di {{count}} immagine?',
  // 'Conferma eliminazione_other': 'Conferma eliminazione di {{count}} immagini?',
  'Conferma eliminazione_one': 'Sei sicuro di voler eliminare $t({{key}})?',
  'Conferma eliminazione_other': 'Sei sicuro di voler eliminare $t({{key}})?',
  'Aggiunti di recente': 'Aggiunti di recente',
  'Carica la tua prima immagine': 'Carica la tua prima immagine',
  'Aggiunta il': 'Aggiunta il',
  Formato: 'Formato',
  'Rinomina immagine': 'Rinomina immagine',
  'Inserisci il nuovo nome':
    'Inserisci il nuovo nome',
  'Elimina immagine': 'Elimina immagine',
  'Sposta immagine': 'Sposta immagine',
  "Seleziona la cartella in cui spostare l'immagine":
    "Seleziona la cartella in cui spostare l'immagine",
  "Aggiungi o rimuovi cartella all'immagine":
    "Aggiungi o rimuovi cartella all'immagine",
  Aggiungi: 'Aggiungi',
  Conferma: 'Conferma',
  'Trascina un file qui': 'Trascina un file qui',
  o: 'o',
  'seleziona un file dal computer': 'seleziona un file dal computer',
  'Data di inserimento': 'Data di inserimento',
  selezionati: 'selezionati',
  'Inserisci un nome': 'Inserisci un nome',
  'Inserisci il nome della cartella': 'Inserisci il nome della cartella',
  'Scegli la cartella da rinominare o eliminare': 'Scegli la cartella da rinominare o eliminare',
  Immagine_one: 'Immagine',
  Immagine_other: 'Immagini',
  Creata_one: 'creata',
  Creata_other: 'create',
  Caricato_one: 'caricato',
  Caricato_other: 'caricati',
  Caricata_one: 'caricata',
  Caricata_other: 'caricate',
  Modificata_one: 'modificata',
  Modificata_other: 'modificate',
  Modificato_one: 'modificato',
  Modificato_other: 'modificati',
  delete_one: 'eliminata',
  delete_other: 'eliminate',
  Eliminato_one: 'eliminato',
  Eliminato_other: 'eliminati',
  Eliminazione: 'l\'eliminazione',
  Spostamento: 'lo spostamento',
  Of_Image_one: 'dell\'immagine',
  Of_Image_other: 'delle immagini',
  Of_Folder_one: 'della cartella',
  Of_Folder_other: 'delle cartelle',
  Success: '$t({{type}}) $t({{action}}) con successo',
  Error: 'Errore durante $t({{action}}) $t({{type}})',
  // Existing_folder: 'Folder with name {{name}} already exists.',
  Existing_media: 'Esiste già una $t({{type}}) con nome {{name}}',
  'Media not found': '$t({{type}}) non trovata',
  upload_SUCCESS_one: 'Immagine caricata con successo',
  upload_SUCCESS_other: 'Immagini caricate con successo',
  upload_DANGER_one: 'Errore durante il caricamento dell\'immagine',
  upload_DANGER_other: 'Errore durante il caricamento delle immagini',
  upload_WARNING_one: 'Attenzione: lo spazio disponibile non è sufficiente al caricamento dell\'immagine.',
  upload_WARNING_other: 'Attenzione: lo spazio disponibile non è sufficiente al caricamento delle immagini.',
  upload_TOOMANYFILES: 'Attenzione: puoi caricare al massimo {{max}} immagini.',
  upload_FILETOOLARGE: 'Attenzione: non puoi caricare file più grandi di {{max}}',
  dropzone_maxUploadSize: 'Dimensione massima per immagini e gif: {{max}}',
  delete_ERROR_one: 'Si è verificato un errore durante l\'eliminazione de files.',
  invalid_name: 'Il nome inserito non è valido',
  PlaceInFolder: 'Seleziona una o più cartelle in cui $t({{action}}) $t({{key}})',
  UploadFiles: 'Aggiungi immagini, gif o video',
  Caricare: 'caricare',
  Spostare: 'spostare',
  File: 'File',
  TheFile_one: 'il file',
  TheFile_other: 'i file',
  YourFile_other: 'i tuoi file',
  Go: 'Vai',
  // Editor
  cropTool: 'Coltura',
  nameIsRequired: 'Il nome è obbligatorio.',
  adjustTab: 'Ritaglio',
  socialMedia: 'I social media',
  padding: 'Imbottitura',
  addBackgroundAsText: 'Aggiungi come testo',
  toBeUploaded: 'da caricare',
  imageTypeWillChangeOnSave:
    'Al momento del salvataggio si sceglierà un nuovo tipo di immagine/estensione con cui salvarla',
  reset: 'Reset',
  classicTv: 'TV classica',
  importing: 'Importazione...',
  brightnessTool: 'Luminosità',
  storyPhoto: 'Foto della storia',
  resizeTab: 'Ridimensiona',
  twInStreamPhotoSize: '1600x1900px',
  portrait: 'Ritratto',
  toggleZoomMenuTitle: 'Alterna il menu dello zoom',
  saveAs: 'Salva con nome',
  uploadWatermark: 'Caricare la filigrana',
  closeEditor: "Chiudere l'editor",
  resetZoomTitle: 'Percentuale di zoom (fare clic per azzerare)',
  headerPhoto: "Foto dell'intestazione",
  letterSpacing: 'Spaziatura tra le lettere',
  feedSquarePhoto: 'Alimentazione foto quadrata',
  rectangleTool: 'Rettangolo',
  textTool: 'Testo',
  arrowTool: 'Freccia',
  rotateTool: 'Ruotare',
  twHeaderPhotoSize: '1500x500px',
  igStoryPhotoSize: '1080x1920px',
  position: 'Posizione',
  contrastTool: 'Contrasto',
  unFlipX: 'Disinclinazione X',
  igProfilePhoto: '320x320px',
  horizontal: 'Orizzontale',
  quality: 'Qualità',
  ellipseTool: 'Ellisse',
  fbStoryPhotoSize: '1080x1920px',
  annotateTab: 'Disegna',
  value: 'Valore',
  filtersTab: 'Filtri',
  zoomOutTitle: 'Rimpicciolisci',
  hsvTool: 'HSV',
  igFeedSquarePhotoSize: '1080x1080px',
  lineHeight: 'Altezza della linea',
  instagram: 'Instagram',
  addBackground: '+ Aggiungi sfondo',
  previewTool: 'Anteprima',
  liProfilePhotoSize: '400x400px',
  finetuneTab: 'Regolazioni',
  liProfileCoverPhotoSize: '1584x396px',
  inStreamPhoto: 'Foto in corrente',
  twProfilePhotoSize: '400x400px',
  resizeWidthTitle: 'Larghezza in pixel',
  toggleRatioLockTitle: 'Blocco del rapporto di trasmissione',
  annotationVariable: 'Impostare la variabile di annotazione',
  uploadImageError: "Errore durante il caricamento dell'immagine.",
  custom: 'Personalizzato',
  cropSizeLowerThanResizedWarning:
    "Nota: l'area di ritaglio selezionata è inferiore al ridimensionamento applicato, il che potrebbe causare una riduzione della qualità.",
  showImageTitle: "Mostra l'immagine originale",
  continue: 'Continua',
  linkedIn: 'LinkedIn',
  actualSize: 'Dimensioni reali (100%)',
  back: 'Indietro',
  resize: 'Ridimensiona',
  flipY: 'Flip Y',
  addImage: '+ Aggiungi immagine',
  fontFamily: 'Famiglia di caratteri',
  polygonTool: 'Poligono',
  blurRadius: 'sfocaturaRaggio',
  imageTypeNotSavingSupported:
    'Il tipo di immagine/estensione NON è supportato nel salvataggio.',
  penTool: 'Penna',
  saturation: 'Saturazione',
  addBackgroundTitle: 'Scegliere il tipo di sfondo',
  zoomInTitle: 'Ingrandimento',
  addWatermark: '+ Aggiungi filigrana',
  isNotImage: "non è un'immagine",
  profileCoverPhoto: 'Foto di copertina del profilo',
  feedPortraitPhoto: 'Foto ritratto di alimentazione',
  textAlignment: 'Allineamento del testo',
  watermarkTab: 'Watermark',
  twitter: 'Twitter',
  loading: 'Caricamento...',
  unFlipY: 'Disimpegnare la Y',
  landscape: 'Paesaggio',
  original: 'Originale',
  saveAsModalLabel: "Salvare l'immagine come",
  size: 'Dimensione',
  liCompanyLogoSize: '300x300px',
  backgroundTab: 'Sfondo',
  addWatermarkTitle: 'Scegliere il tipo di filigrana',
  igProfilePhotoSize: '320x320px',
  cornerRadius: "Raggio d'angolo",
  liBlogPostPhotoSize: '1200x627px',
  cinemascope: 'Cinemascope',
  addWatermarkAsText: 'Aggiungi come testo',
  lineTool: 'Linea',
  warmthTool: 'Calore',
  save: 'Salva',
  timelinePhoto: 'Foto della timeline',
  eventCoverPhoto: "Foto di copertina dell'evento",
  facebook: 'Facebook',
  changesLoseConfirmation: 'Tutte le modifiche andranno perse',
  fbEventCoverPhotoSize: '1200x628px',
  companyPageCover: 'Copertina della pagina aziendale',
  blogPostPhoto: 'Foto del post del blog',
  undoTitle: "Annullamento dell'ultima operazione",
  resetOperations: 'Azzeramento/cancellazione di tutte le operazioni',
  stroke: 'Ictus',
  name: 'Nome',
  // cancel: 'Annullamento',
  cancel: 'Annulla',
  download: 'Scaricare',
  hue: 'Tonalità',
  textSpacings: 'Spaziature del testo',
  fbProfilePhotoSize: '170x170px',
  srTab: 'SR',
  blurTool: 'Sfocatura',
  uploadBackground: 'Caricare lo sfondo',
  downloadAsModalLabel: "Salvare l'immagine come",
  circle: 'Cerchio',
  imageDimensionsHoverTitle:
    "Dimensioni dell'immagine salvata (larghezza x altezza)",
  addByUrl: '+ Aggiungi URL',
  changesLoseConfirmationHint: 'Sei sicuro di voler continuare?',
  redoTitle: "Ripetere l'ultima operazione",
  feedLandscapePhoto: 'Alimentare la foto del paesaggio',
  invalidImageError: 'Immagine non valida fornita.',
  shadow: 'Ombra',
  ellipse: 'Ellisse',
  square: 'Quadrato',
  blur: 'Sfocatura',
  sides: 'Lati',
  vertical: 'Verticale',
  igFeedLandscapePhotoSize: '1080x566px',
  imageTool: 'Immagine',
  areNotImages: 'non sono immagini',
  resetSize: "Ripristino delle dimensioni originali dell'immagine",
  extension: 'Estensione',
  opacity: 'Opacità',
  cancelEditing: 'Annullamento della modifica',
  liCompanyPageCoverSize: '1128x191px',
  transparency: 'Opacità',
  profilePhoto: 'Foto del profilo',
  igFeedPortraitPhotoSize: '1080x1350px',
  fitSize: 'Misura',
  flipX: 'Flip X',
  fbProfileCoverPhotoSize: '851x315px',
  fbTimelinePhotoSize: '1200x630px',
  resizeHeightTitle: 'Altezza in pixel',
  companyLogo: 'Logo aziendale',
  "Inserisci l’URL del file": "Inserisci l’URL del file e clicca su Ok per precaricare l'immagine",
  "Rinomina o Elimina la cartella": "Rinomina o Elimina la cartella",
  invalid_url: 'L\'url inserito non è valido'
};
