import { faImages } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropzoneWrapper } from './Dropzone.style';
import { useTranslation } from 'react-i18next';
import { DropzoneRootProps, DropzoneInputProps } from 'react-dropzone';
// import { FileProps } from '../../../custom.types';

import theme from '../../../theme';
import config from '../../../app/config/config';
import { byteConverter } from '../../../app/helpers/common.helper';

interface DropzoneProps {
  rootProps: () => DropzoneRootProps;
  inputProps: () => DropzoneInputProps;
}

export const ImageDropzone = (props: DropzoneProps) => {
  const { t } = useTranslation();

  return (
    <>
      <DropzoneWrapper>
        <section style={{ width: '100%', height: '100%' }}>
          <div
            {...props.rootProps()}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              height: '100%',
              padding: '15px 0'
            }}
          >
            <input {...props.inputProps()} />
            <FontAwesomeIcon
              pull="left"
              icon={faImages}
              size="4x"
              color={theme.ada.palette['medium-grey']}
            />
            <div style={{ color: theme.ada.palette['medium-grey'] }}>
              <h5>{`${t('Trascina un file qui')} `}</h5>
              <h5>
                {`${t('o')} `}
                <span style={{ color: theme.ada.palette['medium-green'] }}>
                  {`${t('seleziona un file dal computer')}`}
                </span>
              </h5>
            </div>
          </div>
        </section>
      </DropzoneWrapper>
      <div style={{ paddingTop: '10px' }}>
        <p>{`${t('dropzone_maxUploadSize', { max: byteConverter(config.dropzone.maxUploadSize) })}`}</p>
        <p
          style={{
            color: theme.ada.palette['medium-green'],
          }}
        >{`${t('Guarda i formati dei file che puoi usare')}`}</p>
      </div>
    </>
  );
};
