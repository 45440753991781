import styled from 'styled-components';

export const UploadMediaRowWrapper = styled.div`
  width: 100%;
  display: flex;
  height: 60px;
  align-items: center;
  margin-bottom: 20px;
`;

export const MediaPreview = styled.div`
  width: 15%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const MediaName = styled.div`
  width: 80%;
  padding-left: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
`;

export const CloseWrapper = styled.div`
  width: 5%;
  display: flex;
  justify-content: flex-end;
`;