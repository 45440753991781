import styled, { css } from 'styled-components';

export const DisplaySettingsToolbar = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const CheckboxArea = styled.div`
  width: 30%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const InputArea = styled.div`
  width: 30%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
`;

export const ButtonViewArea = styled.div`
  display: flex;
  position: relative;
  font-size: 22px;
`;

export const SmallText = styled.p`
  margin: 0 10px 0 0;
  padding: 0;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
  color: ${({ theme: { ada: theme } }) => theme.palette['medium-grey']};
`;

export const Button = styled.button(
  ({ theme: { ada: theme } }) => css`
    cursor: pointer;
    color: ${theme.palette['medium-grey']};
    border: none;
    background-color: unset;
  `
);

export const FilterDropdownWrapper = styled.div<{ visible: boolean }>(
  ({ visible, theme: { ada: theme } }) => css`
    position: absolute;
    top: 100%;
    right: 70%;
    z-index: 10;
    background-color: white;
    box-shadow: 5px 10px 15px 0px ${theme.palette['medium-grey']};
    -webkit-box-shadow: 5px 10px 15px 0px ${theme.palette['medium-grey']};
    display: ${ visible ? 'block' : 'none' };
  `
);

export const DropdownList = styled.div`
  padding: 10px;
  list-style: none;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const DropdownListItem = styled.button<{selected: boolean}>(
  ({ selected, theme: { ada: theme } }) => css`
    background-color: unset;
    border: none;
    width: 100%;
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;

    &:hover {
      background-color: ${theme.palette['medium-green']};
      color: white;
    }

    font-weight: ${ selected ? `bold` : 'normal' };
  `
);

export const IconContainer = styled.div`
  width: 10px;
  padding-left: 20px;
`;

export const SelectedItems = styled.span`
  margin: 0 0 0 10px;
`;
