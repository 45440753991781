import { useTranslation } from 'react-i18next';

import { InputSearch, IconWrapper } from './SearchInput.style';
import { faMagnifyingGlass, faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, FocusEvent, ChangeEvent, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { incrPage, setSearch } from '../../../app/reducers/gallery.reducer'
import { RootState } from '../../../app/store';

export default function SearchInput() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  enum Expansion {
    FULL = 'full',
    HALF = 'half',
    NO = 'no',
  }

  const globalSearchVal = useAppSelector(
    (state: RootState) => state.gallery.searchValue
  );

  const [expanded, setExpanded] = useState<string>('no');
  const [searchValue, setSearchValue] = useState<string>('');
  // let timerID: number | undefined;
  const timerID = useRef<number | undefined>(undefined);

  const iconRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnBlur = (evt: FocusEvent<HTMLInputElement>) => {
    if (evt.target.value === '') {
      setExpanded(Expansion.NO);
    } else {
      setExpanded(Expansion.HALF);
    }
  };

  useEffect(() => {
    if(globalSearchVal === '') {
      emptyInput();
    }
  }, [globalSearchVal])

  const search = (val: string) => {
    dispatch(setSearch(val));
    setTimeout(() => {
      dispatch(incrPage());
    }, 0);
  }

  const handleOnChange = (evt: ChangeEvent<HTMLInputElement>) => {
    if(expanded !== Expansion.FULL) {
      setExpanded(Expansion.FULL);
    }

    const value = evt.target.value;
    setSearchValue(value);
    
    if(value.length === 0) {
      setSearchValue('');
      search('');
      setExpanded(Expansion.NO);
      return;
    }

    if(value.length >= 3) {
      if(timerID.current) {
        clearTimeout(timerID.current);
      }
      timerID.current = window.setTimeout(search, 300, value);
    }
  };

  const emptyInput = () => {
    if (inputRef.current?.value) {
      setSearchValue('');
      search('');
      inputRef.current.value = '';
    }
    setExpanded(Expansion.NO);
  };

  const handleClick = () => {
    if (expanded === Expansion.NO) {
      setExpanded(Expansion.FULL);
      inputRef.current?.focus();
    }
  };

  return (
    <>
      <InputSearch
        type="text"
        placeholder={`${t('Cerca')}`}
        onBlur={(evt) => handleOnBlur(evt)}
        onChange={(evt) => handleOnChange(evt)}
        expanded={expanded}
        ref={inputRef}
      />
      {searchValue ? (
        <IconWrapper
          ref={iconRef}
          onClick={emptyInput}
          expanded={expanded}
          searchValue={searchValue}
        >
          <FontAwesomeIcon icon={faX} />
        </IconWrapper>
      ) : (
        <IconWrapper
          ref={iconRef}
          onClick={handleClick}
          expanded={expanded}
          searchValue={searchValue}
        >
          <FontAwesomeIcon icon={faMagnifyingGlass} />
        </IconWrapper>
      )}
    </>
  );
}
