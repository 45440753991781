// import * as Language from 'languages';
import { ModalContent, ModalTitle, TopBar } from '../UI/Modals/Modal.style';

import FilerobotImageEditor, { TABS, TOOLS } from 'ada-image-editor';
import {
  AlertType,
  GalleryItemType,
  ResolutionProps,
} from '../../custom.types';
import {
  closeEditor,
  closeGallery,
  incrPage,
  setCurrent,
} from '../../app/reducers/gallery.reducer';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import {
  getImageSize,
  encode,
  getMediaType,
} from '../../app/helpers/common.helper';
import { useEffect, useState } from 'react';
import { dashboard, saveFiles } from '../../app/actions/gallery.action';
import { RootState } from '../../app/store';

import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-light-svg-icons';
import theme from '../../theme';
import { ModalAlert } from '../UI/UI.style';

export default function Editor({
  currentItem,
}: {
  currentItem: GalleryItemType;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [history, setHistory] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [showEditor, setShowEditor] = useState<boolean>(true);
  const memory = useAppSelector((state: RootState) => state.gallery.memory);
  const editorState = useAppSelector(
    (state: RootState) => state.gallery.editorState
  );
  const isExternal = useAppSelector(
    (state: RootState) => state.gallery.isExternal
  );
  const [imageState, setImageState] = useState<HTMLImageElement | string>('');

  let key: AlertType = AlertType.WARNING;

  useEffect(() => {
    if (currentItem.src.indexOf('http') === -1) {
      const image = new Image();
      image.src = currentItem.src;
      image.alt = currentItem.name;
      setImageState(image);
    } else {
      setImageState(currentItem.src);
    }
  }, [currentItem]);

  const handleClose = () => {
    setImageState('');
    dispatch(closeEditor());
    if (isExternal) {
      dispatch(closeGallery());
    }
  };

  return (
    <ModalContent editorState={editorState}>
      <style>
        {`.FIE_tabs {
            overflow: hidden scroll!important;
            padding: 0!important;
            width: 15%!important;
            -ms-overflow-style: none;
            scrollbar-width: none;
          }

          .FIE_tabs::-webkit-scrollbar {
            display: none;
          }`}
      </style>
      <TopBar onClick={handleClose}>
        <ModalTitle color={theme.ada.palette['medium-green']}>{`${t(
          'Modifica immagine'
        )}`}</ModalTitle>
        {/* <FontAwesomeIcon
          color={theme.ada.palette['medium-grey']}
          icon={faX}
        /> */}
      </TopBar>
      {showEditor && imageState ? (
        <FilerobotImageEditor
          showSave={() => !!isEdit}
          /* source="https://i.imgur.com/PW8Oqde.jpeg" */
          /* source="https://scaleflex.airstore.io/demo/stephen-walker-unsplash.jpg" */
          source={imageState}
          onSave={(editedImageObject, designState) => {
            console.log('saved', editedImageObject, designState);
            const { width, height } = editedImageObject;
            if (width && height) {
              const newImage = JSON.parse(JSON.stringify(currentItem));

              const imageSize = getImageSize(
                editedImageObject.imageBase64 || '0'
              );

              if (memory.total - memory.used.original - imageSize <= 0) {
                setShowEditor(false);
                key = 'WARNING' as AlertType;
                return;
              }

              newImage.date = new Date().toISOString();
              delete newImage.id;
              delete newImage.size;
              delete newImage.selected;
              newImage.name = editedImageObject.fullName;
              newImage.src = editedImageObject.imageBase64;
              newImage.ext = editedImageObject.extension;
              newImage.mimeType = editedImageObject.mimeType;
              newImage.type = getMediaType(editedImageObject.mimeType);
              const ar = Number((width / height).toFixed(3));
              newImage.resolution = {
                full: `${width}x${height}`,
                width,
                height,
                ar,
              } as ResolutionProps;
              dispatch(saveFiles([newImage]))
                .then((res) => {
                  if (isExternal) {
                    dispatch(
                      setCurrent({
                        ...(res.payload as GalleryItemType),
                        name: newImage.name,
                      })
                    );
                    handleClose();
                    dispatch(closeGallery());
                  } else {
                    dispatch(closeEditor());
                    dispatch(dashboard()).then(() => dispatch(incrPage()));
                  }
                })
                .catch((err) => {
                  console.info('VINCI err ', err);
                });
            }
          }}
          onClose={handleClose}
          closeAfterSave={false}
          onModify={async (data) => {
            if (isEdit) return true;
            const encodeData = await encode(JSON.stringify(data));
            if (history === '') {
              setHistory(encodeData);
            } else if (history !== encodeData) {
              setIsEdit(true);
            }
          }}
          annotationsCommon={{
            fill: '#ff0000',
          }}
          Text={{ text: 'Filerobot...' }}
          Rotate={{ angle: 90, componentType: 'slider' }}
          Crop={{
            presetsItems: [
              {
                titleKey: 'classicTv',
                descriptionKey: '4:3',
                ratio: String(4 / 3),
                // icon: CropClassicTv, // optional, CropClassicTv is a React Function component. Possible (React Function component, string or HTML Element)
              },
              {
                titleKey: 'cinemascope',
                descriptionKey: '21:9',
                ratio: String(21 / 9),
                // icon: CropCinemaScope, // optional, CropCinemaScope is a React Function component.  Possible (React Function component, string or HTML Element)
              },
            ],
            presetsFolders: [
              {
                titleKey: 'socialMedia', // will be translated into Social Media as backend contains this translation key
                // icon: Social, // optional, Social is a React Function component. Possible (React Function component, string or HTML Element)
                groups: [
                  {
                    titleKey: 'facebook',
                    items: [
                      {
                        titleKey: 'profile',
                        width: 180,
                        height: 180,
                        descriptionKey: 'fbProfileSize',
                      },
                      {
                        titleKey: 'coverPhoto',
                        width: 820,
                        height: 312,
                        descriptionKey: 'fbCoverPhotoSize',
                      },
                    ],
                  },
                ],
              },
            ],
          }}
          tabsIds={[
            TABS.ADJUST,
            TABS.FINETUNE,
            TABS.FILTERS,
            /* TABS.WATERMARK, */
            TABS.ANNOTATE,
            TABS.RESIZE,
          ]} // or {['Adjust', 'Annotate', 'Watermark']}
          defaultTabId={TABS.ADJUST} // or 'Annotate'
          defaultToolId={TOOLS.TEXT} // or 'Text'
          savingPixelRatio={4}
          previewPixelRatio={window.devicePixelRatio}
          useBackendTranslations={false}
          /* translations={it} */
          /* translations={Language.it} */
          /* language="it" */
          /* theme={{
                palette: {
                  'ligth-w': '#dedede',
                  'light-b': '#d5d7e0',
                  'medium-grey': '#7e7e7e',
                  'dark-grey': '#333333',
                  pink: '#9c495d',
                  'medium-green': '#00a79f',
                },
                typography: {
                  fontFamily: 'Roboto, Arial',
                },
              }} */
          theme={{
            typography: {
              fontFamily: 'Raleway',
            },
          }}
        />
      ) : (
        <ModalAlert type={AlertType[key as AlertType]} isExternal={false}>
          {t(`upload_${key}`, { count: 1 })}
        </ModalAlert>
      )}
    </ModalContent>
  );
}
