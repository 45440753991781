export default {
  "cropTool": "Culturas",
  "nameIsRequired": "O nome é obrigatório.",
  "adjustTab": "Ajuste",
  "socialMedia": "Redes sociais",
  "padding": "Acolchoamento",
  "addBackgroundAsText": "Adicionar como texto",
  "toBeUploaded": "para ser carregado",
  "imageTypeWillChangeOnSave": "Ao salvar você irá escolher um novo tipo/extensão de imagem para salvar com",
  "IconTab": "Ícone",
  "reset": "Reinicialização",
  "addIconTitle": "Escolha o tipo de Ícone",
  "classicTv": "TV clássica",
  "importing": "Importar...",
  "brightnessTool": "Luminosidade",
  "storyPhoto": "Foto da história",
  "resizeTab": "Redimensionar",
  "twInStreamPhotoSize": "1600x1900px",
  "portrait": "Retrato",
  "toggleZoomMenuTitle": "Alternar o menu de zoom",
  "saveAs": "Salvar como",
  "uploadWatermark": "Carregar marca de água",
  "closeEditor": "Fechar editor",
  "resetZoomTitle": "Zoom percentual (Clique para reiniciar)",
  "headerPhoto": "Foto de cabeçalho",
  "letterSpacing": "Espaçamento de letras",
  "feedSquarePhoto": "Foto quadrada de alimentação",
  "rectangleTool": "Rectângulo",
  "textTool": "Texto",
  "arrowTool": "Flecha",
  "rotateTool": "Rodar",
  "twHeaderPhotoSize": "1500x500px",
  "igStoryPhotoSize": "1080x1920px",
  "position": "Posição",
  "contrastTool": "Contraste",
  "unFlipX": "Un-Flip X",
  "igProfilePhoto": "320x320px",
  "horizontal": "Horizontal",
  "quality": "Qualidade",
  "ellipseTool": "Elipse",
  "fbStoryPhotoSize": "1080x1920px",
  "annotateTab": "Sorteio",
  "value": "Valor",
  "filtersTab": "Filtros",
  "zoomOutTitle": "Ampliar",
  "hsvTool": "HSV",
  "igFeedSquarePhotoSize": "1080x1080px",
  "lineHeight": "Altura da linha",
  "instagram": "Instagram",
  "addBackground": "+ Adicionar fundo",
  "previewTool": "Pré-visualização",
  "liProfilePhotoSize": "400x400px",
  "finetuneTab": "Finetune",
  "uploadIcon": "Upload Ícone",
  "liProfileCoverPhotoSize": "1584x396px",
  "inStreamPhoto": "Foto no fluxo",
  "twProfilePhotoSize": "400x400px",
  "resizeWidthTitle": "Largura em pixels",
  "toggleRatioLockTitle": "Bloqueio da relação de alternância",
  "annotationVariable": "Definir variável de anotação",
  "uploadImageError": "Erro ao fazer o upload da imagem.",
  "custom": "Personalizado",
  "cropSizeLowerThanResizedWarning": "Nota: a área de cultivo selecionada é menor do que o tamanho aplicado, o que pode causar diminuição da qualidade.",
  "showImageTitle": "Mostrar imagem original",
  "continue": "Continuar",
  "linkedIn": "LinkedIn",
  "actualSize": "Tamanho real (100%)",
  "back": "Voltar",
  "resize": "Redimensionar",
  "flipY": "Flip Y",
  "addImage": "+ Adicionar imagem",
  "fontFamily": "Família de fontes",
  "polygonTool": "Polígono",
  "blurRadius": "blurRadius",
  "imageTypeNotSavingSupported": "O tipo/extensão de imagem NÃO é suportado ao salvar",
  "penTool": "Caneta",
  "saturation": "Saturação",
  "addBackgroundTitle": "Escolha o tipo de fundo",
  "zoomInTitle": "Mais Zoom",
  "addWatermark": "+ Adicionar marca de água",
  "isNotImage": "não é imagem",
  "profileCoverPhoto": "Foto da capa do perfil",
  "feedPortraitPhoto": "Foto do retrato de alimentação",
  "textAlignment": "Alinhamento de texto",
  "watermarkTab": "Marca de água",
  "twitter": "Twitter",
  "loading": "A carregar...",
  "unFlipY": "Un-Flip Y",
  "landscape": "Paisagem",
  "original": "Original",
  "saveAsModalLabel": "Salvar a imagem como",
  "size": "Tamanho",
  "liCompanyLogoSize": "300x300px",
  "backgroundTab": "Fundo",
  "addWatermarkTitle": "Escolha o tipo de marca de água",
  "igProfilePhotoSize": "320x320px",
  "cornerRadius": "Raio do canto",
  "addIcon": "+ Adicionar Ícone",
  "liBlogPostPhotoSize": "1200x627px",
  "cinemascope": "Cinemascópio",
  "addWatermarkAsText": "Adicionar como texto",
  "lineTool": "Linha",
  "warmthTool": "Aquecimento",
  "measureTool": "Medida",
  "save": "Salvar",
  "timelinePhoto": "Foto da linha do tempo",
  "eventCoverPhoto": "Foto da capa do evento",
  "facebook": "Facebook",
  "changesLoseConfirmation": "Todas as mudanças serão perdidas.",
  "fbEventCoverPhotoSize": "1200x628px",
  "companyPageCover": "Capa da página da empresa",
  "blogPostPhoto": "Foto do post do blog",
  "undoTitle": "Desfazer última operação",
  "resetOperations": "Repor/apagar todas as operações",
  "stroke": "Stroke",
  "name": "Nome",
  "cancel": "Cancelar",
  "download": "Descarregar",
  "addIconAsText": "Adicionar como texto",
  "hue": "Matiz",
  "textSpacings": "Espaçamentos de texto",
  "fbProfilePhotoSize": "170x170px",
  "textTab": "Texto",
  "srTab": "SR",
  "blurTool": "Borrão",
  "uploadBackground": "Carregar fundo",
  "downloadAsModalLabel": "Guardar a imagem como",
  "circle": "Círculo",
  "imageDimensionsHoverTitle": "Tamanho da imagem guardada (largura x altura)",
  "addByUrl": "+ Adicionar URL",
  "changesLoseConfirmationHint": "Tens a certeza que queres continuar?",
  "redoTitle": "Refazer a última operação",
  "feedLandscapePhoto": "Fotografia da paisagem de alimentação",
  "invalidImageError": "Imagem inválida fornecida.",
  "shadow": "Sombra",
  "ellipse": "Elipse",
  "square": "Praça",
  "blur": "Borrão",
  "sides": "Lados",
  "vertical": "Vertical",
  "igFeedLandscapePhotoSize": "1080x566px",
  "imageTool": "Imagem",
  "areNotImages": "não são imagens",
  "resetSize": "Redefinir para o tamanho original da imagem",
  "extension": "Extensão",
  "opacity": "Opacidade",
  "cancelEditing": "Cancelar edição",
  "liCompanyPageCoverSize": "1128x191px",
  "transparency": "Opacidade",
  "profilePhoto": "Foto do perfil",
  "igFeedPortraitPhotoSize": "1080x1350px",
  "fitSize": "Tamanho adequado",
  "flipX": "Flip X",
  "fbProfileCoverPhotoSize": "851x315px",
  "fbTimelinePhotoSize": "1200x630px",
  "resizeHeightTitle": "Altura em pixels",
  "companyLogo": "Logótipo da empresa"
}
