import { useTranslation } from 'react-i18next';
import { useAppSelector, useAppDispatch } from '../../app/hooks';
import { RootState } from '../../app/store';
import {
  setView,
  setEditorState,
  closeGallery,
  setCurrent,
} from '../../app/reducers/gallery.reducer';

import {
  ToolbarWrapper,
  ToolbarLeftWrapper,
  ToolbarInner,
  AddImageButton,
  Button,
} from './Toolbar.style';

import {
  faImage,
  faPencil,
  faInputText,
  faFolderOpen,
  faTrashCan,
  faX,
} from '@fortawesome/pro-light-svg-icons';

import ToolbarButton from './ToolbarButton';

import {
  GalleryItemsTypes,
  GalleryItemType,
  GalleryStateEditor,
  GalleryViewState,
} from '../../custom.types';

export default function Toolbar() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const items = useAppSelector((state: RootState) => state.gallery.items);
  const isExternal = useAppSelector(
    (state: RootState) => state.gallery.isExternal
  );
  const itemsType = useAppSelector(
    (state: RootState) => state.gallery.itemsType
  );
  const selected: GalleryItemType[] = items.filter(
    (it) => it.selected === true
  );

  const handleOnUse = () => {
    dispatch(setCurrent(selected[0]));
    dispatch(closeGallery());
  };

  const handleOnEdit = () => {
    setTimeout(() => {
      dispatch(setView({ view: GalleryViewState.EDITOR, isExternal }));
    }, 0);
  };
  return (
    <ToolbarWrapper>
      <ToolbarLeftWrapper>
        {selected.length === 0 && (
          <AddImageButton
            onClick={() => dispatch(setEditorState(GalleryStateEditor.ADD))}
          >
            {t('Aggiungi immagine')}
          </AddImageButton>
        )}
        {selected.length === 1 && isExternal && (
          <Button onClick={handleOnUse}>{t('Usa Immagine')}</Button>
        )}
        <ToolbarInner>
          {selected.length > 0 && (
            <>
              <ToolbarButton
                onClick={() =>
                  selected.length === 1 &&
                  dispatch(setEditorState(GalleryStateEditor.PREVIEW))
                }
                text={t('Anteprima')}
                icon={faImage}
                disable={selected.length !== 1}
              />
              <ToolbarButton
                onClick={() => selected.length === 1 && handleOnEdit()}
                icon={faPencil}
                text={t('Modifica')}
                disable={selected.length !== 1}
              />
              <ToolbarButton
                onClick={() =>
                  selected.length === 1 &&
                  dispatch(setEditorState(GalleryStateEditor.RENAME))
                }
                icon={faInputText}
                text={t('Rinomina')}
                disable={
                  selected.length !== 1 ||
                  itemsType === GalleryItemsTypes.FREE_ITEMS
                }
              />
              <ToolbarButton
                onClick={() =>
                  selected.length > 0 &&
                  dispatch(setEditorState(GalleryStateEditor.MOVE))
                }
                icon={faFolderOpen}
                text={t('Sposta')}
                disable={selected.length === 0}
              />
              <ToolbarButton
                onClick={() =>
                  selected.length > 0 &&
                  dispatch(setEditorState(GalleryStateEditor.DELETE))
                }
                icon={faTrashCan}
                text={t('Elimina')}
                disable={
                  selected.length === 0 ||
                  itemsType === GalleryItemsTypes.FREE_ITEMS
                }
              />
            </>
          )}
        </ToolbarInner>
      </ToolbarLeftWrapper>
      {isExternal && (
        <ToolbarButton
          onClick={() => dispatch(closeGallery())}
          icon={faX}
          text={''}
        />
      )}
    </ToolbarWrapper>
  );
}
