import { useEffect, useState } from 'react';

import {
  GalleryItemSource,
  GalleryItemType,
  GalleryViewState,
} from '../../custom.types';
import { GalleryItemPictureWrapper, Picture } from './GalleryItem.style';

export default function GalleryItemPicture({
  img,
  tag,
  imgOnLoad,
}: {
  img: GalleryItemType;
  tag: GalleryViewState;
  imgOnLoad: (() => void) | undefined;
}) {
  const [src, setSrc] = useState('');
  const [srcSet, setSrcSet] = useState('');

  useEffect(() => {
    if(img && img.scaledImages) {
      const imagesPerTag = img.scaledImages.filter(
        (image: GalleryItemSource) => image.tag === tag
      );
      const srcSetArr = [];
      const indexOrig = imagesPerTag.findIndex(
        (image: GalleryItemSource) => image.suffix === ''
      );
      if (indexOrig > -1) {
        setSrc(imagesPerTag[indexOrig].src);
        for (let i = 0; i < imagesPerTag.length; i++) {
          if (i === indexOrig) continue;
          const img = imagesPerTag[i];
          srcSetArr.push(`${img.src} ${img.suffix}`);
        }
        setSrcSet(srcSetArr.join(', '));
      }
    }
  }, []);

  return (
    <Picture onLoad={imgOnLoad}>
      <GalleryItemPictureWrapper src={src} srcSet={srcSet} alt={img.alt} />
    </Picture>
  );
}
