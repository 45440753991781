import styled from 'styled-components';

export const GalleryContainerWrapper = styled.div`
  overflow: hidden;
  margin: 30px -2rem 0;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const GalleryContainerInner = styled.div`
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-right: -15px;
  align-content: flex-start;
  position: relative;
`;

export const ImageContainer = styled.div`
  border-radius: 10px;
  position: relative;
`;

export const GalleryItemPicture = styled.img`
  ${ImageContainer}:hover & {
    filter: saturate(65%);
  }
`;

export const ImageOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  display: none;
  z-index: 5;

  ${ImageContainer}:hover & {
    display: block;
  }
`;

// list
export const GalleryDetail = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px 20px;
  overflow-y: scroll;
  padding-right: 20px;
  position: relative;
`;

export const GalleryRow = styled.div`
  width: 80%;
  display: flex;
  align-items: center;
  gap: 40px;
`;

export const ImageInfoArea = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const OverlayButton = styled.button(
  ({ theme: { ada: theme } }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${theme.palette['pink']};
    font-size: 10px;
    color: white
    margin: 0 10px;
    padding: 14px 30px;
    border-radius: 30px;
`
);

export const Checkbox = styled.input`
  width: 25px;
  height: 20px;
  margin-right: 5px;
`;

export const SmallText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: black;
  font-weight: 700;
`;

export const EmptyGalleryContainer = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 300px;
`;

export const ScrollHint = styled.div`
  position: fixed;
  bottom: 3%;
  width: 100%;
  height: 140px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.5) 40%,
    rgba(255, 255, 255, 1) 85%,
    rgba(255, 255, 255, 1) 100%
  );
  z-index: 7;
`;
