import styled from 'styled-components';

import { CheckboxProps } from '../../../custom.types';

export const Check = styled.div`
  z-index: 1;

  > svg {
    color: ${({ theme: { ada: theme } }) => theme.palette['medium-green']};
    width: 95% !important;
  }
`;

export const CheckboxWrapper = styled.div`
  ${(props: CheckboxProps) =>
    props.overlay
      ? 'margin: 3% 0 0 3%;'
      : 'width: 10%; max-width: 30px; height: 100%; display: flex; justify-content: center; align-items: center;'};
`;

export const CheckboxSquare = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  /* border: 1px solid
    ${({ theme: { ada: theme } }) => theme.palette['medium-grey']}; */
  background-color: white;
  border-radius: 5px;
  box-shadow: 0px 0px 1px 2px rgb(0 0 0 / 30%);
  -webkit-box-shadow: 0px 0px 1px 2px rgb(0 0 0 / 30%);
  -moz-box-shadow: 0px 0px 1px 2px rgb(0 0 0 / 30%);
  ${(props: CheckboxProps) =>
    props.overlay
      ? 'width: 18px; height: 18px;'
      : 'width: 20px; height: 20px;'};
`;
