import { useEffect, useState } from 'react';
import './App.scss';
import Modal from './layouts/Modal';
import Archive from './layouts/Archive';
import { useAppSelector, useAppDispatch } from './app/hooks';
import { RootState } from './app/store';
import { setCurrent, setView } from './app/reducers/gallery.reducer';
// import MyContext from './context';

import {
  AdaGalleryProps,
  AdaImageProps,
  GalleryViewState,
  AppProps,
  GalleryStateEditor,
  GalleryStateStatus,
  GalleryItemPropType,
  GalleryItemType,
} from './custom.types';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { SpinnerBackdrop, Spinner } from './components/UI/UI.style';
import { getUserData } from './app/actions/main.action';
import { dashboard, fetchMedia } from './app/actions/gallery.action';
import styled, { css } from 'styled-components';

let AdaGalleryFn: AdaGalleryProps;

const GalleryContainer = styled.div<{ isView?: boolean; isExternal?: boolean }>`
  display: none;
  position: relative;
  ${({ isView }) =>
    isView &&
    css`
      display: block;
    `}
  ${({ isExternal }) =>
    !isExternal
      ? css`
          height: 100%;
        `
      : css`
          height: 100vh;
        `}
`;

function App(props: AppProps) {
  const [image] = useState<AdaImageProps>({});

  const [compId, setCompId] = useState<string>('');

  const view = useAppSelector((state: RootState) => state.gallery.view);
  const currentItem = useAppSelector(
    (state: RootState) => state.gallery.currentItem
  );
  const editorState = useAppSelector(
    (state: RootState) => state.gallery.editorState
  );
  const mainStatus = useAppSelector((state: RootState) => state.main.status);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getUserData()).then(() => {
      if (props.container?.hasAttribute('ada-gallery-view')) {
        const view = props.container?.getAttribute(
          'ada-gallery-view'
        ) as GalleryViewState;
        dispatch(setView({ view }));
      }
    });

    AdaGalleryFn = {
      openEditor: (data: Omit<GalleryItemType, 'id type selected'>) => {
        const curr: GalleryItemType = {
          ...data,
          src: data.url!,
          type: GalleryItemPropType.IMAGE,
          selected: true,
        };
        dispatch(dashboard()).then(() => {
          dispatch(setCurrent(curr));
          dispatch(
            setView({ view: GalleryViewState.EDITOR, isExternal: true })
          );
        });
      },
      resetCurrent: () => dispatch(setCurrent(null)),
      fetchMedia: (url: string) => dispatch(fetchMedia(url)),
      openArchive: (componentId: string) => {
        setCompId(() => componentId);
        return dispatch(
          setView({ view: GalleryViewState.ARCHIVE_GRID, isExternal: true })
        );
      },
      events: ['ADA_GALLERY_CLOSE'],
    };
  }, []);

  useEffect(() => {
    AdaGalleryFn.getLastImage = () => currentItem;
  }, [currentItem]);

  useEffect(() => {
    if (currentItem && currentItem.name && view === null) {
      // window.dispatchEvent(new Event('ADA_GALLERY_CLOSE'));
      window.dispatchEvent(
        new CustomEvent('ADA_GALLERY_CLOSE', { detail: compId })
      );
      setCompId(() => '');
    }
  }, [view]);

  return (
    <GalleryContainer
      isView={
        view === GalleryViewState.ARCHIVE_LIST ||
        view === GalleryViewState.ARCHIVE_GRID ||
        view === GalleryViewState.EDITOR
      }
    >
      {(mainStatus === GalleryStateStatus.LOADING ||
        mainStatus === GalleryStateStatus.LOADING_MODAL) && (
        <>
          {mainStatus === GalleryStateStatus.LOADING_MODAL && (
            <SpinnerBackdrop />
          )}
          <Spinner>
            <FontAwesomeIcon icon={faSpinner} size="8x" spin />
          </Spinner>
        </>
      )}
      {(view === GalleryViewState.ARCHIVE_LIST ||
        view === GalleryViewState.ARCHIVE_GRID) && <Archive />}
      {editorState !== GalleryStateEditor.EMPTY && <Modal />}
    </GalleryContainer>
  );
}

export const AdaGallery = () => {
  console.info('VINCI AdaGalleryFn test CI/CD RC6 ', AdaGalleryFn);
  return AdaGalleryFn;
};

export default App;
