import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { GalleryFolder } from '../custom.types';
import type { AppDispatch, RootState } from './store';

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const getSelFolderId = (): string => {
  const folders = useAppSelector((state: RootState) => state.gallery.folders);
  const selFolder = folders.find((f) => f.selected === true) as GalleryFolder;
  if (selFolder) {
    return selFolder.id;
  }
  return '0';
};
