export default {
  "cropTool": "Zuschneiden",
  "nameIsRequired": "Name ist erforderlich.",
  "adjustTab": "Anpassen",
  "socialMedia": "Soziale Medien",
  "padding": "Polsterung",
  "addBackgroundAsText": "Als Text hinzufügen",
  "toBeUploaded": "zum Hochladen",
  "imageTypeWillChangeOnSave": "Beim Speichern wählen Sie neuen Bildtyp/neue Erweiterung aus",
  "IconTab": "Icon",
  "reset": "Zurücksetzen",
  "addIconTitle": "Wählen Sie den Icon-Typ",
  "classicTv": "Klassisches Fernsehen",
  "importing": "Importieren...",
  "brightnessTool": "Helligkeit",
  "storyPhoto": "Foto Story",
  "resizeTab": "Größe ändern",
  "twInStreamPhotoSize": "1600x1900px",
  "portrait": "Porträt",
  "toggleZoomMenuTitle": "Zoom-Menü umschalten",
  "saveAs": "Speicher als",
  "uploadWatermark": "Wasserzeichen hochladen",
  "closeEditor": "Editor schließen",
  "resetZoomTitle": "Zoom % (Klicken um zurückzusetzen)",
  "headerPhoto": "Foto in Kopfzeile",
  "letterSpacing": "Buchstaben Abstand",
  "feedSquarePhoto": "Quadratisches Foto anlegen",
  "rectangleTool": "Rechteck",
  "textTool": "Text",
  "arrowTool": "Pfeil",
  "rotateTool": "Drehen",
  "twHeaderPhotoSize": "1500x500px",
  "igStoryPhotoSize": "1080x1920px",
  "position": "Position",
  "contrastTool": "Kontrast",
  "unFlipX": "Un-Flip X",
  "igProfilePhoto": "320x320px",
  "horizontal": "Horizontal",
  "quality": "Qualität",
  "ellipseTool": "Ellipse",
  "fbStoryPhotoSize": "1080x1920px",
  "annotateTab": "Zeichnen",
  "value": "Wert",
  "filtersTab": "Filter",
  "zoomOutTitle": "Verkleinern",
  "hsvTool": "HSV",
  "igFeedSquarePhotoSize": "1080x1080px",
  "lineHeight": "Linie Höhe",
  "instagram": "Instagram",
  "addBackground": "+ Hintergrund hinzufügen",
  "previewTool": "Vorschau",
  "liProfilePhotoSize": "400x400px",
  "finetuneTab": "Feinabstimmung",
  "uploadIcon": "Upload-Symbol",
  "liProfileCoverPhotoSize": "1584x396px",
  "inStreamPhoto": "In-stream Foto",
  "twProfilePhotoSize": "400x400px",
  "resizeWidthTitle": "Breite in Pixels",
  "toggleRatioLockTitle": "Ratio-Sperre umschalten",
  "annotationVariable": "Variable für Anmerkungen setzen",
  "uploadImageError": "Fehler beim Hochladen des Bildes.",
  "custom": "Personalisiert",
  "cropSizeLowerThanResizedWarning": "Bitte beachten Sie, dass der ausgewählte Schnittbereich kleiner ist, als die vorgegebene Größenänderung. Dies kann zu Qualitätseinbußen führen.",
  "showImageTitle": "Originalbild anzeigen",
  "continue": "Fortsetzen",
  "linkedIn": "LinkedIn",
  "actualSize": "Tatsächliche Größe (100%)",
  "back": "Zurück",
  "resize": "Größe ändern",
  "flipY": "Flip Y",
  "addImage": "+ Bild hinzufügen",
  "fontFamily": "Schriftfamilie",
  "polygonTool": "Polygon",
  "blurRadius": "blurRadius",
  "imageTypeNotSavingSupported": "Bildtyp/-erweiterung wird beim Speichern NICHT unterstützt",
  "penTool": "Stift",
  "saturation": "Sättigung",
  "addBackgroundTitle": "Wählen Sie den Hintergrundtyp",
  "zoomInTitle": "Vergrößern",
  "addWatermark": "+ Wasserzeichen hinzufügen",
  "isNotImage": "ist kein Bild",
  "profileCoverPhoto": "Profil-Titelbild",
  "feedPortraitPhoto": "Porträtfoto anlegen",
  "textAlignment": "Textausrichtung",
  "watermarkTab": "Wasserzeichen",
  "twitter": "Twitter",
  "loading": "Lädt...",
  "unFlipY": "Un-Flip Y",
  "landscape": "Landschaft",
  "original": "Original",
  "saveAsModalLabel": "Bild Speichern als",
  "size": "Größe",
  "liCompanyLogoSize": "300x300px",
  "backgroundTab": "Hintergrund",
  "addWatermarkTitle": "Wasserzeichenart auswählen",
  "igProfilePhotoSize": "320x320px",
  "cornerRadius": "Eckradius",
  "addIcon": "+ Icon hinzufügen",
  "liBlogPostPhotoSize": "1200x627px",
  "cinemascope": "Cinemascope",
  "addWatermarkAsText": "Als Text hinzufügen",
  "lineTool": "Linie",
  "warmthTool": "Farbwärme",
  "measureTool": "Maßnahme",
  "save": "Speichern",
  "timelinePhoto": "Foto Zeitleiste",
  "eventCoverPhoto": "Event Titelfoto",
  "facebook": "Facebook",
  "changesLoseConfirmation": "Alle Änderungen gehen verloren!",
  "fbEventCoverPhotoSize": "1200x628px",
  "companyPageCover": "Unternehmens-Startseite",
  "blogPostPhoto": "Foto-Blog Beitrag",
  "undoTitle": "\nLetzte Operation rückgängig machen",
  "resetOperations": "Alle Änderungen zurücksetzen",
  "stroke": "Strich",
  "name": "Name",
  "cancel": "Abbrechen",
  "download": "Herunterladen",
  "addIconAsText": "Als Text hinzufügen",
  "hue": "Farbton",
  "textSpacings": "Textabstände",
  "fbProfilePhotoSize": "170x170px",
  "textTab": "Text",
  "srTab": "SR",
  "blurTool": "Unschärfe",
  "uploadBackground": "Hintergrund hochladen",
  "downloadAsModalLabel": "Speichern Sie das Bild als",
  "circle": "Kreis",
  "imageDimensionsHoverTitle": "Gespeicherte Bildgröße (Breite x Höhe)",
  "addByUrl": "+ URL hinzufügen",
  "changesLoseConfirmationHint": "Möchten Sie fortsetzen?",
  "redoTitle": "Wiederholen",
  "feedLandscapePhoto": "Landschaftsfoto anlegen",
  "invalidImageError": "Fehlerhaftes Bild bereitgestellt",
  "shadow": "Schatten",
  "ellipse": "Ellipse",
  "square": "Quadrat",
  "blur": "Verschwommen",
  "sides": "Seiten",
  "vertical": "Vertikal",
  "igFeedLandscapePhotoSize": "1080x566px",
  "imageTool": "Bild",
  "areNotImages": "sind keine Bilder",
  "resetSize": "Auf Originalgröße zurücksetzen",
  "extension": "Erweiterung",
  "opacity": "Deckkraft",
  "cancelEditing": "Bearbeitung abbrechen",
  "liCompanyPageCoverSize": "1128x191px",
  "transparency": "Deckkraft",
  "profilePhoto": "Profilbild",
  "igFeedPortraitPhotoSize": "1080x1350px",
  "fitSize": "Größe anpassen",
  "flipX": "Flip X",
  "fbProfileCoverPhotoSize": "851x315px",
  "fbTimelinePhotoSize": "1200x630px",
  "resizeHeightTitle": "Höhe in Pixels",
  "companyLogo": "Firmenlogo"
}
