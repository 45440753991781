import styled from 'styled-components';
import { GalleryStateEditor } from '../../../custom.types';

export const ImagePreviewWrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: center;
  height: calc(100% - 110px);
`;

export const ImagePreview = styled.img<{ width?: string; height?: string }>` 
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : 'auto')};
  object-fit: contain;
`;

export const TopBar = styled.div`
  width: 100%;
  height: 30px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  margin-bottom: 15px;
`;

export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  // padding: 5px;
  margin-top: 30px;
`;

export const ModalContent = styled.div<{ width?: string; height?: string, editorState?: GalleryStateEditor }>`
  background-color: #fefefe;
  display: flex;
  flex-direction: column;
  justify-content: ${({editorState}) => (editorState !== GalleryStateEditor.EDIT ? 'space-between' : 'flex-start')};
  padding: 30px;
  border-radius: 10px;
  /* min-width: calc(80vh / 3 * 4); */
  height: ${({ height }) => (height ? height : '80vh')};
  width: ${({ width }) => (width ? width : '80vw')};
  max-width: 1200px;
  /* max-height: 80vh; */
  /* box-shadow: 2px 2px 12px 1px rgb(0 0 0 / 50%);
  -webkit-box-shadow: 2px 2px 12px 1px rgb(0 0 0 / 50%);
  -moz-box-shadow: 2px 2px 12px 1px rgb(0 0 0 / 50%); */
`;

export const ModalInnerWrapper = styled.div`
  width: calc(80% + 30px);
  margin: 0 auto;
`;

export const ModalWrapper = styled.div`
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const FolderWrapper = styled.div`
  position: relative;
`;

export const ImageInfo = styled.div`
  width: 66%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
`;

export const ImageInfoItem = styled.span`
  color: ${({ theme: { ada: theme } }) => theme.palette['medium-grey']};
`;

export const ImageInfoValue = styled.span`
  color: black;
`;

export const ModalTitle = styled.h3<{ color?: string }>`
  color: ${({ color }) => color || 'black'};
  width: 98%;
  text-align: center;
  margin: 0;
  font-size: 20px;
  font-weight: 400;
`;

export const ModalBody = styled.div`
  width: 100%;
`;

export const LoadImage = styled.div`
  width: 100%;
  margin: 10px auto;
`;

export const UploadChooseBarWrapper = styled.div`
  width: 100%;
  margin: 0 auto 30px;
  border-bottom: 1px solid
    ${({ theme: { ada: theme } }) => theme.palette['medium-grey']};
`;

export const UploadChooseBar = styled.div`
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 30px;
`;

interface UploadTypeProps {
  uploadType: string;
  isActive: boolean;
  onClick: () => void;
}

export const UploadTypeButton = styled.button<UploadTypeProps>(
  ({ isActive, theme: { ada: theme } }) => `
    border: none;
    background-color: unset;
    cursor: pointer;
    padding-bottom: 15px;
    border-bottom: ${
      isActive
        ? `3px solid ${theme.palette['medium-green']};`
        : '3px solid transparent;'
    };
    font-weight: ${isActive ? '700;' : 'unset;'};
  `
);

export const ImageContainer = styled.div`
  width: 100%;
  height: 30vh;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

export const ImageList = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden scroll;
  display: flex;
  flex-direction: column;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ImageListItem = styled.div`
  width: 100%;
  min-height: 85px;
  height: 85px;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  justify-content: space-between;
`;

export const ImageData = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
`;

export const DeleteListItem = styled.button<{ visible: boolean }>(
  ({ visible }) => `
    cursor: pointer;
    background-color: unset;
    border: unset;
    width: 5%;
    justify-content: flex-end;
    display: ${visible ? 'flex' : 'none'};
  `
);

export const FolderForm = styled.form`
  width: 100%;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const FolderFormInput = styled.input`
  width: 100%;
  padding: 7px;
`;

export const FolderFormMessages = styled.div`
  width: 100%;
  padding: 7px;
`;
