import styled from 'styled-components';

import { ImageContainerProps } from '../../custom.types';

export const ImageContainerWrapper = styled.div`
  position: relative;
  padding: 10px 35px;
  width: 25%;
`;

export const ImageContainer = styled.div`
  border-radius: 10px;
  position: relative;
  width: ${(props: ImageContainerProps) => props.width};
  overflow: hidden;
  display: flex;
`;

export const GalleryItemPictureWrapper = styled.img`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 4/3;

  ${ImageContainer}:hover & {
    filter: saturate(65%);
  }
`;

export const Picture = styled.picture`
  width: 100%;
`;

interface ImageOverlayProps {
  visible: boolean;
}

export const ImageOverlay = styled.div<ImageOverlayProps>`
  width: 100%;
  height: 100%;
  position: absolute;
  display: ${(props) => (props.visible ? 'block' : 'none')};
  z-index: 5;

  ${ImageContainer}:hover & {
    display: block;
  }
`;

export const GalleryDetail = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  gap: 20px 20px;
  overflow-y: scroll;
  padding-right: 20px;
  position: relative;
`;

export const GalleryRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-left: 2rem;
`;

export const GalleryRow = styled.div`
  width: 55%;
  display: flex;
  align-items: center;
  gap: 15px;
  padding-bottom: 2.1rem;
`;

export const ImageInfoArea = styled.div`
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin-left: 2rem;
`;

export const OverlayTypeWrapper = styled.div`
  position: absolute;
  bottom: 1%;
  right: 3%;
  z-index: 6;
  filter: drop-shadow(2px 2px 3px #333);
`;

/* 
export const OverlayTypeWrapper = styled.div(
  ({ theme: { ada: theme } }) => `
  position: absolute;
  background-color: ${theme.palette['pink']};
  top: 0%;
  right: 7%;
  z-index: 6;
  padding: 12px;
  border-radius: 50%;
`
);
 */
export const OverlayButton = styled.button(
  ({ theme: { ada: theme } }) => `
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: ${theme.palette['pink']};
    font-size: 10px;
    color: white;
    padding: 14px 30px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
    display: none;

    ${ImageContainer}:hover & {
      display: block;
    }
`
);

export const SmallText = styled.p`
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: black;
`;
