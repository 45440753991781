import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import { DefaultTFuncReturn } from 'i18next';
import { ReactI18NextChild } from 'react-i18next';

export enum GalleryViewState {
  ARCHIVE_GRID = 'ARCHIVE_GRID',
  ARCHIVE_LIST = 'ARCHIVE_LIST',
  EDITOR = 'EDITOR',
}

export enum GalleryItemsTypes {
  MY_ITEMS = 'MY_ITEMS',
  FREE_ITEMS = 'FREE_ITEMS',
}

export enum GalleryEventTypes {
  OPEN_ARCHIVE = 'OPEN_ARCHIVE',
  OPEN_EDITOR = 'OPEN_EDITOR',
}

export interface GalleryParams {
  type: GalleryEventTypes;
}

export interface AdaGalleryProps {
  openEditor: (data: Omit<GalleryItemType, 'id type selected'>) => void;
  openArchive: (componentId: string) => void;
  getLastImage?: (() => void) | null;
  fetchMedia: (url: string) => Promise<GalleryResponse>;
  resetCurrent: () => void;
  events: string[];
}

export interface AppProps {
  container?: HTMLElement;
}

export interface AdaImageProps {
  event?: string;
  src?: string;
  naturalHeight?: number;
  naturalWidth?: number;
  srcset?: string;
  sizes?: string;
  alt?: string;
  height?: number;
  width?: number;
}

export interface LayoutProps {
  clickHandler: (e: React.MouseEvent<HTMLElement>, type: string) => void;
  closeEditor: () => void;
}

export interface ProgressValueProps {
  value: number;
}

export interface ColumnProps {
  width: string;
  padding: string;
  justifyContent?: string;
  borderRight?: string;
  boxShadow?: string;
}

export interface CheckboxProps {
  imgId?: string;
  checked?: boolean;
  overlay?: boolean;
  className?: string;
  onChange?: (isChecked: boolean) => void;
  onClick?: () => void;
}

export interface SmallTextProps {
  color: string;
  fontSize: string;
}

export interface ButtonProps {
  padding?: string;
  margin?: string;
  color: string;
  backgroundColor: string;
  fontSize?: string;
  border: string;
  borderRadius?: string;
}
export interface SizesProps {
  width: number;
  height: number;
  tag: string;
  fit: string;
  ext: string;
  suffix: string;
}

export interface GalleryInput {
  id?: string;
  name?: string;
  folderIds?: string[];
  ids?: string[];
  type: GalleryItemPropType;
}

export interface SearchInput {
  value: string;
  type: string;
  field: string;
}

export interface OrderParams {
  key: string;
  direction: number;
}

export type boGatewayGenericValue =
  | string
  | string[]
  | number
  | number[]
  | GalleryInput
  | SaveImageData
  | SearchInput
  | OrderParams
  | ArrayBuffer;

export type boGatewayGeneric = {
  [key: string]: boGatewayGenericValue;
};

export type boGatewayParams = {
  variables: boGatewayGeneric;
  operation?: string;
  reqFields?: string;
  alias?: string;
  type?: string;
};

export type AllParams = boGatewayParams | boGatewayParams[];

export interface GalleryItemSource {
  src: string;
  resolution: ResolutionProps;
  tag: GalleryViewState;
  suffix: string;
}

export enum GalleryItemPropType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  GIF = 'GIF',
  FOLDER = 'FOLDER',
}

export interface ResolutionProps {
  full: string;
  width: number;
  height: number;
  ar: number;
}
export interface GalleryResponseItem {
  id: string;
  name: string;
  src: string;
  alt: string;
  resolution: ResolutionProps;
  size: number;
  date: string;
  type: GalleryItemPropType;
  folderIds: string[];
  // sizes: GalleryItemSource[];
  scaledImages: GalleryItemSource[];
  ext: string;
  responseMessage?: ResponseMessageObj;
  url?: string;
}

export interface GalleryItemType extends GalleryResponseItem {
  selected: boolean;
}

export interface GalleryResponseFolder {
  id: string;
  name: string;
  source: GalleryItemsTypes;
}

export interface GalleryFolder extends GalleryResponseFolder {
  selected: boolean;
}
/* export interface GalleryItemType {
  availabletime?: string;
  itemname?: string;
  price?: number;
} */

export enum GalleryStateStatus {
  INIT = 'init',
  IDLE = 'idle',
  LOADING = 'loading',
  LOADING_MODAL = 'loadingModal',
  FAILED = 'failed',
}

export enum GalleryStateEditor {
  EMPTY = 'EMPTY',
  PREVIEW = 'PREVIEW',
  EDIT = 'EDIT',
  RENAME = 'RENAME',
  MOVE = 'MOVE',
  DELETE = 'DELETE',
  ADD = 'ADD',
  MANAGE_FOLDER = 'MANAGE_FOLDER',
  ADD_FOLDER = 'ADD_FOLDER',
}

export enum GalleryFolderAction {
  EDIT = 'EDIT',
  DELETE = 'DELETE',
  ADD = 'ADD',
}

export interface GalleryFolderPayload {
  fn: GalleryFolderAction | null;
  folder: GalleryFolder | null;
  responseMessage?: ResponseMessageObj;
}

export interface GalleryFolderHandler {
  folder: GalleryFolder;
  fn: GalleryFolderAction | null;
  responseMessage?: ResponseMessageObj;
}

export interface ResponseMsgPayload {
  responseMessage: ResponseMessageObj;
}

export interface SaveChunkOptions {
  currentChunk: number;
  totalChunks: number;
  id: string;
  folderName: string;
}

export interface GalleryMemory {
  used: {
    original: number;
    all: number;
  };
  total: number;
}

interface AvailableSourceType {
  isDefault: boolean;
  value: GalleryItemsTypes;
}

export interface GalleryState {
  items: GalleryItemType[];
  itemsType: GalleryItemsTypes | null;
  currentPage: number;
  totalPages: number;
  pageSize: number;
  totalItems: number;
  memory: GalleryMemory;
  loadedPages: number;
  currentItem: GalleryItemType | null;
  view: GalleryViewState | null;
  isExternal: boolean;
  editorState: GalleryStateEditor;
  folders: GalleryFolder[];
  sources: AvailableSourceType[];
  alerts: ResponseMessageObj | null;
  searchValue: string;
  order: OrderParams;
  xAdaDestination: string;
}
export type GalleryResponse = {
  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  [key: string]: any | undefined;
};
export type GalleryAjaxResponse = {
  data: GalleryResponse;
};

export interface ImageContainerProps {
  width: string;
}

export interface ToolbarButtonProps {
  icon: IconDefinition;
  text?: string | DefaultTFuncReturn;
  disable?: boolean;
  onClick?: () => void;
}
export interface SelectListProps {
  visible: boolean;
}

export interface FileProps {
  id: number;
  src?: string;
  size: number;
  mimeType: string;
  name: string;
  date: number | string | Date;
  resolution?: ResolutionProps;
  folderIds: string[];
  ext: string;
  type: GalleryItemPropType;
}

export interface SaveImageData extends UpFile {
  sizes: GalleryItemSource[];
}

export interface FolderPillProps {
  id: string;
  name: string;
  onClick: (id: string) => void;
}

export enum AlertType {
  SUCCESS = 'SUCCESS',
  WARNING = 'WARNING',
  DANGER = 'DANGER',
  INFO = 'INFO',
}

export interface AlertProps {
  type: AlertType;
  children: string | ReactI18NextChild;
  isExternal: boolean;
  show?: boolean;
  onClick?: () => void;
}

export interface DashboardResponse {
  idPersonalData: string;
  sources: AvailableSourceType[];
  space: GalleryMemory;
}

interface CustomFieldsType {
  value: string;
  text: string;
}

interface AvailableProductsType {
  id: string;
  label: string;
}

interface CredentialsType {
  client_id?: string;
  client_secret?: string;
  idPersonalData?: string;
}

export interface UserDataResponse {
  credentials: CredentialsType;
  customFields: CustomFieldsType[];
  availableProducts: AvailableProductsType[];
}

export interface MainState {
  credentials: CredentialsType;
  customFields: CustomFieldsType[];
  availableProducts: AvailableProductsType[];
  status: GalleryStateStatus;
}

export interface TokenCache {
  accessToken: string;
  accessTokenExpiresAt: string;
  client: {
    id: string;
  };
  user: {
    id: string;
  };
}

export interface ResponseMessageObj {
  alertKey: AlertType;
  message: string;
  count?: number;
  options?: ResponseMessageOptions | SaveChunkOptions;
}

interface ResponseMessageOptions {
  [key: string]: string;
}

export interface ListItemProps {
  id: number;
  type: string;
  dir: number;
  value: string;
  selected: boolean;
}

export interface UpFile extends FileProps {
  file: File;
  status: UploadStatus;
  base64?: string;
}

export interface FullStatus {
  [key: number]: UploadInfo;
}

export interface UploadInfo {
  folderName: string;
  status: UploadStatus;
}

export enum UploadStatus {
  INIT = 'INIT',
  DONE = 'DONE',
  FAILED = 'FAILED',
}
