import { AlertProps } from '../../../custom.types';
import { MainAlert, ModalAlert } from '../UI.style';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Alert = (props: AlertProps) => {
  return (
    <MainAlert type={props.type} isExternal={props.isExternal} show={props.show}>
      <div style={{ width: '90%' }}>{props.children}</div>
      <div style={{ cursor: 'pointer' }} onClick={() => props.onClick && props.onClick()}>
        <FontAwesomeIcon icon={faX}/>
      </div>
    </MainAlert>
  );
};

export const InnerAlert = (props: AlertProps) => {
  return (
    <ModalAlert type={props.type} isExternal={props.isExternal}>{props.children}</ModalAlert>
  );
};
