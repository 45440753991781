import { createSlice } from '@reduxjs/toolkit';
import { GalleryStateStatus, MainState } from '../../custom.types';
import { dashboard, deleteItems, editItem, fetchMedia, getFoldersList, getGalleryList, manageFolder, saveFiles, saveTempMedia } from '../actions/gallery.action';
import { getUserData } from '../actions/main.action';

const initialState = {
  credentials: {
    client_id: '',
    client_secret: '',
    idPersonalData: '',
  },
  customFields: [],
  availableProducts: [],
  status: GalleryStateStatus.INIT,
} as MainState;

const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    /* setStatus(state) {
      state.status = GalleryStateStatus.LOADING;
    }, */
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFoldersList.pending, (state) => {
        state.status = GalleryStateStatus.LOADING;
      })
      .addCase(getFoldersList.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(getFoldersList.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(getGalleryList.pending, (state) => {
        state.status = GalleryStateStatus.LOADING;
      })
      .addCase(getGalleryList.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(getGalleryList.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(deleteItems.pending, (state) => {
        state.status = GalleryStateStatus.LOADING_MODAL;
      })
      .addCase(deleteItems.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(deleteItems.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(editItem.pending, (state) => {
          state.status = GalleryStateStatus.LOADING_MODAL;
      })
      .addCase(editItem.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(editItem.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(dashboard.pending, (state) => {
        state.status = GalleryStateStatus.LOADING;
      })
      .addCase(dashboard.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(dashboard.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(saveFiles.pending, (state) => {
        state.status = GalleryStateStatus.LOADING_MODAL;
      })
      .addCase(saveFiles.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(saveFiles.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(manageFolder.pending, (state) => {
        state.status = GalleryStateStatus.LOADING_MODAL;
      })
      .addCase(manageFolder.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(manageFolder.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(getUserData.pending, (state) => {
        state.status = GalleryStateStatus.LOADING;
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.status = GalleryStateStatus.IDLE;
        if (action.payload && action.payload.data) {
          state.credentials = action.payload.data.credentials;
          state.customFields = action.payload.data.customFields;
          state.availableProducts = action.payload.data.availableProducts;
        }
      })
      .addCase(getUserData.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(saveTempMedia.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(saveTempMedia.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
      .addCase(fetchMedia.pending, (state) => {
        state.status = GalleryStateStatus.LOADING_MODAL;
      })
      .addCase(fetchMedia.fulfilled, (state) => {
        state.status = GalleryStateStatus.IDLE;
      })
      .addCase(fetchMedia.rejected, (state) => {
        state.status = GalleryStateStatus.FAILED;
      })
  },
});

/* export const {
  setStatus
} = mainSlice.actions; */

export default mainSlice.reducer;
