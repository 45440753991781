import { useTranslation } from 'react-i18next';

import { ImagePreview, ImagePreviewWrapper, TopBar } from './Modal.style';
import {
  ModalContent,
  ModalTitle,
  ImageInfo,
  ImageInfoItem,
  ImageInfoValue,
} from './Modal.style';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../../theme';
import { GalleryItemType } from '../../../custom.types';
import { byteConverter } from '../../../app/helpers/common.helper';
import { closeEditor } from '../../../app/reducers/gallery.reducer';
import { useAppDispatch } from '../../../app/hooks';

export default function Preview({
  currentItem,
}: {
  currentItem: GalleryItemType;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      {currentItem && (
        <ModalContent width="auto" height="80vh">
          <TopBar onClick={() => dispatch(closeEditor(false))}>
            <ModalTitle>{currentItem.name}</ModalTitle>
            <FontAwesomeIcon
              color={theme.ada.palette['medium-grey']}
              icon={faX}
            />
          </TopBar>
          <ImagePreviewWrapper>
            <ImagePreview src={currentItem.src} />
          </ImagePreviewWrapper>
          <ImageInfo>
            <div>
              <ImageInfoItem>{`${t('Formato')}`}:</ImageInfoItem>
              <ImageInfoValue>{` ${currentItem.resolution.full}`}</ImageInfoValue>
            </div>
            <div>
              <ImageInfoItem>{`${t('Dimensione')}`}:</ImageInfoItem>
              <ImageInfoValue>{` ${byteConverter(
                currentItem.size
              )}`}</ImageInfoValue>
            </div>
            <div>
              <ImageInfoItem>{`${t('Aggiunta il')}`}:</ImageInfoItem>
              <ImageInfoValue>{` ${new Date(
                currentItem.date
              ).toLocaleDateString()}`}</ImageInfoValue>
            </div>
          </ImageInfo>
        </ModalContent>
      )}
    </>
  );
}
