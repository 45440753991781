export default {
  "cropTool": "Crop",
  "nameIsRequired": "Name is required.",
  "adjustTab": "Adjust",
  "padding": "Padding",
  "toBeUploaded": "to be uploaded",
  "reset": "Reset",
  "classicTv": "Classic TV",
  "importing": "Importing...",
  "brightnessTool": "Brightness",
  "resizeTab": "Resize",
  "portrait": "Portrait",
  "toggleZoomMenuTitle": "Toggle zoom menu",
  "saveAs": "Save as",
  "uploadWatermark": "Upload watermark",
  "letterSpacing": "Letter Spacing",
  "rectangleTool": "Rectangle",
  "textTool": "Text",
  "arrowTool": "Arrow",
  "rotateTool": "Rotate",
  "position": "Position",
  "contrastTool": "Contrast",
  "unFlipX": "Un-Flip X",
  "horizontal": "Horizontal",
  "quality": "Quality",
  "ellipseTool": "Ellipse",
  "annotateTab": "Brush",
  "value": "Value",
  "filtersTab": "Filters",
  "zoomOutTitle": "Zoom out",
  "hsvTool": "HSV",
  "lineHeight": "Line height",
  "finetuneTab": "Finetune",
  "resizeWidthTitle": "Width in pixels",
  "toggleRatioLockTitle": "Toggle ratio lock",
  "uploadImageError": "Error while uploading the image.",
  "custom": "Custom",
  "cropSizeLowerThanResizedWarning": "Note, the selected crop area is lower than the applied resize which might cause quality decrease",
  "showImageTitle": "Show original image",
  "continue": "Continue",
  "actualSize": "Actual size (100%)",
  "back": "Back",
  "resize": "Resize",
  "flipY": "Flip Y",
  "addImage": "+ Add image",
  "fontFamily": "Font family",
  "polygonTool": "Polygon",
  "penTool": "Pen",
  "saturation": "Saturation",
  "zoomInTitle": "Zoom in",
  "addWatermark": "+ Add watermark",
  "isNotImage": "is not image",
  "textAlignment": "Text alignment",
  "watermarkTab": "Watermark",
  "loading": "Loading...",
  "unFlipY": "Un-Flip Y",
  "landscape": "Landscape",
  "original": "Original",
  "saveAsModalLabel": "Save the image as",
  "size": "Size",
  "addWatermarkTitle": "Choose the watermark type",
  "cornerRadius": "Corner Radius",
  "cinemascope": "Cinemascope",
  "addWatermarkAsText": "Add as text",
  "lineTool": "Line",
  "warmthTool": "Warmth",
  "save": "Save",
  "changesLoseConfirmation": "All changes will be lost",
  "undoTitle": "Undo last operation",
  "resetOperations": "Reset/delete all operations",
  "stroke": "Stroke",
  "name": "Name",
  "cancel": "Cancel",
  "hue": "Hue",
  "textSpacings": "Text spacings",
  "textTab": "Text",
  "blurTool": "Blur",
  "imageDimensionsHoverTitle": "Saved image size (width x height)",
  "changesLoseConfirmationHint": "Are you sure you want to continue?",
  "redoTitle": "Redo last operation",
  "invalidImageError": "Invalid image provided.",
  "shadow": "Shadow",
  "ellipse": "Ellipse",
  "square": "Square",
  "blur": "Blur",
  "sides": "Sides",
  "vertical": "Vertical",
  "imageTool": "Image",
  "areNotImages": "are not images",
  "resetSize": "Reset to original image size",
  "extension": "Extension",
  "opacity": "Opacity",
  "fitSize": "Fit size",
  "flipX": "Flip X",
  "shapeTab": "Shape",
  "resizeHeightTitle": "Height in pixels"
}
