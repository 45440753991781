import { concatMap, map, Observable, of } from 'rxjs';
import { ajax, AjaxConfig } from 'rxjs/ajax';
import {
  AllParams,
  boGatewayGeneric,
  boGatewayGenericValue,
  boGatewayParams, GalleryAjaxResponse,
  GalleryResponse,
  TokenCache
} from '../../custom.types';
import config from '../config/config';
import { capitalize, getBoGatewayhDomain, getTdbOauthDomain, getUserDataUrl } from '../helpers/common.helper';

export default class GraphqlService {
  private static _instance: GraphqlService;
  private _tokens: TokenCache[] = [];

  public static getInstance(): GraphqlService {
    if (!GraphqlService._instance) {
      GraphqlService._instance = new GraphqlService();
    }

    return GraphqlService._instance;
  }

  request(
    credentials: GalleryResponse,
    params: AllParams, 
    xAdaDestination = ''
  ): Observable<GalleryResponse> {
    return this.getOauthToken(credentials).pipe(
      concatMap((token: GalleryResponse) => {
          const newToken = token as TokenCache;
          return this.saveTokenCache(newToken);
        }
      ),
      concatMap((token) =>
        this.getData(credentials.idPersonalData, params, token.accessToken, xAdaDestination)
      )
    );
  }

  saveTokenCache(token: TokenCache) {
    if(token?.user?.id) {
      const findToken = this._tokens.findIndex(t => String(t.user.id) === String(token.user.id));
      if(findToken === -1) {
        this._tokens.push(token);
      }
    }
    return of(token);
  }

  getTokenCache(idPersonalData: string): TokenCache | null {
    const findToken = this._tokens.find(t => String(t.user.id) === String(idPersonalData));
    if(findToken) {
      const expire = new Date(findToken.accessTokenExpiresAt).getTime();
      const now = Date.now();
      if((expire - 5000) > now) {
        return findToken;
      } else {
        this._tokens = this._tokens.filter(t => String(t.user.id) !== String(idPersonalData));
      }
    }
    return null;
  }

  getData(
    idPersonalData: string,
    params: AllParams,
    token: string,
    xAdaDestination = ''
  ): Observable<GalleryResponse> {
    const data = this.buildGraphQLData(params);
    if(!data) {
      return of({})
    }
    const headers: Record<string, string> = {
      'content-type': 'application/json',
    };
    if (token) {
      headers['Authorization'] = `Bearer ${token}`;
    }
    if(xAdaDestination !== '') {
      headers['x-ada-destination'] = xAdaDestination;
    }
    const options = {
      method: 'POST',
      headers,
      body: data,
      url: `${getBoGatewayhDomain()}/${idPersonalData}`,
    };

    return this.doRequest(options);
  }

  getUserData(): Observable<GalleryResponse> {
    if (!config.tdbOauth.enable) {
      return of({ credentials: null });
    }
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      url: getUserDataUrl(),
    };
    return this.doRequest(options);
  }
  
  getOauthToken(credentials: GalleryResponse): Observable<GalleryResponse> {
    if (!config.tdbOauth.enable) {
      return of({ accessToken: '' });
    }
    /* const client_id = '74F027eEC425350C0a341D21c1acB609';
    const client_secret = '3E0f911Dde85fC36C39B9658423b1e27'; */
    const { client_id, client_secret, idPersonalData } = credentials;
    const findToken = this.getTokenCache(idPersonalData);
    if(findToken) {
      return of(findToken);
    }
    const body = `client_id=${client_id}&client_secret=${client_secret}&grant_type=client_credentials&scope=ada_gallery`;
    const options = {
      method: 'POST',
      headers: { 'content-type': 'application/x-www-form-urlencoded' },
      body,
      url: `${getTdbOauthDomain()}/${idPersonalData}${config.tdbOauth.paths.token}`,
    };
    return this.doRequest(options);
  }

  // eslint-disable-next-line  @typescript-eslint/no-explicit-any
  doRequest(options: AjaxConfig): Observable<GalleryResponse> {
    return ajax<GalleryAjaxResponse>(options).pipe(
      map((response) => response?.response?.data)
    );
  }

  buildGraphQLData(params: AllParams) {
    const queryTypes = [];

    const items: boGatewayParams[] = Array.isArray(params) ? params : [params];
    const variables: boGatewayGeneric = this.buildGraphQLVariables(items);
    const queries = [];

    const types = new Set(items.map(item => item.type));
    if(types.size > 1) {
      return null;
    }

    let type = 'query';
    if(types.size > 0) {
      type = types.values().next().value || 'query';
    }

    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const queryArgs = [];
      if (item.variables) {
        const varKeys = Object.keys(item.variables);
        for (let j = 0; j < varKeys.length; j++) {
          const name = varKeys[j];
          const value = item.variables[name];
          const type = this.getGraphQLVariableType(name, value, item);

          let queryType = '$' + name + ':' + type;
          let queryArg = name + ':' + '$' + name;

          if (items.length > 1) {
            queryType = '$' + this.getGraphQLVariableName(name, i) + ':' + type;
            queryArg = name + ':' + '$' + this.getGraphQLVariableName(name, i);
          }

          if (queryTypes.indexOf(queryType) < 0) {
            queryTypes.push(queryType);
          }
          queryArgs.push(queryArg);
        }
        let query = '';
        if (item.alias) {
          query += item.alias + ': ';
        }
        query +=
          item.operation + '(' + queryArgs.join(',') + ')' + item.reqFields;
        queries.push(query);
      }
    }
    return {
      query: `${type}(${queryTypes.join(',')}){${queries.join('')}}`,
      variables: variables,
    };
  }

  getGraphQLVariableType(
    name: string,
    value: boGatewayGenericValue,
    item: boGatewayParams
  ) {
    let type = capitalize(typeof value);
    if (type === 'Number') {
      type = 'Int';
    }
    if (Array.isArray(value) && value.length > 0) {
      type = '[' + capitalize(typeof value[0]) + ']';
    }

    if (name === 'refEvent') {
      type = '[String]';
    }

    if (name === 'search') {
      type = 'SearchInput';
    }

    if (name === 'date') {
      type = 'Date';
    }

    if (name === 'source') {
      type = 'GalleryEnumSources';
    }

    if (name === 'folderId') {
      type = 'String';
    }

    if (name === 'order') {
      type = 'OrderParams';
    }

    if(item.operation === 'manageGallery') {
      if(name === 'input') {
        type = 'GalleryInput';
      }
      if(name === 'action') {
        type = 'GalleryEnumAction';
      }
    }

    if (name === 'type') {
      switch (item.operation) {
        case 'itemsList':
          type = 'itemsListTypes';
          break;
        case 'gallery':
          type = 'GalleryEnumTypes';
          break;
        default:
          type = 'type';
          break;
      }
    }
    return type;
  }

  buildGraphQLVariables(items: boGatewayParams[]) {
    if (items.length === 1) {
      return items[0].variables;
    }
    const variables: boGatewayGeneric = {};
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const varKeys = Object.keys(item.variables);
      for (let j = 0; j < varKeys.length; j++) {
        const varKey = this.getGraphQLVariableName(varKeys[j], i);
        variables[varKey] = item.variables[varKeys[j]];
      }
    }
    return variables;
  }

  getGraphQLVariableName(name: string, index: number): string {
    return `${name}_${index}`;
  }
}
