import { ChangeEvent, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from '../../../app/hooks';
import { RootState } from '../../../app/store';
import { GalleryFolder } from '../../../custom.types';
import { Select } from './Select.style';

export const Folders = ({
  label,
  handler,
  placeholder,
  disabled
}: {
  handler: (folder: GalleryFolder) => void;
  label: string;
  placeholder?: string | undefined;
  disabled: boolean
}) => {
  const { t } = useTranslation();
  const folders = useAppSelector((state: RootState) => state.gallery.folders);
  const selectRef = useRef<HTMLSelectElement | null>(null);

  const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
    if (evt.target.value) {
      const id = evt.target.value;
      const folder = folders.find((f) => f.id === id);
      if (folder) {
        handler(folder);
      }
      if (selectRef.current) {
        if (hasPlaceholder) {
          selectRef.current.value = placeholder as string;
        }
      }
    }
  };

  const hasPlaceholder = placeholder || placeholder === '' ? true : false;

  return (
    <div style={{ marginTop: '25px' }}>
      <label htmlFor="">{`${t(label)}`}</label>
      <Select ref={selectRef} onChange={onChange} disabled={disabled}>
        {hasPlaceholder && (
          <option key={placeholder} value={placeholder}>
            {placeholder}
          </option>
        )}
        {folders.map(
          (folder, i) =>
            folder.id !== '0' && (
              <option key={i} value={folder.id}>
                {folder.name}
              </option>
            )
        )}
      </Select>
    </div>
  );
};
