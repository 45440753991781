import { useEffect } from 'react';

import { useAppDispatch, useAppSelector } from '../app/hooks';
import { dashboard } from '../app/actions/gallery.action';

import Sidebar from '../components/Sidebar';
import Content from '../components/Content/Content';
import { Container } from '../components/UI/UI.style';
import { RootState } from '../app/store';

export default function Archive() {
  const dispatch = useAppDispatch();

  const isExternal = useAppSelector((state: RootState) => state.gallery.isExternal);

  useEffect(() => {
    dispatch(dashboard());
  }, []);

  return (
    <Container isExternal={isExternal}>
      <Sidebar />
      <Content />
    </Container>
  );
}
