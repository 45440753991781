import { createAsyncThunk } from '@reduxjs/toolkit';
import { map, Observable, of } from 'rxjs';
import { ajax } from 'rxjs/ajax';
import { UserDataResponse } from '../../custom.types';
import { getUserDataUrl } from '../helpers/common.helper';

import mockUserDataResponse from '../../mockUserDataResponse';

function doRequest(): Observable<{ data: UserDataResponse | null }> {
  if (window.location.href.indexOf('51.178.131.117') > -1) {
    // return of({ idPersonalData:"939", client_id:"74F027eEC425350C0a341D21c1acB609", client_secret:"3E0f911Dde85fC36C39B9658423b1e27" });
    return of(mockUserDataResponse);
  }
  const body = '';
  const options = {
    method: 'POST',
    headers: { 'content-type': 'application/x-www-form-urlencoded' },
    body,
    url: getUserDataUrl(),
  };
  return ajax<{ data: UserDataResponse | null }>(options).pipe(
    map((response) => response?.response)
  );
}

export const getUserData = createAsyncThunk(
  'credentials/get',
  (_a, { getState }): Promise<{ data: UserDataResponse | null }> => {
    return new Promise((resolve, reject) => {
      const res$ = doRequest();
      res$.subscribe({
        next: (value) => {
          resolve(value);
        },
        error: (err) => {
          console.error(err.message);
          return reject(err);
        },
      });
    });
  }
);
