import styled from 'styled-components';
import { ProgressValueProps } from '../custom.types';

export const AvailableSpaceWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: end;
`;

export const ProgressValue = styled.div<ProgressValueProps>`
  width: ${(props) => props.value}%;
  height: 90%;
  background-color: ${({ theme: { ada: theme } }) =>
    theme.palette['medium-green']};
  border-radius: 20px;
`;

export const ProgressBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 21px;
  padding: 1px 3px;
  border-radius: 20px;
  background-color: white;
  border: 1px solid
    ${({ theme: { ada: theme } }) => theme.palette['medium-green']};
  margin-top: 5px;
`;
