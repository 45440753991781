import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { closeEditor, incrPage, moveItems } from '../../../app/reducers/gallery.reducer';

import {
  GalleryFolder,
  GalleryFolderAction,
  GalleryFolderHandler,
} from '../../../custom.types';

import { ModalContent, ModalTitle } from './Modal.style';
import { TopBar, Footer, ModalBody } from './Modal.style';
import { ButtonOk, ButtonCancel } from '../UI.style';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';
import { AddInFolder } from '../../AddInFolder/AddInFolder';
import { RootState } from '../../../app/store';
import { dashboard, editItem } from '../../../app/actions/gallery.action';

export default function Move() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const items = useAppSelector((state: RootState) => state.gallery.items);
  const selected = items.filter((it) => it.selected === true);
  const folders = useAppSelector((state: RootState) => state.gallery.folders);

  const [selectedFolders, setSelectedFolders] = useState<GalleryFolder[]>([]);

  const MoveHandler = () => {
    dispatch(
      moveItems({
        ids: selected.map((i) => i.id),
        folderIds: selectedFolders.map((f) => f.id),
      })
    );
    dispatch(editItem({ folderIds: selectedFolders.map((f) => f.id) }))
    dispatch(closeEditor(true));
    dispatch(dashboard()).then(() => dispatch(incrPage()));
  };

  useEffect(() => {
    const current = selected.length === 1 ? selected[0] : null;
    if (current) {
      setSelectedFolders(
        folders.filter((f) => current.folderIds.includes(f.id))
      );
    }
  }, []);

  const selectFolderHandler = ({ fn, folder }: GalleryFolderHandler) => {
    const index = selectedFolders.findIndex((f) => f.id === folder.id);
    switch (fn) {
      case GalleryFolderAction.ADD:
        if (index === -1) {
          setSelectedFolders((oldState) => [...oldState, ...[folder]]);
        }
        break;
      case GalleryFolderAction.DELETE:
        if (index !== -1) {
          setSelectedFolders((oldState) =>
            oldState.filter((f) => f.id !== folder.id)
          );
        }
        break;
    }
  };

  return (
    <ModalContent width="600px" height="auto">
      <TopBar onClick={() => dispatch(closeEditor())}>
        <ModalTitle color={theme.ada.palette['medium-green']}>{`${t(
          'Sposta'
        )}`}</ModalTitle>
        <FontAwesomeIcon
          color={theme.ada.palette['medium-grey']}
          icon={faX}
        />
      </TopBar>
      <ModalBody>
        {/* {selectedFolders.length === 0 && (
          <p>{`${t("Seleziona la cartella in cui spostare l'immagine")}`}</p>
        )} */}
        {/* {selectedFolders.length > 0 && (
          <p>{`${t("Aggiungi o rimuovi cartella all'immagine")}`}</p>
        )} */}
        <AddInFolder
          selectedFolders={selectedFolders}
          handler={selectFolderHandler}
          label={t('PlaceInFolder', {action: 'Spostare', key: 'YourFile_other'})}
          disabled={false}
        />
      </ModalBody>
      <Footer>
        <ButtonOk onClick={MoveHandler}>{t('Conferma')}</ButtonOk>
        <ButtonCancel onClick={() => dispatch(closeEditor())}>
          {t('Annulla')}
        </ButtonCancel>
      </Footer>
    </ModalContent>
  );
}
