import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useRef, useState } from 'react';
import { ButtonOk } from '../UI.style';
import { ValidationMessage } from '../ValidationMessage';
import { validateImageUrl } from '../../../app/helpers/common.helper';

const UrlInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const InputWrapper = styled.div`
  display: flex;
  width: 100%;
`;

const Input = styled.input`
  width: 95%;
  padding: 7px;
`;

let errMessage: string;

const UrlInput = ({ onImportURL }: { onImportURL: (url: string) => void }) => {
  const { t } = useTranslation();

  const [isValid, setIsValid] = useState<boolean>(true);

  const inputRef = useRef<HTMLInputElement>(null);

  const importUrl = () => {
    const url = (inputRef.current as HTMLInputElement).value;
    if(url === '') return;
    onImportURL(url);
  }

  const validateUrl = () => {
    const url = inputRef.current?.value || '';
    const isUrlValid = validateImageUrl(url);
    if(!isUrlValid) {
      errMessage = 'invalid_url'
    }
    setIsValid(() => isUrlValid);
  }

  return (
    <UrlInputWrapper>
      <label>{t('Inserisci l’URL del file')}</label>
      <InputWrapper>
        <Input ref={inputRef} type='url' style={{ marginRight: '5px' }} onChange={validateUrl} />
        <ButtonOk onClick={importUrl} disabled={!isValid}>{t('Ok')}</ButtonOk>
      </InputWrapper>
      {!isValid && <ValidationMessage>{t(errMessage, {})}</ValidationMessage>}
    </UrlInputWrapper>
  )
}

export default UrlInput;