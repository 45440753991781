/** External Dependencies */
import React, { useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useTheme } from '@scaleflex/ui/theme/hooks/use-theme';

/** Internal Dependencies */
import { useAppReducer } from 'ada-image-editor/hooks';
import {
  translate,
  updateTranslations,
} from 'ada-image-editor/utils/translator';
import appReducer from './appReducer';
import AppContext from './AppContext';
import getInitialAppState from './getInitialAppState';

const AppProvider = ({ children, config = {} }) => {
  const [state, _dispatch] = useAppReducer(
    appReducer,
    getInitialAppState(config),
    config
  );

  const dispatch = useCallback(
    (...args) => {
      _dispatch(...args);
    },
    [_dispatch]
  );

  useEffect(() => {
    updateTranslations(config.translations, config.language);
  }, [config.useBackendTranslations, config.language, config.translations]);

  const theme = useTheme();
  const { t } = useTranslation();
  const providedValue = useMemo(
    () => ({
      ...state,
      config,
      theme,
      dispatch,
      t,
      /* t: translate, */
    }),
    [config, state]
  );

  return (
    <AppContext.Provider value={providedValue}>{children}</AppContext.Provider>
  );
};

AppProvider.defaultProps = {
  config: {},
};

AppProvider.propTypes = {
  children: PropTypes.node.isRequired,
  config: PropTypes.instanceOf(Object),
};

export default AppProvider;
