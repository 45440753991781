import { useTranslation } from 'react-i18next';

import {
  AvailableSpaceWrapper,
  ProgressBar,
  ProgressValue,
} from './AvailableSpace.style';
import { SmallText } from './UI/UI.style';

import theme from '../theme';
import { RootState } from '../app/store';
import { useAppSelector } from '../app/hooks';
import { useEffect, useState } from 'react';
import { byteConverter } from '../app/helpers/common.helper';

export const AvailableSpace = () => {
  const { t } = useTranslation();
  const memory = useAppSelector((state: RootState) => state.gallery.memory);
  const [space, setSpace] = useState('');

  useEffect(() => {
    if (memory && memory.total !== 0) {
      const occupiedSpaceValue = memory.used.original / memory.total;
      setSpace((occupiedSpaceValue * 100).toFixed(2));
    }
  }, [memory]);

  return (
    <>
      {space && (
        <AvailableSpaceWrapper>
          <label>
            <SmallText
              color={theme.ada.palette['medium-grey']}
              fontSize={'11px'}
            >
              <span
                style={{
                  color: theme.ada.palette['medium-green'],
                  fontWeight: '700',
                }}
              >
                {`${space}% ${t('usato')}`}
              </span>
              {` (${byteConverter(memory.used.original)} ${t(
                'su'
              )} ${byteConverter(memory.total)})`}
            </SmallText>
          </label>
          <ProgressBar>
            <ProgressValue value={parseFloat(space)} />
          </ProgressBar>
        </AvailableSpaceWrapper>
      )}
    </>
  );
};

export default AvailableSpace;
