import { Buffer } from 'buffer';
import { GalleryItemPropType } from '../../custom.types';
import config from '../config/config';

import {
  faFolder,
  faGif,
  faImage,
  faVideo,
  IconDefinition,
} from '@fortawesome/pro-regular-svg-icons';

export async function encode(str: string | Uint8Array) {
  const buff =
    typeof str === 'string' ? Buffer.from(str, 'utf-8') : Buffer.from(str);
  const base64 = buff.toString('base64');
  return base64;
}

export async function decode(base64: string) {
  const buff = Buffer.from(base64, 'base64');
  const str = buff.toString('utf-8');
  return str;
}

export function getImageSize(base64: string): number {
  return (Buffer.from(String(base64)).length / 4) * 3;
}

export function capitalize(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function byteConverter(number: number, i = 0): string {
  const BASE = 1024;
  const units = ['B', 'KB', 'MB', 'GB', 'TB'];
  if (number < BASE) return `${number} ${units[i]}`;
  return byteConverter(Math.ceil(number / BASE), ++i);
}

export function getBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      let str = reader.result;
      if (!str) reject('');

      str = str?.toString() || '';
      resolve(str);
    };
    reader.readAsDataURL(file);
  });
}

export function getMediaType(mimeType: string): GalleryItemPropType {
  switch (mimeType) {
    case 'image/png':
    case 'image/bmp':
    case 'image/jpeg':
    case 'image/jpg':
    case 'image/tiff':
    case 'image/webp':
    case 'image/avif':
      return GalleryItemPropType.IMAGE;
    case 'image/gif':
      return GalleryItemPropType.GIF;
    default:
      return GalleryItemPropType.VIDEO;
  }
}

export function validateFolderName(val: string): boolean {
  return /^[\w\d\s\-_.,()~éèìòàùÈÉÒÀÙÌ]+$/g.test(val.trim());
}

export function getTdbOauthDomain(): string {
  const defaultUrl = 'https://tdb-oauth.staging.adaoncloud.com';
  if (window.location.href.indexOf('51.178.131.117') > -1) {
    return defaultUrl;
  }
  return config.tdbOauth.domain || defaultUrl;
}

export function getBoGatewayhDomain(): string {
  const defaultUrl = 'http://51.178.131.117:3018';
  if (window.location.href.indexOf('51.178.131.117') > -1) {
    return defaultUrl;
  }

  return config.boGateway.url || defaultUrl;
}

export function getBeDomain(): string {
  const defaultUrl = 'http://51.178.131.117:4300';
  if (window.location.href.indexOf('51.178.131.117') > -1) {
    return defaultUrl;
  }
  return config.galleryBe.url || defaultUrl;
}

export function getUserDataUrl(): string {
  let url = `${window.location.protocol}//${window.location.host}`;
  if (window.location.href.indexOf('4200') > -1) {
    url = 'http://localhost';
  }
  return `${url}/solutions/gallery/getUserData`;
}

export function getIcon(type: string): IconDefinition {
  switch (type) {
    case GalleryItemPropType.VIDEO:
      return faVideo;
    case GalleryItemPropType.GIF:
      return faGif;
    case GalleryItemPropType.FOLDER:
      return faFolder;
    default:
      return faImage;
  }
}

export function validateFileName(val: string): boolean {
  return /^[\w,\s-._]+$/g.test(val.trim());
}

export function validateImageUrl(val: string): boolean {
  return /^(http)?s?:?(\/\/[^"']*\.(?:png|jpg|jpeg|gif|png|svg))$/gi.test(val);
}
