import Toolbar from '../Toolbar/Toolbar';
import FilterBar from '../FilterBar/FilterBar';
import GalleryContainer from '../GalleryContainer/GalleryContainer';
import { ContentWrapper } from './Content.style';
import { useAppSelector } from '../../app/hooks';
import { RootState } from '../../app/store';

export default function Content() {
  const items = useAppSelector((state: RootState) => state.gallery.items);
  return (
    <ContentWrapper>
      <Toolbar />
      <FilterBar />
      <GalleryContainer />
    </ContentWrapper>
  );
}
