import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AlertType,
  AllParams,
  boGatewayGeneric,
  boGatewayParams,
  GalleryFolder,
  GalleryFolderAction,
  GalleryFolderPayload,
  GalleryInput,
  GalleryItemPropType,
  GalleryItemsTypes,
  GalleryItemType,
  GalleryResponse,
  GalleryResponseItem,
  ResponseMsgPayload,
  SaveImageData,
  SearchInput,
  UpFile,
} from '../../custom.types';
import BeDirectService from '../classes/beDirect.class';
import GraphqlService from '../classes/graphql.class';
import config from '../config/config';
import { RootState } from '../store';

/* const common = (params: boGatewayParams): Promise<GalleryResponse> => {
  return new Promise((resolve, reject) => {
    const graphqlService = GraphqlService.getInstance();
    const res$ = graphqlService.request(credentials, params);

    res$.subscribe({
      next: (value) => {
        const p = params?.alias || params?.operation || '';
        const items = value[p] || [];
        const metadata = value.metadata[p] || {};
        resolve({ items, metadata });
      },
      error: (err) => {
        console.error(err.message);
        return reject(err);
      },
    });
  });
}; */

// const common = (state: GalleryState): Promise<GalleryResponse> => {
const common = ({
  mockData,
  page,
  size,
}: {
  mockData: GalleryResponseItem[];
  page: number;
  size: number;
}): Promise<GalleryResponse> => {
  return new Promise((resolve, reject) => {
    const from = (page - 1) * size;
    const to = from + size;
    const items = mockData.slice(from, to);
    resolve({ items, metadata: { totalItems: mockData.length } });
  });
};

export const getGalleryList = createAsyncThunk(
  'gallery/galleryList',
  (_a, { getState }): Promise<GalleryResponse> => {
    return new Promise((resolve, reject) => {
      const {
        gallery: state,
        main: { credentials },
      } = getState() as RootState;
      if (!state.itemsType) {
        return resolve({});
      }

      let data: GalleryResponse = {};
      let search: SearchInput;
      let id = '0';
      if (state.folders.length > 0) {
        id = state.folders.find((f) => f.selected)?.id || '0';
      }
      const graphqlService = GraphqlService.getInstance();
      const params: AllParams = {
        variables: {
          type: 'list',
          source: GalleryItemsTypes[state.itemsType],
          page: state.currentPage,
          folderId: id,
          size: state.pageSize,
          order: state.order,
        },
        operation: 'gallery',
        reqFields:
          '{ items { id name src type ext date resolution { width height ar full } size folderIds scaledImages { resolution { width height ar full } src tag suffix } } }',
        alias: '',
      };

      if (state.searchValue) {
        search = {
          value: state.searchValue,
          field: 'name',
          type: 'LIKE',
        };
        params.variables.search = search;
      }

      const res$ = graphqlService.request(credentials, params);
      res$.subscribe({
        next: (value) => {
          const p = params?.alias || params?.operation || '';
          const items = value[p] || [];
          const metadata = value.metadata[p] || {};

          data = JSON.parse(JSON.stringify(items));

          resolve({
            items: data.items,
            metadata,
          });
        },
        error: (err) => {
          console.error(err.message);
          return reject(err);
        },
      });
    });
  }
);

export const dashboard = createAsyncThunk(
  'gallery/dashboard',
  (_a, { getState }): Promise<GalleryResponse> => {
    return new Promise((resolve, reject) => {
      const {
        gallery: state,
        main: { credentials },
      } = getState() as RootState;

      let data: GalleryResponse = {};

      const graphqlService = GraphqlService.getInstance();
      const params = {
        variables: {
          type: 'dashboard',
        },
        operation: 'gallery',
        reqFields: '{ sources { isDefault value } space }',
        alias: 'galleryDashboard',
      };

      const res$ = graphqlService.request(credentials, params);
      res$.subscribe({
        next: (value) => {
          const p = params?.alias || params?.operation || '';
          const items = value[p] || [];
          console.log('dashboard response', value);
          data = JSON.parse(JSON.stringify(items));

          resolve({
            sources: data.sources,
            memory: data.space,
          });
        },
        error: (err) => {
          console.error(err.message);
          return reject(err);
        },
      });
    });
  }
);

export const deleteItems = createAsyncThunk(
  'gallery/deleteItems',
  (a, { getState }): Promise<ResponseMsgPayload> => {
    return new Promise((resolve, reject) => {
      const {
        gallery: state,
        main: { credentials },
      } = getState() as RootState;
      const ids = state.items
        .filter((it) => it.selected === true)
        .map((i) => i.id);

      if (!ids) {
        return resolve({
          responseMessage: {
            alertKey: AlertType.DANGER,
            message: 'Media not found',
          },
        });
      }

      const graphqlService = GraphqlService.getInstance();

      const params = {
        variables: {
          input: {
            type: 'IMAGE',
            ids,
          },
          action: 'DELETE',
        },
        operation: 'manageGallery',
        reqFields: '{ responseMessage { alertKey message count options } }',
        alias: '',
        type: 'mutation',
      } as boGatewayParams;
      const res$ = graphqlService.request(credentials, params);
      res$.subscribe({
        next: (value) => {
          const p = params?.alias || params?.operation || '';
          // const items = value[p] || [];
          const metadata = value.metadata[p] || {};
          resolve(metadata);
        },
        error: (err) => {
          console.error(err.message);
          return reject(err);
        },
      });
    });
  }
);

export const editItem = createAsyncThunk(
  'gallery/editItem',
  (
    { name, folderIds }: Partial<GalleryItemType>,
    { getState }
  ): Promise<Partial<GalleryItemType> | null> => {
    return new Promise((resolve, reject) => {
      const {
        gallery: state,
        main: { credentials },
      } = getState() as RootState;
      //const item = state.items.find((it) => it.selected === true);
      const items = state.items.filter((it) => it.selected === true);
      // let takeOldFolders = false;
      if (items.length < 1) {
        return resolve(null);
      } /* else {
        takeOldFolders = true;
      } */

      for (let i = 0; i < items.length; i++) {
        const item = items[i];
        const input = { type: 'IMAGE', id: item.id } as GalleryInput;
        if (name) {
          input.name = name;
        }
        if (folderIds) {
          input.folderIds = folderIds;
          // ? Devo prendere in considerazione le vecchie cartelle?
          // if(takeOldFolders) {
          //   input.folderIds = [ ...Array.from(new Set([...item.folderIds, ...folderIds ]))];
          // }
        }

        const graphqlService = GraphqlService.getInstance();

        const params = {
          variables: {
            input,
            action: 'EDIT',
          },
          operation: 'manageGallery',
          reqFields: '{ responseMessage { alertKey message count options } }',
          alias: '',
          type: 'mutation',
        } as boGatewayParams;
        const res$ = graphqlService.request(credentials, params);
        res$.subscribe({
          next: (value) => {
            const p = params?.alias || params?.operation || '';
            const metadata = value.metadata[p] || {};
            resolve({
              name,
              folderIds,
              responseMessage: metadata.responseMessage,
            });
          },
          error: (err) => {
            console.error(err.message);
            return reject(err);
          },
        });
      }
    });
  }
);

export const saveFiles = createAsyncThunk(
  'gallery/saveFiles',
  (files: UpFile[], { getState }): Promise<GalleryResponse> => {
    return new Promise((resolve, reject) => {
      const {
        gallery: { currentItem, xAdaDestination },
        main: { credentials },
      } = getState() as RootState;
      const graphqlService = GraphqlService.getInstance();

      for (let i = 0; i < files.length; i++) {
        const file = files[i] as SaveImageData;
        file.sizes = JSON.parse(JSON.stringify(config.defaultSizes));
        file.date = new Date(file.date).toISOString();

        const media = JSON.parse(JSON.stringify(file));
        delete media.id;
        delete media.file;
        delete media.status;

        const params = {
          variables: {
            input: media,
            action: 'SAVE',
          },
          operation: 'manageGallery',
          reqFields:
            '{ id name src type ext date resolution { width height ar full } size folderIds scaledImages { resolution { width height ar full } src tag suffix } responseMessage { alertKey message count options } }',
          alias: '',
          type: 'mutation',
        } as boGatewayParams;
        const res$ = graphqlService.request(
          credentials,
          params,
          xAdaDestination
        );
        res$.subscribe({
          next: (value) => {
            const p = params?.alias || params?.operation || '';
            const metadata = value.metadata[p] || {};

            resolve({ ...value.manageGallery, metadata });
          },
          error: (err) => {
            console.error(err.message);
            return reject(err);
          },
        });
      }
    });
  }
);

export const manageFolder = createAsyncThunk(
  'gallery/manageFolder',
  (
    {
      fn,
      folder,
      name,
    }: {
      fn: GalleryFolderAction | null;
      folder: GalleryFolder | null;
      name?: string | null;
    },
    { getState }
  ): Promise<GalleryFolderPayload> => {
    return new Promise((resolve, reject) => {
      const {
        gallery: state,
        main: { credentials },
      } = getState() as RootState;
      if (fn) {
        let action = '';
        let id = '';
        if (fn === GalleryFolderAction.ADD) {
          action = 'SAVE';
          folder = null;
        } else {
          action = GalleryFolderAction[fn];
          if (folder) {
            id = folder.id;
          }
        }

        const graphqlService = GraphqlService.getInstance();
        const params = {
          variables: {
            input: {
              id,
              type: GalleryItemPropType.FOLDER,
              name,
            },
            action,
          },
          type: 'mutation',
          operation: 'manageGallery',
          reqFields:
            '{ id name source responseMessage { alertKey message count options } }',
          alias: '',
        } as boGatewayParams;
        const res$ = graphqlService.request(credentials, params);
        res$.subscribe({
          next: (value) => {
            const p = params?.alias || params?.operation || '';
            const items = value[p] || [];
            const metadata = value.metadata[p] || {};

            if (!folder) {
              folder = {
                ...items,
              };
            }

            resolve({
              fn,
              folder,
              responseMessage: metadata.responseMessage,
            } as GalleryFolderPayload);
          },
          error: (err) => {
            console.error(err.message);
            return reject(err);
          },
        });
      }
    });
  }
);

export const getFoldersList = createAsyncThunk(
  'gallery/getFoldersList',
  (_a, { getState }): Promise<GalleryResponse> => {
    return new Promise((resolve, reject) => {
      const {
        gallery: state,
        main: { credentials },
      } = getState() as RootState;
      if (!state.itemsType) {
        return resolve({});
      }

      let data: GalleryResponse = {};
      const graphqlService = GraphqlService.getInstance();
      const params = {
        variables: {
          type: 'folders',
          source: GalleryItemsTypes[state.itemsType],
        },
        operation: 'gallery',
        reqFields: '{ folders { id name source } }',
        alias: '',
      };

      const res$ = graphqlService.request(credentials, params);
      res$.subscribe({
        next: (value) => {
          const p = params?.alias || params?.operation || '';
          const items = value[p] || [];
          const metadata = value.metadata[p] || {};

          data = JSON.parse(JSON.stringify(items));

          resolve({
            folders: data.folders,
            metadata,
          });
        },
        error: (err) => {
          console.error(err.message);
          return reject(err);
        },
      });
    });
  }
);

export const saveTempMedia = createAsyncThunk(
  'gallery/saveTempMedia',
  (
    {
      id,
      name,
      type,
      ext,
      chunk,
      currentChunk,
      totalChunks,
      folderName,
    }: {
      id: number;
      name: string;
      type: GalleryItemPropType;
      ext: string;
      chunk: Blob;
      currentChunk: number;
      totalChunks: number;
      folderName: string;
    },
    { getState }
  ): Promise<GalleryResponse> => {
    return new Promise((resolve, reject) => {
      const {
        main: { credentials },
        gallery: { xAdaDestination },
      } = getState() as RootState;

      const beDirectService = BeDirectService.getInstance();
      const data = {
        id: String(id),
        chunk,
        type,
        name,
        ext,
        currentChunk,
        totalChunks,
        fn: 'saveChunk',
      } as boGatewayGeneric;

      if (folderName) {
        data.folderName = folderName;
      }

      const res$ = beDirectService.request(credentials, data, xAdaDestination);
      res$.subscribe({
        next: (value) => {
          resolve(value);
        },
        error: (err) => {
          console.error(err.message);
          return reject(err);
        },
      });
    });
  }
);

export const fetchMedia = createAsyncThunk(
  'gallery/fetchMedia',
  (url: string, { getState }): Promise<GalleryResponse> => {
    return new Promise((resolve, reject) => {
      const {
        main: { credentials },
      } = getState() as RootState;

      const beDirectService = BeDirectService.getInstance();
      const data = {
        url,
        fn: 'fetchMedia',
      } as boGatewayGeneric;

      const res$ = beDirectService.request(credentials, data);
      res$.subscribe({
        next: (value) => {
          resolve(value);
        },
        error: (err) => {
          console.error(err.message);
          return reject(err);
        },
      });
    });
  }
);

/* export const deleteItems = createAsyncThunk(
  'gallery/deleteItems',
  (value, { dispatch }): Promise<GalleryResponse> => {
    return new Promise((resolve, reject) => {
      const params: boGatewayParams = {
        variables: { type: 'list', page: 1 },
        operation: 'gallery',
        reqFields: '{ id name src alt resolution size date }',
        alias: 'galleryList',
      };

      common(params)
        .then((res) => resolve(res))
        .catch((err) => err);
    });
  }
); */
