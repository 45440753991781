export default {
  "cropTool": "Gewas",
  "nameIsRequired": "Naam is verplicht.",
  "adjustTab": "Aanpassen",
  "socialMedia": "Sociale media",
  "padding": "Opvulling",
  "addBackgroundAsText": "Toevoegen als tekst",
  "toBeUploaded": "om te uploaden",
  "imageTypeWillChangeOnSave": "Bij het opslaan kiest u een nieuw afbeeldingstype/ extensie om op te slaan met",
  "IconTab": "Icoon",
  "reset": "Reset",
  "addIconTitle": "Kies het type pictogram",
  "classicTv": "Klassieke TV",
  "importing": "Importeren...",
  "brightnessTool": "Helderheid",
  "storyPhoto": "Story foto",
  "resizeTab": "Formaat wijzigen",
  "twInStreamPhotoSize": "1600x1900px",
  "portrait": "Portret",
  "toggleZoomMenuTitle": "Menu zoom uitschakelen",
  "saveAs": "Opslaan als",
  "uploadWatermark": "Watermerk uploaden",
  "closeEditor": "Sluit editor",
  "resetZoomTitle": "Zoom percentage (Klik om te resetten)",
  "headerPhoto": "Koptekst foto",
  "letterSpacing": "Letterafstand",
  "feedSquarePhoto": "Voer vierkante foto",
  "rectangleTool": "Rechthoek",
  "textTool": "Tekst",
  "arrowTool": "Pijl",
  "rotateTool": "Draaien",
  "twHeaderPhotoSize": "1500x500px",
  "igStoryPhotoSize": "1080x1920px",
  "position": "Positie",
  "contrastTool": "Contrast",
  "unFlipX": "Un-Flip X",
  "igProfilePhoto": "320x320px",
  "horizontal": "Horizontaal",
  "quality": "Kwaliteit",
  "ellipseTool": "Ellips",
  "fbStoryPhotoSize": "1080x1920px",
  "annotateTab": "Teken",
  "value": "Waarde",
  "filtersTab": "Filters",
  "zoomOutTitle": "Uitzoomen",
  "hsvTool": "HSV",
  "igFeedSquarePhotoSize": "1080x1080px",
  "lineHeight": "Lijnhoogte",
  "instagram": "Instagram",
  "addBackground": "+ Voeg achtergrond toe",
  "previewTool": "Voorbeeld",
  "liProfilePhotoSize": "400x400px",
  "finetuneTab": "Finetune",
  "uploadIcon": "Pictogram uploaden",
  "liProfileCoverPhotoSize": "1584x396px",
  "inStreamPhoto": "In-stream foto",
  "twProfilePhotoSize": "400x400px",
  "resizeWidthTitle": "Breedte in pixels",
  "toggleRatioLockTitle": "Schakel vergrendeling",
  "annotationVariable": "Stel annotatie variabele in",
  "uploadImageError": "Fout bij het uploaden van de afbeelding.",
  "custom": "Aangepaste",
  "cropSizeLowerThanResizedWarning": "Let op, het geselecteerde bijsnijdgebied is kleiner dan het toegepaste formaat, waardoor de kwaliteit kan afnemen",
  "showImageTitle": "Toon originele afbeelding",
  "continue": "Ga door",
  "linkedIn": "LinkedIn",
  "actualSize": "Werkelijke grootte (100%)",
  "back": "Terug",
  "resize": "Formaat wijzigen",
  "flipY": "Flip Y",
  "addImage": "+ Afbeelding toevoegen",
  "fontFamily": "Lettertype familie",
  "polygonTool": "Polygoon",
  "blurRadius": "blurRadius",
  "imageTypeNotSavingSupported": "Afbeeldingstype/-extensie wordt NIET ondersteund bij het opslaan",
  "penTool": "Pen",
  "saturation": "Verzadiging",
  "addBackgroundTitle": "Kies het type achtergrond",
  "zoomInTitle": "Inzoomen",
  "addWatermark": "+ Watermerk toevoegen",
  "isNotImage": "is geen beeld",
  "profileCoverPhoto": "Profiel cover foto",
  "feedPortraitPhoto": "Voer portretfoto",
  "textAlignment": "Tekst uitlijning",
  "watermarkTab": "Watermerk",
  "twitter": "Twitter",
  "loading": "Laden...",
  "unFlipY": "Un-Flip Y",
  "landscape": "Landschap",
  "original": "Origineel",
  "saveAsModalLabel": "Bewaar de afbeelding als",
  "size": "Maat",
  "liCompanyLogoSize": "300x300px",
  "backgroundTab": "Achtergrond",
  "addWatermarkTitle": "Kies het type watermerk",
  "igProfilePhotoSize": "320x320px",
  "cornerRadius": "Hoek Straal",
  "addIcon": "+ Pictogram toevoegen",
  "liBlogPostPhotoSize": "1200x627px",
  "cinemascope": "Cinemascope",
  "addWatermarkAsText": "Toevoegen als tekst",
  "lineTool": "Lijn",
  "warmthTool": "Warmte",
  "measureTool": "Maatregel",
  "save": "Sla",
  "timelinePhoto": "Timeline foto",
  "eventCoverPhoto": "Foto omslag evenement",
  "facebook": "Facebook",
  "changesLoseConfirmation": "Alle veranderingen zullen verloren gaan",
  "fbEventCoverPhotoSize": "1200x628px",
  "companyPageCover": "Bedrijfspagina omslag",
  "blogPostPhoto": "Blog post foto",
  "undoTitle": "Laatste bewerking ongedaan maken",
  "resetOperations": "Reset/verwijder alle bewerkingen",
  "stroke": "Beroerte",
  "name": "Naam",
  "cancel": "Annuleren",
  "download": "Download",
  "addIconAsText": "Toevoegen als tekst",
  "hue": "Hue",
  "textSpacings": "Afstanden tussen de teksten",
  "fbProfilePhotoSize": "170x170px",
  "textTab": "Tekst",
  "srTab": "SR",
  "blurTool": "Waas",
  "uploadBackground": "Upload achtergrond",
  "downloadAsModalLabel": "Bewaar de afbeelding als",
  "circle": "Cirkel",
  "imageDimensionsHoverTitle": "Opgeslagen beeldgrootte (breedte x hoogte)",
  "addByUrl": "+ URL toevoegen",
  "changesLoseConfirmationHint": "Weet je zeker dat je door wilt gaan?",
  "redoTitle": "Herhaal laatste bewerking",
  "feedLandscapePhoto": "Voer landschapsfoto",
  "invalidImageError": "Ongeldige afbeelding.",
  "shadow": "Schaduw",
  "ellipse": "Ellips",
  "square": "Vierkant",
  "blur": "Waas",
  "sides": "Kanten",
  "vertical": "Verticaal",
  "igFeedLandscapePhotoSize": "1080x566px",
  "imageTool": "Afbeelding",
  "areNotImages": "zijn geen beelden",
  "resetSize": "Terugzetten naar originele afbeeldingsgrootte",
  "extension": "Uitbreiding",
  "opacity": "Opaciteit",
  "cancelEditing": "Annuleren",
  "liCompanyPageCoverSize": "1128x191px",
  "transparency": "Opaciteit",
  "profilePhoto": "Profiel foto",
  "igFeedPortraitPhotoSize": "1080x1350px",
  "fitSize": "Pas maat",
  "flipX": "Flip X",
  "fbProfileCoverPhotoSize": "851x315px",
  "fbTimelinePhotoSize": "1200x630px",
  "resizeHeightTitle": "Hoogte in pixels",
  "companyLogo": "Bedrijfslogo"
}
