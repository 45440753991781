/** External Dependencies */
/* import {
  FineTune,
  Annotate,
  CropFrame,
  ImageFilters,
  Watermark,
  Resize,
} from '@scaleflex/icons'; */

import {
  faCrop,
  faAperture,
  faPenSwirl,
  faImagePolaroid,
  faRulerTriangle,
  faSliders,
} from '@fortawesome/pro-light-svg-icons';

/** Internal Dependencies */
import { TABS_IDS } from 'ada-image-editor/utils/constants';

export const AVAILABLE_TABS = [
  {
    id: TABS_IDS.ADJUST,
    labelKey: 'adjustTab',
    icon: faCrop,
  },
  {
    id: TABS_IDS.FINETUNE,
    labelKey: 'finetuneTab',
    icon: faSliders,
  },
  {
    id: TABS_IDS.FILTERS,
    labelKey: 'filtersTab',
    icon: faAperture,
    hideFn: ({ useCloudimage }) => useCloudimage,
  },
  {
    id: TABS_IDS.WATERMARK,
    labelKey: 'watermarkTab',
    icon: faImagePolaroid,
  },
  {
    id: TABS_IDS.ANNOTATE,
    labelKey: 'annotateTab',
    icon: faPenSwirl,
    hideFn: ({ useCloudimage }) => useCloudimage,
  },
  {
    id: TABS_IDS.RESIZE,
    labelKey: 'resizeTab',
    icon: faRulerTriangle,
  },
];
/* export const AVAILABLE_TABS = [
  {
    id: TABS_IDS.ADJUST,
    labelKey: 'adjustTab',
    icon: CropFrame,
  },
  {
    id: TABS_IDS.FINETUNE,
    labelKey: 'finetuneTab',
    icon: FineTune,
  },
  {
    id: TABS_IDS.FILTERS,
    labelKey: 'filtersTab',
    icon: ImageFilters,
    hideFn: ({ useCloudimage }) => useCloudimage,
  },
  {
    id: TABS_IDS.WATERMARK,
    labelKey: 'watermarkTab',
    icon: Watermark,
  },
  {
    id: TABS_IDS.ANNOTATE,
    labelKey: 'annotateTab',
    icon: Annotate,
    hideFn: ({ useCloudimage }) => useCloudimage,
  },
  {
    id: TABS_IDS.RESIZE,
    labelKey: 'resizeTab',
    icon: Resize,
  },
]; */
