import {
  FolderPillWrapper,
  FolderPillInner,
  FontAwesomeIconWrapper,
} from './FoldersPillsSelection.style';

import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FolderPillProps } from '../../custom.types';

export const FolderPill = (props: FolderPillProps) => {
  return (
    <FolderPillWrapper name={props.name} id={props.id} onClick={() => null}>
      <FolderPillInner>
        {props.name}
        <FontAwesomeIconWrapper onClick={() => props.onClick(props.id)}>
          <FontAwesomeIcon icon={faX} />
        </FontAwesomeIconWrapper>
      </FolderPillInner>
    </FolderPillWrapper>
  );
};
