import { ChangeEvent, createRef, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
  FolderForm,
  FolderFormInput,
  Footer,
  ModalBody,
  ModalContent,
  ModalTitle,
  TopBar,
} from './Modal.style';
import { ButtonOk } from '../UI.style';
import { faX } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import theme from '../../../theme';
import { useTranslation } from 'react-i18next';

import { RootState } from '../../../app/store';
import { GalleryFolderAction, GalleryStateStatus } from '../../../custom.types';
import { manageFolder } from '../../../app/actions/gallery.action';
import { closeEditor } from '../../../app/reducers/gallery.reducer';
import { validateFolderName } from '../../../app/helpers/common.helper'
import { ValidationMessage } from '../ValidationMessage';

let message: string;
let opts = {};

export default function AddFolder() {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  // const [messages, setMessages] = useState<string[]>([]);
  const nameRef = createRef<HTMLInputElement>();
  const status = useAppSelector((state: RootState) => state.main.status);
  const folders = useAppSelector((state: RootState) => state.gallery.folders);

  const [folderName, setFolderName] = useState<string>('');
  const [saved, setSaved] = useState<boolean>(false);
  const [valueChanged, setValueChanged] = useState<boolean>(false);
  const [isValid, setIsValid] = useState<boolean>(false);
  // const [error, setError] = useState<string>('');

  let timerID: ReturnType<typeof setTimeout>;

  const SaveHandler = () => {
    if(!isValid) return;
    const name = nameRef.current?.value || '';
    dispatch(
      manageFolder({
        fn: GalleryFolderAction.ADD,
        folder: null,
        name,
      })
    ).then((value) => {
      if (value) {
        dispatch(closeEditor());
        setSaved(true);
        // setMessages(['Messaggio Salvato']);
        setFolderName('');
        setValueChanged(false);
      }
    });
  };

  const editHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value
    setFolderName(value);
    
    if(timerID) {
      clearTimeout(timerID);
    }

    timerID = setTimeout(() => {
      setValueChanged(true);
      const isNameValid = validateFolderName(value);
      const isNew = folders.findIndex(f => f.name === value) === -1;
      if(!isNameValid) {
        //setError(() => ('Nome non valido.'));
        message = 'invalid_name';
      }
      if(!isNew) {
        // setError(() => ('Cartella già esistente'));
        message = 'Existing_media';
        opts = { type: 'Cartella_one', name: value };
      }
      setTimeout(() => {
        setIsValid(() => (isNameValid && isNew));
      }, 0)
    }, 300);

  };

  useEffect(() => {
    if (nameRef.current) {
      nameRef.current.focus();
    }
  }, [nameRef]);

  useEffect(() => {
    setTimeout(() => {
      setSaved(false)
      setIsValid(false)
    }, 3000);
  }, [saved]);

  return (
    <ModalContent width="600px" height="auto">
      <TopBar onClick={() => dispatch(closeEditor())}>
        <ModalTitle color={theme.ada.palette['medium-green']}>{`${t('Crea', {
          key: 'Cartella_one',
        })}`}</ModalTitle>
        <FontAwesomeIcon
          color={theme.ada.palette['medium-grey']}
          icon={faX}
        />
      </TopBar>
      <ModalBody>
        {status !== GalleryStateStatus.LOADING && (
          <FolderForm>
            <label>{`${t('Inserisci il nome della cartella')}`}</label>
            <FolderFormInput
              ref={nameRef}
              value={folderName}
              onChange={editHandler}
              placeholder={`${t('Inserisci un nome')}`}
            />
          </FolderForm>  
        )}
        {!isValid && valueChanged && folderName !== '' && <ValidationMessage>{t(message, opts)}</ValidationMessage>}
      </ModalBody>
      <Footer>
        {(valueChanged || !saved) && status !== GalleryStateStatus.LOADING && (
          <ButtonOk onClick={SaveHandler} disabled={!isValid}>{t('Salva')}</ButtonOk>
        )}
      </Footer>
    </ModalContent>
  );
}
