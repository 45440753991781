import i18n from 'i18next';
import { translations as resources } from 'languages';
import { initReactI18next } from 'react-i18next';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'it', // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  });
/* i18n.addResourceBundle('en', 'namespace1', {
  key: 'hello from namespace 1'
}); */

export default i18n;
