import styled, { css } from 'styled-components';

export const InputSearch = styled.input<{ expanded: string }>(
  ({ expanded, theme: { ada: theme } }) => css`
    height: 35px;
    font-size: 12px;
    border: 1px solid ${theme.palette['medium-grey']}70;
    border-radius: 5px;
    padding: 10px;
    position: relative;
    overflow: hidden;
    width: 0%;
    opacity: 0;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;
    outline: ${theme.palette['medium-grey']};

    ${expanded === 'no' &&
    css`
      width: 0%;
      opacity: 0;
    `}
    ${expanded === 'half' &&
    css`
      width: 30%;
      opacity: 1;
    `}
      ${expanded === 'full' &&
    css`
      width: 75%;
      opacity: 1;
    `};
  `
);

export const IconWrapper = styled.div<{
  expanded: string;
  searchValue: string;
}>(
  ({ expanded, searchValue, theme: { ada: theme } }) => css`
    position: absolute;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);
    color: ${theme.palette['medium-grey']};
    cursor: pointer;
    height: 80%;
    display: flex;
    align-items: center;
    background-color: white;
    padding: 0 5px 0;
    font-size: 22px;
    transition: all 0.3s linear;
    -webkit-transition: all 0.3s linear;
    -moz-transition: all 0.3s linear;
    -o-transition: all 0.3s linear;

    ${expanded === 'no' &&
    css`
      font-size: 22px;
    `}

    ${(expanded === 'full' || expanded === 'half') &&
    css`
      font-size: 14px;
      ${searchValue === '' &&
      css`
        pointer-events: none;
      `}
    `}
  `
);
